import { Component, OnInit,ElementRef,Input,AfterViewInit,OnChanges,SimpleChanges } from '@angular/core';
import { InsightsDashboardService } from '../insights-dashboard.service';

@Component({
  selector: 'app-insights-dashboard',
  templateUrl: './insights-dashboard.component.html',
  styleUrls: ['./insights-dashboard.component.css']
})
export class InsightsDashboardComponent implements OnInit,AfterViewInit {

 @Input() id: string;
 @Input() title: string;
 @Input() height = 'calc(100vh - 120px)' 
 @Input() width = '80vw';

  constructor(private elementRef:ElementRef,private insightsS:InsightsDashboardService) { }

  ngOnInit(): void {

    
  }

  ngAfterViewInit():void{

    this.show_dashboard()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.id && changes.id.currentValue) {

      this.id = changes && changes.id && changes.id.currentValue;
      this.show_dashboard(); 
    }

  }


  show_dashboard(){

    const dashboardElement = this.elementRef.nativeElement.querySelector('#dashboard');
  
    if (dashboardElement) {
      this.insightsS.embedDashboard(this.id).subscribe(
        () => {
          const iframe = dashboardElement.querySelector('iframe');
          if (iframe) {
            iframe.style.width = this.width;
            iframe.style.height = this.height; 
            iframe.frameBorder="0"
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

}
