<div class="log-container" *ngIf="!metadata_loading">

    <div class="metadata-selection" *ngIf="hideLogPanelFlag">

        <app-log-file-panel (selectedCurves)="onCurveSelectionFile($event)" (selectedMarkers)='onMarkerSelection($event)' 
        (selectedFills)='onFillSelection($event)' (displayLogCurves)='onTabChangeOfLogCurves(event)' (displayTypesofCurves)='onTabChangeOfFillType(event)'
        [traces]="traces" [markers]="markers" [mode]="mode" [view]="view" *ngIf="mode == 'file' || 'db'"></app-log-file-panel>
           
    </div>
    
    <div class="log_canvas">
        
  
        <div class="header-panel">

            <div class="card flex justify-content-center">
                <p-menu appendTo="body" #menu [model]="items" [popup]="true"></p-menu>
                <i pTooltip="Menu"  class="fa fa-solid fa-bars icons" (click)="menu.toggle($event)"></i>
            </div>

            <div class="panel-icons">

                <i pTooltip="Zoom In"  class="fas fa-search-plus icons" (click)="zoom_in()"></i>

                <i pTooltip="Zoom Out" class="fas fa-search-minus icons" (click)="zoom_out()"></i>

                <i pTooltip="Reset Zoom" class="fa fa-refresh icons" (click)="reset_zoom()"></i>

                <i pTooltip="Zoom to Fit" class="fa fa-solid fa-maximize icons" (click)="zoomToFit()"></i>
                
                <div class="title p-fluid p-d-flex p-ai-center" style="margin-right: 0.5rem;">Scale: 1:</div>
                <input id="depthscaleinput" type="number" (keyup.enter)="changeInput(depthScale)" pInputText [(ngModel)]="depthScale" style="width:70px;" pTooltip="Enter Depth Scale"/>
                

            </div>
        
        
        </div>

        <div class="data-panel">
            
            <div class="canvas">

                <canvas #plot style="height: 100%;width: 100%;"></canvas>
                 
            </div>
            
            <i pTooltip="Zoom In" class="pi pi-spin pi-spinner loading" *ngIf="loading" ></i>

        </div>
        

    
    
    </div>


</div>


<div class="log-container"  *ngIf="metadata_loading">

    <i pTooltip="Zoom In" class="pi pi-spin pi-spinner loading" ></i>

</div>