import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewModelService } from 'src/app/browse-data/view-model.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {

  @Output() selected_nav = new EventEmitter();

  tree_active=false;
  filter_active=false;
  grid_active=false;
  map_active=false;
  log_view_active=false;
  log_view=false;

  @Input() disable=false;

  viewers_state;
  constructor(private viewMs:ViewModelService) { }

  ngOnInit(): void {

    this.viewers_state = this.viewMs.get_viewers()

    // this.set_viewers_state()
  }

  public set_viewers_state(){

    this.viewers_state.forEach(view => {

      if(view.type == 'grid' && view.active == true){

        document.getElementById('grid').style.color='#0374ff';
        this.grid_active=true;
      }
      if(view.type == 'map' && view.active == true){

        document.getElementById('map').style.color='#0374ff';
        this.map_active=true;
      }
      if(view.type == 'tree' && view.active == true){

        document.getElementById('tree').style.color='#0374ff';
        this.tree_active=true;
      }

      if(view.type == 'filter' && view.active == true){

        document.getElementById('filter').style.color='#0374ff';
        this.filter_active=true;
      }
      
    });
  }

  toggle(event){

      if(event == 'tree'){

        this.tree_active=!this.tree_active;
        this.filter_active=false;

        if(this.tree_active){

          document.getElementById('tree').style.color='#0374ff'
          document.getElementById('filter').style.color='black'
        }else{

          document.getElementById('tree').style.color='black'
        }

        this.selected_nav.emit({event:event,state:this.tree_active})
      }

      
      if(event == 'grid'){

        this.grid_active=!this.grid_active;

        if(this.grid_active){

          document.getElementById('grid').style.color='#0374ff'
        }else{

          document.getElementById('grid').style.color='black'
        }

        this.selected_nav.emit({event:event,state:this.grid_active})
      }

      if(event == 'map'){

        this.map_active=!this.map_active;
        this.log_view_active=false;

        if(this.map_active){

          document.getElementById('map').style.color='#0374ff';
          this.log_view? document.getElementById('log').style.color='black':null
        }else{

          document.getElementById('map').style.color='black'
        }

        this.selected_nav.emit({event:event,state:this.map_active})
      }


      if(event == 'filter'){

        this.filter_active=!this.filter_active;
        this.tree_active=false;

        if(this.filter_active){

          document.getElementById('filter').style.color='#0374ff';
          document.getElementById('tree').style.color='black'
        }else{

          document.getElementById('filter').style.color='black'
        }

        this.selected_nav.emit({event:event,state:this.filter_active})
      }

      if(event == 'log'){

        this.log_view_active=!this.log_view_active;

        if(this.log_view_active){

          document.getElementById('log').style.color='#0374ff'
        }else{

          document.getElementById('log').style.color='black'
        }

        this.selected_nav.emit({event:event,state:this.log_view_active})
      }

      
  }

  set_log_viewr_active(){

    this.log_view=true;
    this.map_active=false;
    this.log_view_active=true;

    document.getElementById('map').style.color='black';
    
    setTimeout(() => {
      document.getElementById('log').style.color='#0374ff';
      
    }, 20);
   

  }

  set_grid_active(){

    this.grid_active=true;
    document.getElementById('grid').style.color='#0374ff'

  }

  activate(event){

    if(event == 'map'){

      this.map_active=true;
      document.getElementById('map').style.color='#0374ff';

    }
    
  }

  deactivate(event){

    if(event == 'filter'){

      this.filter_active=false;
      document.getElementById('filter').style.color='black';

    }

    if(event == "tree"){

      this.tree_active=false;
      document.getElementById('tree').style.color='black'

    }

    if(event == 'log'){

      this.log_view_active=false;
      document.getElementById('log').style.color='black';
    }

    
    if(event == 'map'){

      this.map_active=false;
      document.getElementById('map').style.color='black';
    }

  }


}
