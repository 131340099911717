import { HttpClient, HttpParams } from '@angular/common/http';

import { DataModelService } from 'src/app/browse-data/data-model.service';
import { Injectable } from '@angular/core';
import { LoaderPortalServicesService } from 'src/app/services/loader-portal-services.service';
import { ViewModelService } from 'src/app/browse-data/view-model.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalCompService {

grid_data;
map_caching = true;
map_cache = [];
summary_grid=true;

channels_by_borehole=[];

all_filters = [];

datasource_index_for_filters=[]

linked_points=null;

global_points=[];

  constructor(
    private http:HttpClient,
    private loaderPService: LoaderPortalServicesService,
    private dMService: DataModelService,
    private vService: ViewModelService
    ) { }

get_stored_cache(){

  let activeSubContext = this.vService.active_subcontext;

  return this.map_cache[activeSubContext]
}

clear_filters(){

  this.datasource_index_for_filters=[];
  this.all_filters=[];
  this.linked_points=[];
  this.global_points=[];
  this.map_cache=[];

  let subContexts = this.vService.get_all_subcontexts();

  subContexts.forEach(cnt => {

    this.all_filters.push([])
    this.datasource_index_for_filters.push([])
    this.linked_points.push([])
    this.global_points.push([])
    this.map_cache.push(null)
  })


}

get_dynamic_partitions(){

  const params = new HttpParams().set('context', this.vService.active_view_model)


  return this.http.get(`${this.loaderPService.browse_data_api_endpoint}/get-partitions`,{params:params})

}


get_grid_data(datasource,page){

  let filters = this.get_applied_filters(datasource)

  let activeSubContext = this.vService.active_subcontext;


  if(this.linked_points[activeSubContext].length>0){

    let fk_points = this.linked_points[activeSubContext][0];
  
    fk_points["dataSource"]=datasource;
    fk_points["operator"]="="
    filters.unshift(fk_points)
  }else if(this.global_points[activeSubContext].length>0){
   
    let fk_points = this.global_points[activeSubContext][0];
    filters.unshift(fk_points)

  }



  return this.http.post(`${this.loaderPService.browse_data_api_endpoint}/widget-grid`,this.get_ps_web_grid_template(datasource,filters,page))

}


get_map_cache(index,extent){

  let datasource = this.vService.get_parent_datasource_from_sub_context_index(index)

  let body = {
    datasource:datasource,
    extent:extent,
    partition:this.vService.active_partition,
    link:this.dMService.get_primary_key(datasource)
  }

  return this.http.post(`${this.loaderPService.browse_data_api_endpoint}/browseDatasets/geometry`,body)

}

get_dashboard(month){
  
  let params = new HttpParams();

  params = params.append("month",month)
    
  return this.http.get(`${this.loaderPService.browse_data_api_endpoint}/dashboard`,{params:params})
}

get_context_definition(context){
  
  return this.http.get(`${this.loaderPService.browse_data_api_endpoint}/browseDatasets/getModel/${context}`)

}

create_datasource_index_mapping(datasource){

  let activeSubContext = this.vService.active_subcontext;

  this.datasource_index_for_filters[activeSubContext].push(datasource)

  this.all_filters[activeSubContext].push({datasource:datasource,filter_values:[]})

  return this.datasource_index_for_filters[activeSubContext].length -1

}

get_datasource_index_mapping(datasource){

  let activeSubContext = this.vService.active_subcontext;

  let index = this.datasource_index_for_filters[activeSubContext].indexOf(datasource)

  if(index==-1){

    return this.create_datasource_index_mapping(datasource)
  }

  else{

    return index
  }

}

get_linked_points(){

 let  attribute = [
    {
       "attribute":this.vService.get_tree_link(),
       "attributeType":"LONG",
    }
 ]


let datasource = this.vService.get_active_datasource()
 let filters = this.get_applied_filters(datasource)


 let activeSubContext = this.vService.active_subcontext;

  if(this.linked_points[activeSubContext].length>0){

    let fk_points = this.linked_points[activeSubContext][0];
  
    fk_points["dataSource"]=datasource;
    fk_points["operator"]="="
    filters.unshift(fk_points)

  }else if(this.global_points[activeSubContext].length>0){
   
    let fk_points = this.global_points[activeSubContext][0];
    filters.unshift(fk_points)

  }


let p_key = this.vService.get_datasource_primary_key()

if(p_key){

  attribute.push({"attribute":p_key,
  "attributeType":"LONG"
})
 
}

  return this.http.post(`${this.loaderPService.browse_data_api_endpoint}/widget`,this.get_ps_web_filter_template(attribute,filters,datasource))
}





update_associated_keys(keys){
  
  let index = this.get_datasource_index_mapping(this.vService.get_active_datasource())

  let activeSubContext = this.vService.active_subcontext;

  let ds_filters = this.all_filters[activeSubContext][index]

  ds_filters["fks"] = keys

  let len = 99999

  this.all_filters[activeSubContext].forEach(fil=>{

    if(fil["fks"][0]["values"].length < len){
      this.linked_points[activeSubContext] = fil["fks"]
      len = fil["fks"][0]["values"].length
    }
  })

}

update_primary_key_count(len){

  let index = this.get_datasource_index_mapping(this.vService.get_active_datasource())
  let activeSubContext = this.vService.active_subcontext;

  let ds_filters = this.all_filters[activeSubContext][index]

  ds_filters["pks"] = len

}

update_tree_labels(tree){

  let activeSubContext = this.vService.active_subcontext;

  tree[0]["children"].forEach((elem,indx) => {
  
    let index = this.datasource_index_for_filters[activeSubContext].indexOf(elem["data"])
  
    if(index>-1){

      let ds_filters = this.all_filters[activeSubContext][index]
    
      tree[0]["children"][indx]["label"] = elem["label"].split("(")[0] + "("+ds_filters["pks"]+")"

    }
    
  });

  return tree

}


get_filter_data(datasource){


  let attributes = this.dMService.get_datasource_filters(datasource)

  let filters = this.get_applied_filters(datasource)

  let activeSubContext = this.vService.active_subcontext;

  if(this.linked_points[activeSubContext].length>0){

    let fk_points = this.linked_points[activeSubContext][0];
  
    fk_points["dataSource"]=datasource;
    fk_points["operator"]="="
    filters.unshift(fk_points)
  }else if(this.global_points[activeSubContext].length>0){
   
    let fk_points = this.global_points[activeSubContext][0];
    filters.unshift(fk_points)

  }


  
  return this.http.post(`${this.loaderPService.browse_data_api_endpoint}/widget`,this.get_ps_web_filter_template(attributes,filters,datasource))

}

get_search_results(attributes,filters,datasource){

  return this.http.post(`${this.loaderPService.browse_data_api_endpoint}/widget`,this.get_ps_web_filter_template(attributes,filters,datasource))

}

get_tree_data(definition){

  let datasource = definition[0]["data"]

  // let filters = this.get_applied_filters(datasource)

  let filters=[]

  let activeSubContext = this.vService.active_subcontext;

  if(this.linked_points[activeSubContext].length>0){

    let fk_points = this.linked_points[activeSubContext][0];
  
    fk_points["dataSource"]=datasource;
    fk_points["operator"]="="
    filters.unshift(fk_points)
  }else if(this.global_points[activeSubContext].length>0){
   
    let fk_points = this.global_points[activeSubContext][0];
    filters.unshift(fk_points)

  }




  let nodes = definition[0]["children"].map(att=>{
    
    return {'label':att.label,'key':att.key,'data':att.data}
  
  })


  let body = {

    "filters":filters,
    "parent_datasource":definition[0]["data"],
    "parent_label":definition[0]["label"],
    "link":definition[0]["link"],
    "project":this.vService.active_partition,
    "nodes":nodes
  }



  return this.http.post(`${this.loaderPService.browse_data_api_endpoint}/widget-tree`,body)

}

get_all_filters(){

let activeSubContext = this.vService.active_subcontext;

 let  filters = []


  this.datasource_index_for_filters[activeSubContext].forEach(datasource=>{

    this.get_applied_filters(datasource).forEach(x=>filters.push(x))
  })

  return filters

}


get_applied_filters(datasource){

let activeSubContext = this.vService.active_subcontext;

let index = this.datasource_index_for_filters[activeSubContext].indexOf(datasource)
  if(index==-1){

    return []
  }
  else{

    let filters = []
  
   this.all_filters[activeSubContext][index]["filter_values"].forEach(val=>{


     let fil = {

      "attribute":val.attribute,
      "values":[],
      "operator":val.operator,
      "dataSource":datasource
      }

      fil["values"] = val.selected.map(x=>x.name)
      
      if(fil["values"].length){
        filters.push(fil)
      }     

   })

   filters.map(att=>{

    att["attributeType"] = this.dMService.get_attribute_type(att["attribute"],datasource)

  })

  return filters
  }

}


get_ps_web_filter_template(attributes,filters,datasource){

  return {
    "type":"filter",
    "attributes":attributes,
    "index":[
       
    ],
    "aggFunc":[
       
    ],
    "filters":filters,
    "templateFilters":[
       
    ],
    "project":this.vService.active_partition,
    "dataSource":datasource,
    "user":"ps_admin",
    "associationKeys":[
       
    ],
    "order_by_position_topics":[
       "PSL_Borehole_Summary"
    ]
 }
}

get_ps_web_map_cache_template(filters){

  return {
    "type":"graph",
    "attributes":[

       {
          "attribute":this.vService.get_tree_link(),
          "attributeType":"LONG",
       },
       {
          "attribute":"Shape",
          "attributeType":"POINT",
       }
    ],
    "index":[
       
    ],
    "aggFunc":[
       
    ],
    "componentConfig":null,
    "graph":{
       "layout":{
          "style":"open-street-map",
          "accessToken":null,
          "height":"650",
          "width":null,
          "zoom":6,
          "lat":null,
          "long":null,
          "showlegend":false,
          "externalPlots":[
             
          ],
          "layers":[
             
          ]
       },
       "traces":[
          {
             "graphType":"openmap",
             "hoverinfo":[
              this.vService.get_tree_link()
             ],
             "selectable_attribute":this.vService.get_tree_link(),
             "shape_attribute":"Shape",
             "markerColor":"#636efa",
             "markerSize":8,
             "lineColor":"#636efa",
             "name":"",
             "traceFilters":[
                
             ]
          }
       ]
    },
    "filters":filters,
    "project":this.vService.active_partition,
    "dataSource":"dmaas2_boreholes",
    "user":"sis_admin",
    "order_by_position_topics":[
       "PSL_Borehole_Summary"
    ],
    "associationKeys":[
       
    ],
    "templateFilters":[
       
    ]
 }
}

  
get_ps_web_grid_template(datasource,filters,page){

let attributes = this.dMService.get_datasource_definition(datasource)

return {
    "index":[
       
    ],
    "attributes":attributes,
    "aggFunc":[
       
    ],
    "filters":filters,
    "type":"grid",
    "templateFilters":[
       
    ],
    "project":this.vService.active_partition,
    "dataSource":datasource,
    "user":"ps_admin",
    "page_number":page,
    "num_of_records":500,
    "associationKeys":[
       
    ],
    "constraint_choices":[
       
    ],
    "order_by_position_topics": ["PSL_Borehole_Summary"]
 }

}


get_log_curves(rows){

  

  let params = new HttpParams();

  rows.forEach(element => {

    params = params.append("curveid",element)
    
  });
  

  return this.http.get(`${this.loaderPService.browse_data_api_endpoint}/getCurves`,{params:params})
}

get_grid_options(){

  return this.vService.get_grid_options()
}


get_well_details(borehole){

let body = {

    borehole:borehole
  }
   return this.http.post(`${this.loaderPService.browse_data_api_endpoint}/well_info`,body);

}


get_file_details(filepath){

  filepath = filepath


  return this.http.get(`${this.loaderPService.browse_data_api_endpoint}/fileDetails?path=${filepath}`)
}

download_file(filepath){

  return this.http.get(`${this.loaderPService.browse_data_api_endpoint}/fileDownload?path=${filepath}`,{
  responseType: 'blob', reportProgress: true,
  observe: 'events' })

}

download_multiple_files(selectedFiles){
  let body = {
    files:selectedFiles
  }
  return this.http.post(`${this.loaderPService.browse_data_api_endpoint}/multipleFileDownloads`, body,{
  responseType: 'blob', reportProgress: true,
  observe: 'events' })

}


get_partitions(){

  
  return this.vService.get_all_partitions().map(res=>{

    return {name: res, code: res}
  })


}


get_public_data_filters() {
  return this.http.post(
    `${this.loaderPService.browse_data_api_endpoint}/datasource/getFilters`,
    { filters: {} }
  );
}

get_well_schematic_data(request_body) {

  return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/getWellboreSchematicData`,request_body);
  
}

}


