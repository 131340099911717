<div class="traceviewer-container" *ngIf="!errorFlag">

  <div class="metadata-selection" *ngIf=!maximizedFlag>

      <p-tabView (onChange)="on_tab_change($event)">
          <p-tabPanel header="Textual Header">
            <p-scrollPanel [style]="{ height: '73vh', width: '35vw'}">
              <div>
                  <pre *ngFor="let line of textualHeaders">
                    {{line}}
                  </pre>
              </div>
            </p-scrollPanel>
          </p-tabPanel>
          <p-tabPanel header="Binary Header">
                      <div>
                        <p-table [columns]="binaryHeaderColumns" [value]="binaryHeaders"
                          styleClass="p-datatable-sm p-datatable-gridlines" [loading]="headerLoading"
                          [tableStyle]="{ 'table-layout': 'auto' }" [scrollable]="true" scrollHeight="68vh">
                
                        <ng-template pTemplate="header">
                          <tr>
                              <th style="width:16.5rem;">Attribute</th>
                              <th style="width:calc(30vw - 12rem)">Value</th>
                          </tr>
                        </ng-template>
                
                        <ng-template pTemplate="body" let-rowData let-col="binaryHeaderColumns">
                          <tr *ngFor="let col of binaryHeaderColumns">
                            <td style="width:16.5rem;font-weight: 500;">{{col}}</td>
                            <td style="width:calc(30vw - 12rem)">{{ rowData[col] }}</td>
                          </tr>
                        </ng-template>
                
                        </p-table>
                      </div>
          </p-tabPanel>
          <p-tabPanel header="Trace Header">
              <div class="grid p-shadow-1">

                  <p-table #dt [lazy]="true" [columns]="traceHeaderColumns" [value]="traceHeaders"
                    [scrollable]="true" scrollHeight="68vh" scrollDirection="both"
                    styleClass="p-datatable-sm p-datatable-gridlines" [tableStyle]="{ 'table-layout': 'auto' }" 
                    [loading]="loading">

                    <ng-template pTemplate="caption">
                      <div class="caption-div">
                        <p>Total Traces: {{traceHeaderTotalRecords}}</p>
                        <div class="filter-item p-fluid slider" style="text-align: center; padding-right:2px; padding-bottom:2px;">
                            <input type="number" (keyup.enter)="change_trace_header(value)" pInputText [(ngModel)]="value" class="trace-input" pTooltip="Enter Trace Number"/>
                            <p-slider pTooltip="Slide to select Trace Number" [(ngModel)]="value" (onSlideEnd)="change_trace_header(value)" [max]="traceHeaderTotalRecords" [min]="1"></p-slider>
                        </div>
                      </div>
                     
                    </ng-template>

                    <ng-template pTemplate="header">
                      <tr>
                          <th style="width:12rem;">Attribute</th>
                          <th style="width:12rem;">Offset</th>
                          <th style="width:calc(30vw - 19.5rem)">Value</th>
                      </tr>
                    </ng-template>

                    <ng-template pTemplate="body">
                      <tr *ngFor="let col of traceHeaderColumns; let i = index">
                        <td style="width:12rem;font-weight: 500;">{{col}}</td>
                        <td style="width:12rem;">{{offsetList[col]}}</td>
                        <td style="width:calc(30vw - 19.5rem)">{{traceHeaderData[0][i]}}</td>
                      </tr>
                    </ng-template>
                    
                  </p-table>

                 
                
                </div>
          </p-tabPanel>
      </p-tabView>
         
  </div>

  <p-divider layout="vertical"></p-divider>
  
  <div>
      <div>
        <i pTooltip="Change Settings" class="fas fa-cog caption-icons" style="text-align: right;float: right" (click)="op.toggle($event)"></i>
      </div>

      <p-overlayPanel #op>
        <div class="caption-div">
          <p style="font-weight: 500;">Select a color Map:</p>
        </div>
        <p-dropdown [options]="this.seismicColorMaps" [(ngModel)]="selectedSeismicColorMap" optionLabel="name" [filter]="true" 
        filterBy="name" placeholder="Select a Color Map" (onChange)="changeInput()">
            <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedSeismicColorMap">
                    <div>{{ selectedSeismicColorMap.name }}</div>
                </div>
            </ng-template>
            <ng-template let-color pTemplate="item">
                <div class="flex align-items-center gap-2">
                    <div>{{ color.name }}</div>
                </div>
            </ng-template>
        </p-dropdown>
      </p-overlayPanel>
  
      <canvas style="height: 99%;width: 112%;" #trace_viewer></canvas>
  
  </div>


</div>
<div>
  <div class="caption-div" *ngIf="errorFlag">
    <p style="font-weight: 500; color:red">Error Loading Seismic Viewer</p>
  </div>
</div>










