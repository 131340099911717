import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import { DataTablesService } from 'src/app/data-tables/data-tables.service';
import { MapComponent } from '../map/map.component';

@Component({
  selector: 'app-deviation-viewer',
  templateUrl: './deviation-viewer.component.html',
  styleUrls: ['./deviation-viewer.component.css']
})
export class DeviationViewerComponent implements AfterViewInit{

  data;
  layout;
  deviation_data;
  show_map=false;
  @Input() selectedRows;
  loading=false;
  error=false;
  error_message;
  map_data;
  rows;

  @ViewChild('map') mapView: MapComponent;

  constructor(private service:DataTablesService) { }

  ngAfterViewInit(): void {


    // let rows = this.selectedRows.map(r=>{
    //  return {"ubhi":r["UBHI"],"id":r["ProcID"]} 
    // })

    this.rows = this.selectedRows;

    this.loading=true;


    this.service.get_deviations(this.rows).subscribe(res=>{

      if (res["error"].length>2) {

          this.error = true;
          this.error_message=res["error"]
        
      }

      this.data=res["plot"];

      this.loading=false;
      this.map_data=res["map_data"]

      setTimeout(() => {
        this.show_map=true;

        setTimeout(() => {

          this.plot_map();
          
        }, 50);
       
        
      }, 150);
      this.deviation_data=res["data"]

      this.layout = {
        'autosize': true,
        'showlegend': false,
        'colorway':["#636efa", "#ef553b",
        "#00cc96", "#ab63fa",
        "#ffa15a", "#19d3f3",
        "#ff6692", "#b6e880",
        "#ff97ff", "#fecb52"],
       
        'scene': {

          'camera':{center:{x:0.2,y:0.2,z:0}},
          'zaxis': {
            'autorange': 'reversed',
            'backgroundcolor': "#f9eaff",
            'showbackground': true,
            'gridcolor': "white"
          },
          'xaxis': {
  
            'backgroundcolor': "#ffffea",
            'showbackground': true,
            'gridcolor': "white"
          },
          'yaxis': {
  
            'backgroundcolor': "#eaf6ff",
            'showbackground': true,
            'gridcolor': "white"
          }
  
        },
        'margin': {
          'l': 0,
          'r': 0,
          'b': 0,
          't': 0
        },
  
        'width': '750',
        'height':'700'
      }
    
    })
  }

  plot_map(){

    let map_recs={"search":{

      "title":[],
      "GeoJSONextent":[],
      "key":"title",
      "color":[]
    }}

    this.map_data.forEach(element => {

      map_recs["search"]["GeoJSONextent"].push(element);
      map_recs["search"]["title"].push(element["title"]);
      map_recs["search"]["color"].push("yellow")

    });

    this.mapView.map_data=map_recs;
    this.mapView.plot_geojson("search");

  }

  downloadFile(){

    if(this.selectedRows.length == 1){
      let fileName = this.selectedRows[0]["Borehole Name"] + ".csv"
      this.downloadDevationData(fileName);
    } else {
      this.downloadDevationData("Deviation.zip");
    }

  }

  downloadDevationData(fileName){
    this.service.download_deviations_data(this.rows).subscribe((res:any) =>{
      if(res.body){
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(res.body)
        a.href = objectUrl
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(objectUrl);
      }
    });
}

}
