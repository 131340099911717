<div class="filter-container p-fluid">

    <div class="side-nav-heading">

      <p>Filters</p>

      <div style="padding-right:0.5rem">
        <i *ngIf="!loading" class="pi pi-times cross" (click)="close()"></i>
        <i *ngIf="loading" class="pi pi-spin pi-spinner spinner2"></i>
       </div>
  </div>


    <div  class="dropdowns" *ngFor="let filter of filters_list;let i=index" >

      <h5 style="margin-bottom: 0.7rem;">{{filter.attribute}}</h5>
      <p-multiSelect 
      [options]="filter.values" 
      [placeholder]="filter.values.length ? filter.values.length  + ' options': 'Loading'" 
      [virtualScroll]="true"   
      [(ngModel)]="selected[i]"
      (ngModelChange)="set_filters(filter.attribute,i)"
      [showTransitionOptions]="'0ms'" 
      [hideTransitionOptions]="'0ms'"
      [maxSelectedLabels]="0" 
      itemSize="40"
      [selectedItemsLabel]="'{0} items selected'"
      optionLabel="name"></p-multiSelect>

    </div>

</div>

<div class="apply-btn">

  <p-button label="Apply" styleClass="p-button" (onClick)="apply_filter()"></p-button>

</div>

