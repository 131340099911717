import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderPortalServicesService } from '../services/loader-portal-services.service';

@Injectable({
  providedIn: 'root'
})
export class SearchPortalService {

  constructor(
    private http:HttpClient,
    public loaderPService:LoaderPortalServicesService
  ) { }

  show_progress=false;
  search_text=null;
  indices=[];
  show_index=null;
  search_results={};
  filter_options=[];
  selected_mode="MAP";
  selected_indexes=[];
  filters=[]
  indexes_with_filter=[];
  index_count_map={};  
  selected_records={};

  search(){
    let body = {
      search_string:this.search_text,
      filters:this.filters,
      indices:this.selected_indexes,
      page_size:8,
      start:0,
      mode:this.selected_mode

    }
    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/search`,body)
  }

  search_index(index_name,start,size){

    let body = {
      search_string:this.search_text,
      filters:this.filters,
      indices:[index_name],
      page_size:size,
      start:start,
      mode:this.selected_mode
    }

    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/search`,body)
  }

}
