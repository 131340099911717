import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import {Alignment} from '@int/geotoolkit/layout/BoxLayout';
import { LoaderPortalServicesService } from 'src/app/services/loader-portal-services.service';
import { NodeServerDataProvider } from './node';
import {Plot} from '@int/geotoolkit/plot/Plot';
import { RemoteReaderDataProviderRegistry } from '@int/geotoolkit/seismic/data/RemoteReaderDataProviderRegistry';
import {RemoteSeismicDataSource} from '@int/geotoolkit/seismic/data/RemoteSeismicDataSource';
import {SeismicColors} from '@int/geotoolkit/seismic/util/SeismicColors';
import {SeismicPipeline} from '@int/geotoolkit/seismic/pipeline/SeismicPipeline';
import {SeismicWidget} from '@int/geotoolkit/seismic/widgets/SeismicWidget';
import { ToolsContainer } from "@int/geotoolkit/controls/tools/ToolsContainer";

@Component({
  selector: 'app-trace-viewer',
  templateUrl: './trace-viewer.component.html',
  styleUrls: ['./trace-viewer.component.css']
})
export class TraceViewerComponent implements AfterViewInit, OnInit{
  
  @ViewChild("trace_viewer") canvas:any;
  @Input() file_path;
  @Input() maximized: EventEmitter<boolean>;

  dataSource:RemoteSeismicDataSource;
  pipeline:SeismicPipeline;
  widget:SeismicWidget;
  plot:Plot;
  seismicFilePath;
  textualHeaders = [];
  binaryHeaders = [];
  traceHeaders = [];
  binaryHeaderColumns = [];
  traceHeaderColumns = [];
  total_records;
  traceHeaderData = [];
  traceHeaderTotalRecords : number = 0;
  traceHeaderRequestedRecords;
  selectedRows;
  loading = true;
  headerLoading = true;
  traceHeadersRowsSize = 1;
  seismicColorMaps = [];
  selectedSeismicColorMap;
  offsetList;
  traceHeaderError;
  value;
  maximizedFlag = false;
  errorFlag = false;

  constructor(private dataService:LoaderPortalServicesService) { 

    RemoteReaderDataProviderRegistry.getInstance().register('node', new NodeServerDataProvider({}));
  }

  textualHeader = true;
  seismicViewer = false;
  ngOnInit(): void {

    //this.file_path = '/data/temp/Westside_GMA2021_Phase_1/Segy/GMA21PH1 Acquisition Data/Acquisition/SEGY_GEOM_DATA/WESTSIDE_GMA2021_PHASE_1_GMA21-04_SHOTGATHERS_CORRELATED.segy';

    this.selectedSeismicColorMap = {name:'RedWhiteBlack', label:'RedWhiteBlack'};
    SeismicColors.getDefault().listNameColorMaps().forEach(data => {
      let node = {label:data,name:data}
      this.seismicColorMaps.push(node);
    });

    this.dataService.getHeaderDetailsOfTraceFile(this.file_path,"EBCDIC",0,0).subscribe(resp => {
      resp["headerDetails"].forEach(element => {
        this.textualHeaders.push(element)
      });
    }, error => {
      this.errorFlag = true;
    });

    this.dataService.getHeaderDetailsOfTraceFile(this.file_path,"Binary",0,0).subscribe(resp => {
      this.binaryHeaders.push(resp["headerDetails"])
      this.binaryHeaderColumns = resp["columns"];
      this.headerLoading = false;
    },error => {
      this.errorFlag = true;
    });
    let fromPoint = 0;
    let toPoint = this.traceHeadersRowsSize;
    this.dataService.getHeaderDetailsOfTraceFile(this.file_path,"TraceHeaders",fromPoint,toPoint).subscribe(resp => {
      this.traceHeaders.push(resp["headerDetails"])
      this.traceHeaderColumns = resp["columns"];
      this.traceHeaderRequestedRecords = resp["totalRequestedRecords"];
      this.traceHeaderTotalRecords = resp["totalRecords"];
      this.offsetList = resp["offsetList"];
      this.value = this.value != undefined ? this.value = this.value : this.value = 1
      let data = this.traceHeaders[0];
      data.forEach(d => {
        this.traceHeaderData.push(d);
      });
      this.loading = false;
    },error => {
      this.errorFlag = true;
    });

    this.create_datasource();
    this.create_trace_viewer();

    this.maximized.subscribe( flag => {
      if(flag){
        this.maximizedFlag = true;
        this.maximize_canvas();
      }
      if(!flag){
        this.maximizedFlag = false;
        this.minimize_canvas();
      }
    });
  }

  ngAfterViewInit() {
   
  }

  create_trace_viewer(){
    
    this.dataSource.open( () => {
        this.dataSource.select({}, (reader:any) => {   
            
            this.pipeline = new SeismicPipeline('Seismic', reader, reader.getStatistics());

            this.pipeline.setOptions(this.get_pipeline_options());

            this.widget = new SeismicWidget(this.pipeline,this.get_seismic_widget_options() );

            this.plot = new Plot({
                "canvasElement": this.canvas.nativeElement,
                "root": this.widget,
                'autoSize': false,
                'actualSize': true
            });

            const toolContainer = new ToolsContainer(this.plot);
            toolContainer.add(this.widget.getTool());

        });
    }, (err:any) => {}
    );


  }

  get_pipeline_options(){

    return {
        'normalization': {

            'scale': 0.4
        },
        'plot': {
            'type': {
                'Wiggle': false,
                'InterpolatedDensity': true
            },
            'decimationSpacing': 5,
        },
        'colors': {
            'colorMap': SeismicColors.getDefault().createNamedColorMap(this.selectedSeismicColorMap.name)
        },

        
    }
  
}

get_seismic_widget_options(){

    return {
        'colorbar': {
            'axis': {
                'tickgenerator': {
                    'edge': {
                        'tickvisible': false,
                        'labelvisible': false
                    }
                },
                'size':90
            }
        },
        "maxheight": "90%",
        "alignment": Alignment.Center

      }
}

  create_datasource(){
    this.dataSource = new RemoteSeismicDataSource({
        'file': this.file_path,
        'version': 'node',
        'dataService':this.dataService
    });
  }

  on_tab_change(event){
    if(event.index == 0){
      this.textualHeader = true;
      this.seismicViewer = false;
    }
    if(event.index == 1){
      this.seismicViewer = true;
      this.textualHeader = false;
    }

  }

  change_trace_header(event) {
    if(event > this.traceHeaderTotalRecords || event == 0){
      [].forEach.call(document.querySelectorAll('.trace-input'), function (el) {
        el.style.border = '2px solid';
        el.style.borderColor = 'red';
      });
      return;
    }

    [].forEach.call(document.querySelectorAll('.trace-input'), function (el) {
      el.style.border = 'none';
      el.style.borderColor = 'none';
    });
    
    this.traceHeaderData = [];
    this.traceHeaders = [];
    this.loading = true;
    let fromPoint = event - 1;
    let toPoint = event;
    if(toPoint > this.traceHeaderTotalRecords){
      toPoint = this.traceHeaderTotalRecords
    }
    this.dataService.getHeaderDetailsOfTraceFile(this.file_path,"TraceHeaders",fromPoint,toPoint).subscribe(resp => {
      this.traceHeaderError = resp["error"];
      if(this.traceHeaderError != null){
        this.loading = false;
        return;
      }
      this.traceHeaders.push(resp["headerDetails"]);
      this.traceHeaderColumns = resp["columns"];
      this.traceHeaderRequestedRecords = resp["totalRequestedRecords"];
      this.traceHeaderTotalRecords = resp["totalRecords"];
      let data = this.traceHeaders[0];
      data.forEach(d => {
        this.traceHeaderData.push(d);
      });
      this.loading = false;
    });
  }

  changeInput(){
    this.widget.getPipeline().setColorMap(SeismicColors.getDefault().createNamedColorMap(this.selectedSeismicColorMap.name))
  }

  maximize_canvas(){
    let x = 150;
    let height;
    let width;
    [].forEach.call(document.querySelectorAll('.p-dialog-content'), function (el) {
      height = el.clientHeight;
      width = el.clientWidth;
    });
    this.plot.setSize(width+x,height);
  }

  minimize_canvas(){
    let x = 150;
    let y = 5
    let height;
    let width;
    [].forEach.call(document.querySelectorAll('.p-dialog-header'), function (el) {
      width = el.clientWidth;
    });
    [].forEach.call(document.querySelectorAll('.traceviewer-container'), function (el) {
      height = el.clientHeight;
    });
    width = 55/100 * width;
    this.plot.setSize(width-x,height-y);
  }


}

