import { Component, Input, OnInit, SimpleChanges, OnChanges, ComponentRef, OnDestroy, ElementRef, AfterViewInit, ViewChild, Output, EventEmitter, APP_INITIALIZER } from '@angular/core';
import { TreeModule } from 'primeng/tree';
import { stringify } from 'querystring';
import { range } from 'rxjs';
import { ViewModelService } from '../browse-data/view-model.service';
import { GlobalCompService } from '../global-comps/global-comps-services/global.service';
import { BackendApiService } from '../services/backend-api.service';


declare let L;
declare let turf;


@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.css']
})
export class LeafletMapComponent implements AfterViewInit, OnChanges, OnDestroy,OnInit {

  @Input() data: any[] = [];
  @Input() showGlobalFilters = false;
  @Input() globalFilters;
  @Output() selected:EventEmitter<any> = new EventEmitter();
  @Output() activateLogViewer:EventEmitter<any> = new EventEmitter();
  @Output() showTree = new EventEmitter();

  @Output() change_partition = new EventEmitter()

  items = [
    {
        label: 'Open With',
        items: [{
                label: 'LOG VIEWER', 
                icon: 'fab fa-gitter',
                command: ()=>{this.test("launch viewer")}
            }]
    }
  
]

  datasets;
  boreholes;
 
  selectedCountries;
  layout:any = {
    mapbox:{
      style:"open-street-map"
    }
  }


  colorway = ["#636efa", "#ef553b",
  "#00cc96", "#ab63fa",
  "#ffa15a", "#19d3f3",
  "#ff6692", "#b6e880",
  "#ff97ff", "#fecb52"]

  layers = [

    {name:"Boreholes",key:0},
    {name:"Boreholes (public)",key:1},
    {name:"Seismic",key:2},
    {name:"Petroleum Permits",key:3},
  ]

  map_id = String(Math.random())
  selection_list:any[] = [];
  labels=false;
  all_selection_layers:any[] = [];
  map: any;
  layergroup: any;
  drawnitems: any;
  main_layers: any[] = [];
  index_plot;
  selectedLayers=[0,1,2];
  main_layers_cpy;
  custom_div=false;
  selected_extent={}
  loading=false;
  drawControl;
  charts;
  show_details_panel;
  clikedLayers=[]
  multi_select=false;
  bh_labels;
  seis_labels;

  panel_data;

  correlation_line;

  @ViewChild('map-container') map_elem: ElementRef;

  @Output() selectedRecords = new EventEmitter<any>();


  constructor(private service :BackendApiService,
    private gService:GlobalCompService,
    private vService:ViewModelService) { 

    this.charts= this.service.get_dashboard_charts()
    
  }

  ngOnChanges(changes: SimpleChanges): void {


  }

  ngOnInit(){

    this.gService.map_cache.forEach(x=> 
      {this.main_layers.push(null);this.data.push(null)})

  }

  ngAfterViewInit() {

  

    this.render_basemap()
    this.activate_map();
    this.add_draw_controls();
    this.add_draw_callbacks_bh();
   
  
  }

  on_partition_selection(){

    this.change_partition.emit()
    
  }


test(x){

  this.activateLogViewer.emit(true)
}


activate_map(){

  this.loading=true;
  
  if(this.showGlobalFilters){

    
  }else{

    this.get_map_data()

  }

}


get_map_data(){

  this.loading=false;
  let extent = {}
  let total=0;
  let received=0;

  let current_view = this.vService.get_view()

  this.gService.map_cache.forEach((map_layer,indx)=>{

    if(!map_layer && current_view.tree[indx]["partition"] == this.vService.active_partition){

      total=total+1;

      this.loading=true;

      this.gService.get_map_cache(indx,extent).subscribe(res=>{

        received = received +1;
        this.gService.map_cache[indx]=JSON.stringify(res);

        this.data[indx]=res;

        this.plot_geojson(indx)

        // if(this.selectedLayers.includes(indx) && !this.gService.linked_points[0].length){

        //   this.plot_geojson(indx)
        
        // }
        // else{

        //   this.selectedLayers=[0]
        //   this.get_map_points(0)

        // }

        if(total == received){
     
          this.loading=false;

        }

      })

    }else{

      if(indx == this.vService.active_subcontext)

      this.get_map_points(indx)
    }
  })
  
}


  get_map_points(indx){

    this.gService.get_linked_points().subscribe(res=>{

      this.loading=false;

      let points = res[0]["values"]

      let cache = JSON.parse(this.gService.get_stored_cache());


    let map_key = this.vService.get_tree_link()

    this.data[indx]={keys:[],GeoJSONextent:[],availability:[]}

      points.forEach((element)=>{

        let index = cache[map_key].indexOf(String(element))
        if(index > -1){

          this.data[indx]["keys"].push(cache[map_key][index])
          this.data[indx]["GeoJSONextent"].push(cache["GeoJSONextent"][index])
        }
        if(cache["availability"]){
          this.data[indx]["availability"].push(cache["availability"][index])
        }
      });


      setTimeout(() => {

        if(!this.selectedLayers.includes(indx)){

          let temp = [];

          this.selectedLayers.forEach(x=>temp.push(x))

          temp.push(indx)

          this.selectedLayers=temp;
        }
   
        this.plot_geojson(indx)
        
      }, 20);

    })

    
  }


  plot_geojson(indx){

 
    if (this.main_layers[indx]) {

      this.map.removeLayer(this.main_layers[indx])

      this.main_layers[indx]=null;
    }
    
    let index=0

    let me=this;

    let key ="hole_id"

    if(!me.data[indx][key]){

      key="keys"
    }

    this.main_layers[indx] = L.geoJSON(null, {

      onEachFeature: function (feature, layer) {

        layer.bindTooltip("<h4>"+me.data[indx][key][index]+"</h4>");

        index=index+1;
      },
      pointToLayer: function (point, latlng) {

        let color = "#a1fcde"

        let stroke=false;

        // let opa = 1;

        // if(indx == 1){

        //   color="#18e91a"
        // }

        // if(indx == 1 && me.vService.active_view_model == 'all_datasets2'){

        //   color = "#d7d02a"
        // }


        // if((indx == 0 || indx==2) && me.data[indx]["availability"][index] == 'N'){
          
        //   stroke = true;
        //   opa=0;
        // }

        return L.circleMarker(latlng, { radius: 1.5, stroke: stroke,color:color, fillColor:color,weight:1, fillOpacity: 1,key:me.data[indx][key][index],subContext:indx })

        // return L.circleMarker(latlng, { radius: 1.5, stroke: stroke,color:color, fillColor:color,weight:1, fillOpacity: 1,subContext:indx })
      },
      style:function(feature,layer){
        
        // if(indx == 5){

        //   return {color:"#a1fcde",weight:2,opacity:0.8,fill:false}
        // }
       
       
      },
    }).addTo(this.map)


    this.main_layers[indx].on('click',(e)=>this.onLayerSelect(e.layer))


    setTimeout(() => {

      this.main_layers[indx].addData(this.data[indx]["GeoJSONextent"])
      this.reset_map_bound();

      // if(this.selectedLayers.includes(0)){

      //   this.main_layers[0].bringToFront()
      // }
      
    }, 10);

   
  }

  toggle_layers(selected_key){


    if(!this.selectedLayers.includes(selected_key)){

      this.map.removeLayer(this.main_layers[selected_key])

      this.main_layers[selected_key]=null;

    }else if(selected_key == 3){
      
      let layer = L.tileLayer('https://api.mapbox.com/styles/v1/udit29/cl0eqed8k000014p31yfr3imv/draft/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidWRpdDI5IiwiYSI6ImNrMHJ6bHAyYzBiMWozY293c2VveG0wbHoifQ.RPsYeZBrnxuYYPOeA4s9dQ').addTo(this.map)
    
      this.main_layers.push(layer)

    }

    else{

      this.plot_geojson(selected_key)
    }

  }


  onLayerSelect(layer){

    if(!this.multi_select){

      
      if(this.clikedLayers.includes(layer)){

        this.show_details_panel=false;

        this.panel_data=null;

        this.clikedLayers[0].setStyle({stroke:layer.options.origStroke,color:layer.options.origColor})
        
        this.clikedLayers=[]
      
      }else{

        if(this.clikedLayers.length){

          this.clikedLayers[0].setStyle({stroke:layer.options.origStroke,color:layer.options.origColor})
        
          this.clikedLayers=[]
        }
  
        layer.setStyle({stroke:true,color:'red',origColor:layer.options.color,origStroke:layer.options.stroke})
  
        this.clikedLayers.push(layer)

        this.show_details_panel=true;

        this.panel_data=null;

        this.loading=true;

        this.gService.get_well_details(layer.options.key).subscribe(res=>{
    
          this.loading=false;
    
          this.panel_data=res
        })
  
      }


    }

  }


  close_panel(){

    this.show_details_panel=false;

    let layer = this.clikedLayers[0]

    layer.setStyle({stroke:layer.options.origStroke,color:layer.options.origColor})
        
    this.clikedLayers=[]
    
  }




  plot_on__hover(geojson,title){

    var icon = L.divIcon();

    this.index_plot = this.main_layers.length;

    this.main_layers.push(
      
      L.geoJSON(null,{

      pointToLayer: function (point, latlng) {
        return L.marker(latlng,{icon:icon});
      }

    }).addTo(this.map))


    this.main_layers[this.index_plot].addData(geojson);

    let layers = this.main_layers[this.index_plot];

    layers.getLayers()[0].bindPopup(title).openPopup();

  }

  remove_on_hover(){

    this.map.removeLayer(this.main_layers[this.index_plot])
  }

  fitbounds(geojson){

    let me = this;

    L.geoJSON(geojson,{

      pointToLayer: function (point, latlng) {

        me.map.fitBounds(latlng.toBounds(500));
      }

    }).addTo(this.map);


  }

  set_zoom_based_style(radius){


    this.main_layers.forEach((lay,i) => {


      if(lay && (i==0 || i==1 || i==2 )){

        lay.getLayers().forEach((lay,i)=>{

          lay.setStyle({radius:radius})

        })

      }
      
  })
  }


  add_draw_callbacks_bh(){

    let me = this;

    me.selection_list=[];
    me.all_selection_layers=[];

    // this.map.on('moveend',function(e){

    //   if(me.map.getZoom()<=8){

    //       me.set_zoom_based_style(2)
    //   }

    //   if(me.map.getZoom()>=8 && me.map.getZoom()<=11){

    //       me.set_zoom_based_style(5)
      
    //   }

    //   if(me.map.getZoom()>11){

    //       me.set_zoom_based_style(8)
     
    //   }


    //   if(me.map.getZoom()>=13.5){

    //     me.set_zoom_based_style(9)

    //     let test = me.map.getBounds();
    //     let latlngs = [[test.getSouthWest().lat,test.getSouthWest().lng],[test.getSouthEast().lat,test.getSouthEast().lng],
    //     [test.getNorthEast().lat,test.getNorthEast().lng],[test.getNorthWest().lat,test.getNorthWest().lng]
    //     ]

    //     me.createLabels(L.polygon(latlngs).toGeoJSON())

    //   }
    //   else{
    //     if(me.labels==true){

    //       me.removeLabels();

    //       }
    //     }
      
    // })

    this.map.on('draw:created',function(e){

      var layer_geoJSON;

      if (e["layerType"] == "circle") {

        layer_geoJSON = turf.circle([e.layer._latlng["lng"], e.layer._latlng["lat"]],
          e.layer._mRadius / 1000, { steps: 100, units: 'kilometers' });

      }
      else {

        layer_geoJSON = e.layer.toGeoJSON();

      }

      if(e["layerType"] == "polyline"){

        me.main_layers[0].getLayers().forEach((lay,indx)=>{

        var pointInLayer = turf.pointOnFeature(lay.toGeoJSON())

        if(turf.pointToLineDistance(pointInLayer,layer_geoJSON,{units: 'kilometers'})<0.15){
   
          let cache = me.data[0]["keys"]
          me.selection_list.push({attribute:"Borehole", value:cache[indx]});

        }

     
        })

        me.correlation_line = L.geoJSON(e.layer.toGeoJSON(), {
          style: function (feature) {
              return {weight:2,color:'red'};
          }
          }).addTo(me.map)

        me.process_selected_points(me.selection_list)

      }else{

        me.selected_extent=layer_geoJSON;

        if(me.vService.active_view_model == 'all_datasets' || me.vService.active_view_model == 'all_datasets2' ){

          me.fetch_map_data_and_set_keys()
  
        }

      }

    
    })


  }


  fetch_map_data_and_set_keys(){

    let total=0;
    let received=0;

    this.gService.clear_filters()

    this.gService.map_cache.forEach((map_layer,index)=>{

        this.loading=true;

        total=total+1;

        let extent = this.selected_extent;
  
        this.gService.get_map_cache(index,extent).subscribe(res=>{
  
          received = received +1;

          let ds = this.vService.get_parent_datasource_from_sub_context_index(index)

          let pk = this.vService.get_tree_link_for_provided_context(index)
          
          this.gService.global_points[index] = [{attribute:pk,attributeType: "LONG",dataSource: ds,operator:"=",values:res[pk]}]
          

          this.gService.map_cache[index]=JSON.stringify(res);

          this.data[index]=res;

          if(total == received){
            
            this.loading=false;

            this.data.forEach((x,index)=>{ 
              
              if(this.selectedLayers.includes(index)){

                this.plot_geojson(index)
              }
            
            })
            
            this.selectedRecords.emit(true)
          }
  
        })
  
      
    })


  }


  send_filter_event(){

      this.selected.emit("selected")

  }

  reset_map_bound(){

    this.map.fitBounds(this.main_layers[this.vService.active_subcontext].getBounds()); 

  }

 

  set_map_bounds() {

    let latLongBounds = this.main_layers.map(lay=>lay.getBounds());

    let bound = latLongBounds[0];

    latLongBounds =  latLongBounds.slice(1)

    latLongBounds.forEach(bnd => bound.extend(bnd))

    this.map.fitBounds(bound); 

  }


  setView(){

    this.map.invalidateSize()
  }

  render_basemap() {
    
    this.map = L.map(this.map_id,{maxZoom:15,zoomControl: false}).setView([0,0], 2);

    L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
    { id: 'satellite' + "-v9", attribution: '', maxZoom:20, accessToken: 'pk.eyJ1IjoidWRpdDI5IiwiYSI6ImNrMHJ6bHAyYzBiMWozY293c2VveG0wbHoifQ.RPsYeZBrnxuYYPOeA4s9dQ' }).addTo(this.map)



    // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);
    

    // L.tileLayer('https://api.mapbox.com/styles/v1/udit29/cl0eqed8k000014p31yfr3imv/draft/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidWRpdDI5IiwiYSI6ImNrMHJ6bHAyYzBiMWozY293c2VveG0wbHoifQ.RPsYeZBrnxuYYPOeA4s9dQ').addTo(this.map)
  

    // L.tileLayer('https://api.mapbox.com/styles/v1/udit29/cl0es2nd9003g14kozh85umuv/draft/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidWRpdDI5IiwiYSI6ImNrMHJ6bHAyYzBiMWozY293c2VveG0wbHoifQ.RPsYeZBrnxuYYPOeA4s9dQ').addTo(this.map)
  
  }

  add_draw_controls() {

    this.drawnitems = L.featureGroup();
    this.map.addLayer(this.drawnitems);

    this.drawControl = new L.Control.Draw({
      draw: {
        polygon: {
          shapeOptions: {
            color: 'blue',
            fillOpacity: 0.4,
            fillColor: 'white',
            dashArray: "6",
            weight: 2
          },

        },
        rectangle: {
          shapeOptions: {
            color: 'blue',
            fillOpacity: 0.4,
            fillColor: 'white',
            dashArray: "6",
            weight: 2
          },
        },
        circle: {
          shapeOptions: {
            color: 'blue',
            fillOpacity: 0.4,
            fillColor: 'white',
            dashArray: "6",
            weight: 2
          },
        },
        polyline: { shapeOptions: {
          color: 'blue',
          fillOpacity: 0.4,
          fillColor: 'white',
          dashArray: "6",
          weight: 2
        }
      },
        marker: false,
        circlemarker: false

      },
      position:'topright'
    });

    this.map.addControl(this.drawControl);

    // this.createIcons('card',"right");
    this.createIcons('layer_selector',"right")

    // this.createIcons('layer-details-panel',"right")

    // this.createIcons('clear-filter',"");

  }


  removeLabels(){

    let sbcntxts = this.vService.get_all_subcontexts()

    let me =this;
  
    sbcntxts.forEach((cnt,indx)=>{
  
      let key = this.vService.get_tree_link_for_provided_context(indx)
  
    
      if(!me.data[indx][key]){
    
        key="keys"
      }
  
      if(this.main_layers[indx]){
  
        this.main_layers[indx].getLayers().forEach((lay,i)=>{
    
          lay.closeTooltip();
          lay.unbindTooltip();
          lay.bindTooltip("<h4>"+this.data[indx][key][i] +"</h4>");
        
          });
  
      }
    
    })
    

  this.labels=false;
}

createLabels(geoJSON){

  let sbcntxts = this.vService.get_all_subcontexts()

  let me =this;

  sbcntxts.forEach((cnt,indx)=>{

    let key = this.vService.get_tree_link_for_provided_context(indx)

  
    if(!me.data[indx][key]){
  
      key="keys"
    }

    if(this.main_layers[indx] && indx == 0){

      this.main_layers[indx].getLayers().forEach((lay,i)=>{
  
        var pointInLayer = turf.pointOnFeature(lay.toGeoJSON())
         
        if(turf.booleanPointInPolygon(pointInLayer,geoJSON)){
      
          lay.unbindTooltip();
          lay.bindTooltip(me.data[indx][key][i],{permanent:true,direction:'center', className:'class-tooltip'});
          lay.openTooltip(lay._latlng);
      
          me.labels=true;
        }
      
        });

    }
  
  })

  
   let col = Array.from(document.getElementsByClassName("leaflet-tooltip") as HTMLCollectionOf<HTMLElement>)

   let size ='11px';

   if(this.map.getZoom() > 13){

      size='11px'
   }


   for (let i = 0; i < col.length; i++) {

   col[i].style.background = "transparent";
   col[i].style.color="white";
   col[i].style.fontWeight="400";
   col[i].style.border="none";
   col[i].style.fontSize=size;
   col[i].style.boxShadow="none";
   col[i].style.margin="0px";
   col[i].style.padding="0px";
   col[i].style.fontFamily =  "Arial, Helvetica, sans-serif";
   
   
   
  }


}

set_publish(event){

  this.selection_list.push(event)

  this.process_selected_points(this.selection_list)

  this.show_details_panel=false;


}

  
  process_selected_points(selection_list) {


    if (selection_list) {

      let pairs = {};

      selection_list.forEach(elem => {

        if (elem.attribute in pairs) {

          pairs[elem.attribute].push(elem.value)
        }
        else {

          pairs[elem.attribute] = [elem.value]

        }
      })

      let filters = []

      for (var key in pairs) {

        filters.push({ [key]: [...new Set(pairs[key])] })
      }
      
      let activeSubContext = this.vService.active_subcontext;


      let datasource = this.vService.get_active_datasource()

      let filter_index = this.gService.get_datasource_index_mapping(datasource)
      
      let index = this.gService.all_filters[activeSubContext][filter_index]["filter_values"]
      .indexOf(this.gService.all_filters[activeSubContext][filter_index]["filter_values"].
      find(att=>att.attribute=='Borehole')
      )

      filters = filters[0]["Borehole"].map(att=>{
        return {code:att,name:att}
      })

     
      if(index == -1){

        this.gService.all_filters[activeSubContext][filter_index]["filter_values"]
        .push({"operator":"=","attribute":'Borehole',"selected":filters})
      }
      else{
  
        this.gService.all_filters[activeSubContext][filter_index]["filter_values"][index]["selected"]=filters;
        this.gService.all_filters[activeSubContext][filter_index]["filter_values"][index]["operator"]= "=";
        
      }

      this.showTree.emit();


      this.selectedRecords.emit(filters);

      this.selection_list=[];

    } else {

      // this.service.graphSelection = [];
    }


  }

  createIcons(id,position){

    L.Control.mybar = L.Control.extend({
  
      options: {
          closeButton: true,
          position: 'top-left',
          autoPan: true,
      },
  
      initialize: function (placeholder, options) {
          L.setOptions(this, options);
  
          // Find content container
          var content = this._contentContainer = L.DomUtil.get(placeholder);
  
          // Remove the content container from its original parent
          content.parentNode.removeChild(content);

          var l;

          if(position == 'right'){

             l = 'leaflet-top leaflet-right';

          }
          if(position == "left-bottom"){

            l= 'leaflet-top leaflet-left';
          }
      
          
  
          // Create sidebar container
          var container = this._container =
              L.DomUtil.create('div', l);
  
          // Style and attach content container
          L.DomUtil.addClass(content, 'leaflet-control');
          container.appendChild(content);
  
          
      },
  
      addTo: function (map) {
          var container = this._container;
          var content = this._contentContainer;
  
          // Attach sidebar container to controls container
          var controlContainer = map._controlContainer;
          controlContainer.insertBefore(container, controlContainer.firstChild);
  
          this._map = map;
      },
  
  });
  
  L.control.mybar = function (placeholder, options) {
      return new L.Control.mybar(placeholder, options);
  };

      L.control.mybar(id,{}).addTo(this.map);   
  }



  ngOnDestroy() {

    this.map.remove();
  }


}

