
<div class="container" *ngIf="!loading">

   
    <div class="viewers" *ngIf="!error">

        <app-plotly [data]="data" [layout]="layout"></app-plotly>

    </div>

    <div class="error" *ngIf="error">

        {{error_message}}

    </div>
    


</div>

<div class="spinner-container" *ngIf="loading">

    <i  class="pi pi-spin pi-spinner spinner2" style="font-size: 2rem;"></i>

</div>


