<div class="chips-panel center">
    
  

        <div>

            <div *ngIf="vService.active_subcontext!=null" class="center">

                <p-overlayPanel #op 
                showCloseIcon="true">
                    <ng-template pTemplate>
                        <div style="width:20rem;max-height:40rem;">
                            <app-filter (onFilter)="apply();op.toggle($event)"></app-filter>
                        </div>  
                        
                    </ng-template>
                </p-overlayPanel>
                
                <button  type="text" pButton label="FILTERS" icon="pi pi-angle-down" class="p-button-text btn" (click)="op.toggle($event)"></button>
               
            </div>

    </div>
   

    <div class="center">

        <div style="margin-left:0.2rem" *ngFor="let filter of applied_filters; let i=index">

            <p-chip label="{{filter.attribute+' '+labels[i]}}" (onRemove)="remove_filter($event,i)" [removable]="filter.dataSource==datasource"></p-chip>
           
        </div>

    </div>
    

    <div *ngIf="type_value_auto || type_value_manual || type_value_manual_date" style="margin-right: 0.2rem;" class="property-name">

        {{property}}

    </div>
    <div *ngIf="select_property && selectedContext" >
        <p-autoComplete 
        
        placeholder="Search Attribute" [completeOnFocus]="true"
        [(ngModel)]="property" [suggestions]="property_results"
        (onSelect)="selected_property()"
        [forceSelection]="true"
        [styleClass]="p-inputtext-sm"
        scrollHeight="150px"
        (completeMethod)="search_property($event)"></p-autoComplete>
    </div>

    <div *ngIf="select_operator" style="width:2rem" class="p-fluid" >
        <p-autoComplete       
         [completeOnFocus]="true"
        [(ngModel)]="operator" [suggestions]="operator_results"
        [forceSelection]="true"
        [styleClass]="p-inputtext-sm"
        (completeMethod)="search_operator($event)"></p-autoComplete>
    </div>

    <div id="text_enter" style="width:9rem" *ngIf="type_value_auto" class="p-fluid" >
        <p-autoComplete  
        #autocomplete   
        [(ngModel)]="value" [suggestions]="value_results"
        [forceSelection]="false"
        [styleClass]="p-inputtext-sm"
        (onKeyUp)="set_value_auto($event)"
        (onSelect)="set_value_from_click()"
        (completeMethod)="search_values($event)"></p-autoComplete>
    </div>

    <div *ngIf="type_value_auto">
        
        <p (click)="clear()" style="font-size: 0.9rem;color: rgb(243, 89, 89);margin-left: 0.5rem;cursor: pointer;">CLEAR</p>
        
    </div>
    

    <input style="width:6rem" id="text_enter" *ngIf="type_value_manual" type="text" placeholder="Enter Value"  class="p-inputtext-sm" pInputText [(ngModel)]="value" autofocus> 

    <p-inputMask *ngIf="type_value_manual_date" (onKeydown)="set_value_date($event)" mask="9999/99/99" [(ngModel)]="value" placeholder="yyyy-mm-dd"></p-inputMask>
    <!-- (ngModelChange)="change_text()" -->

</div>
