import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ThirdPartyDraggable } from '@fullcalendar/interaction';
import { AutoComplete } from 'primeng/autocomplete';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { DataModelService } from 'src/app/browse-data/data-model.service';
import { ViewModelService } from 'src/app/browse-data/view-model.service';
import { GlobalCompService } from '../global-comps-services/global.service';

@Component({
  selector: 'app-f-chips',
  templateUrl: './f-chips.component.html',
  styleUrls: ['./f-chips.component.css']
})
export class FChipsComponent implements OnInit {

  datasource;
  applied_filters;
  labels;
  property;
  operator="LIKE";
  applicable_operators;
  all_properties;
  property_results;
  operator_results=[]
  select_property=true;
  select_operator=false;
  type_value_auto=false;
  type_value_manual=false;
  type_value_manual_date=false;
  all_attributes;
  value;
  filter_index;
  all_contexts;
  all_partitions;
  selected_partition;
  show_contexts = false;

  type_timestamp=false;

  value_results=[];
  selectedContext;

  @Input() disable=false;

  @Output() onFilter: EventEmitter<any> = new EventEmitter();
  @Output() onContextChange :EventEmitter<any> = new EventEmitter();

  @ViewChild('autocomplete') autocomplete:AutoComplete; 

  constructor(public vService:ViewModelService,
    private gService:GlobalCompService,private dMService:DataModelService) { }

  ngOnInit(): void {


    this.datasource = this.vService.get_active_datasource();

    this.all_attributes = this.dMService.get_datasource_definition(this.datasource);
    
    this.all_properties = this.all_attributes.map(att=>att.attribute)

    this.get_paritions()
    
    this.all_contexts = [];

    // this.selectedContext = this.all_contexts[0]["code"]

    // this.set_filter_chips()

  }

  get_paritions(){

    this.all_partitions = this.gService.get_partitions();

  }

  set_filter_chips(){

    
    this.applied_filters =  this.gService.get_all_filters()


   this.labels = this.applied_filters.map(att=>{

    if(att.values.length > 2){

      att.values = att.values[0] +' & '+ (att.values.length - 1) + " others"
    
    }

    return att.operator+' '+att.values
   })

    
  }

  change_context(){
  

    this.vService.active_subcontext=this.selectedContext;

    this.vService.set_active_datasource_from_context()

    this.onContextChange.emit(this.vService.active_datasource)

  }


  on_partition_selection(){
    
    this.vService.active_partition = this.selected_partition;

    this.all_contexts = this.vService.get_views_for_selected_partition();

    if (this.all_contexts.length >1 ) {
       this.show_contexts = true;
    }else{

      this.selectedContext = this.all_contexts[0].code;
      setTimeout(() => {
        this.change_context()
      }, 10);
      
    }

  }
 


  search_property(event){

    let filtered : any[] = [];
    let query = event.query;

    for(let i = 0; i < this.all_attributes.length; i++) {
        let prop = this.all_properties[i];
        if (prop.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(prop);
        }
    }

    this.property_results = filtered;
  }


  selected_property(){

    this.value=null;
    this.select_property=false;

    this.set_operator_results()

    if(this.operator && this.operator == "LIKE"){

      this.type_value_auto=true;

      setTimeout(() => {

        this.autocomplete.focusInput()
      }, 20);

    }
    if(this.type_timestamp){

       this.select_operator=true;
        this.type_value_manual_date=true;

    }
    
    if(!this.type_timestamp && this.operator != "LIKE"){
     
      this.select_operator=true;
      this.type_value_manual=true;
      let self=this

      setTimeout(() => {
      
        var input = document.getElementById("text_enter");
        input.addEventListener("keyup", function(event) {
        if (event.code == "Enter" && self.value && self.value.length) {
  
            self.select_operator=false;
            self.type_value_manual=false; 
            self.select_property=true;
            self.apply_filter()
                
          }
        });
      }, 20);
    }
   

  
  }

  set_operator_results(){

    let attribute =  this.all_attributes.filter(x=>x.attribute == this.property)[0]

    let type = attribute.attributeType;

    if(type == "TIMESTAMP"){

      this.type_timestamp=true;
    }

    if(type == "STRING"){

      this.operator='LIKE'
      this.select_operator=false;
    }
    else{
      this.select_operator=true;
      this.operator=">"
      this.applicable_operators = [">","<","="]
    }

   
  }

  set_value_from_click(){

    this.operator="="

    this.select_operator=false;
    this.type_value_auto=false; 
    this.select_property=true;
    this.apply_filter()

  }

  set_value(event){

     if (event.code == "Enter" && this.value && this.value.length) {

          this.select_operator=false;
          this.type_value_auto=false; 
          this.select_property=true;
          this.type_value_manual=false;
          this.apply_filter()
          
        }
  }

  set_value_auto(event){

    if (event.code == "Enter" && this.value && this.value.length) {

         this.select_operator=false;
         this.type_value_auto=false; 
         this.select_property=true;
         this.type_value_manual=false;
         this.apply_filter()
             
       }
 }

  set_value_date(event){

    if (event.code == "Enter" && this.value && this.value.length) {

      this.select_operator=false;
      this.type_value_manual_date=false; 
      this.select_property=true;

      if(this.type_timestamp){

      
        this.value=this.value.replace("/","-")
        this.value=this.value.replace("/","-")
        this.value = this.value+' 00:00:00'
        this.type_timestamp=false;
        this.apply_filter()
      }

          
    }


  }

  search_values(event){

    let  attribute = this.all_attributes.find(att=>att.attribute == this.property)
    
    let filter = attribute;

    filter["operator"] = this.operator;

    filter["dataSource"] = this.datasource;

    filter["values"] = [event.query]

    this.gService.get_search_results([attribute],[filter],this.datasource).subscribe(res=>{
      
      this.value_results=res[0]["values"]
    
    },error=>{this.value_results=[]})

  }

  search_operator(event){

     this.operator_results= [] 
     
     this.operator_results = this.applicable_operators;
  }


  apply_filter(){

    this.filter_index = this.gService.get_datasource_index_mapping(this.datasource)

    let activeSubContext = this.vService.active_subcontext;

    let index = this.gService.all_filters[activeSubContext][this.filter_index]["filter_values"]
    .indexOf(this.gService.all_filters[activeSubContext][this.filter_index]["filter_values"].
    find(att=>att.attribute==this.property)
    )

    if(index == -1){

      this.gService.all_filters[activeSubContext][this.filter_index]["filter_values"]
      .push({"operator":this.operator,"attribute":this.property,"selected":[{code:this.value,name:this.value}]})
    }
    else{

      this.gService.all_filters[activeSubContext][this.filter_index]["filter_values"][index]["selected"]=[{code:this.value,name:this.value}];
      this.gService.all_filters[activeSubContext][this.filter_index]["filter_values"][index]["operator"]= "=";
      
    }



    this.property=null;
    this.value=null;
    this.operator=":";
    this.operator_results=[];
    this.property_results=[];
    this.applicable_operators=null;
    this.type_value_auto=false;
    this.type_value_manual=false;
    this.type_value_manual_date=false;

    this.set_filter_chips()

    this.onFilter.emit(true)
  }

  clear(){

    this.select_operator=false;
    this.type_value_auto=false; 
    this.select_property=true;
    this.type_value_manual=false;
    this.property=null;
  }

  remove_filter(event,indx){

    let activeSubContext = this.vService.active_subcontext;

    let index = this.gService.get_datasource_index_mapping(this.applied_filters[indx]["dataSource"])

    let filter_index = this.gService.all_filters[activeSubContext][index]["filter_values"].findIndex(x=>this.applied_filters[indx]["attribute"] == x["attribute"] )


    this.gService.all_filters[activeSubContext][index]["filter_values"] = this.gService.all_filters[activeSubContext][index]["filter_values"].filter((x,i)=> i != filter_index)
    

    this.gService.all_filters[activeSubContext][index]["fks"]=[];
    this.gService.all_filters[activeSubContext][index]["pks"]=null;
    this.gService.linked_points[activeSubContext]=[];

    

    if(!this.gService.all_filters[activeSubContext][index]["filter_values"].length){

      let temp = []

      this.gService.all_filters[activeSubContext] =  this.gService.all_filters[activeSubContext].filter((x,i)=> x.filter_values.length>0)

      this.gService.all_filters[activeSubContext].forEach((x,i)=>{

        if(x.filter_values.length >0){

          temp.push(x)
        }
      })

      this.gService.all_filters[activeSubContext] = temp;
      let temp2 =[]

      this.gService.datasource_index_for_filters[activeSubContext].forEach((x,i)=>{
          if(i!=index){

            temp2.push(x)

          }
      })
      
      this.gService.datasource_index_for_filters[activeSubContext]=temp2;

    }

    
    this.onFilter.emit(true)
    
  }

  apply(){

    
    this.onFilter.emit(true)

  }


}
