import {HttpClient} from '@int/geotoolkit/http/HttpClient';
import { LoaderPortalServicesService } from 'src/app/services/loader-portal-services.service';
import {RemoteReaderDataProvider} from '@int/geotoolkit/seismic/data/RemoteReaderDataProvider';
import { obfuscate } from '@int/geotoolkit/lib';

export class NodeServerDataProvider extends RemoteReaderDataProvider {

    options:any;
    http:any;
    dataservice: LoaderPortalServicesService;
    fileName;
    constructor (options:any) {
        super();
        this.options = options;
        this.http = HttpClient.getInstance().getHttp();
        this.dataservice = options['dataService'];
    }

    
     createInstance (options:any) {
        return new NodeServerDataProvider(options);
    }

     open (fileName:any) {
        let baseUrl = this.dataservice.getBrowseEndpoint();
        this.fileName = fileName;
        return this.http.get(baseUrl+'/trace-viewer/file-info?path='+this.fileName, {
            'responseType': 'json'   
        }).then((response:any) => {
            if (response['data']) {
                return response['data'];
            }
            return Promise.reject('Server error');
        }, () => Promise.reject('Cannot connect to the server! Run node server.js!'));
    }

     queryTraces (fileName:any, query:any) {
        let baseUrl = this.dataservice.getBrowseEndpoint();
        this.fileName = fileName;
        return this.http.get(baseUrl+'/trace-viewer/trace-info?path='+this.fileName, {
            'responseType': 'json'
        }).then((response:any) => {
            if (response['data']) {
                return response['data'];
            }
            return Promise.reject('Server error');
        }, () => Promise.reject('Cannot connect to the server! Run node server.js!'));
    }

     readTraces (fileName:any , options:any) {
        this.fileName = fileName;
        let baseUrl = this.dataservice.getBrowseEndpoint();
        return this.http.request({
            'url': baseUrl+'/trace-viewer/trace-samples',
            'method': 'POST',
            'responseType': 'arraybuffer',
            'headers': {
                'Content-Type': 'application/json'
            },
            'data': {
                'filePath': this.fileName,
                // 'byteOrder': options['byteOrder'],
                'from':options["from"],
                'to':options["to"],
                'headers':true,
                'samples':true
               
            },
            'transformResponse': function (response:any) {
                return response['data'];
            }
        });

    }
}

obfuscate(NodeServerDataProvider);

