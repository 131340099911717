import { AfterViewInit, Component, Input, OnInit, SimpleChanges } from '@angular/core';

declare var Plotly: any;

@Component({
  selector: 'app-plotly',
  templateUrl: './plotly.component.html',
  styleUrls: ['./plotly.component.css']
})
export class PlotlyComponent implements AfterViewInit {

  @Input() data;
  @Input() layout;

  id = String(Math.random());

  constructor() { }

  ngAfterViewInit(): void {

  this.plot_graph();    
  
  }

  ngOnChanges(changes: SimpleChanges): void {


    if (changes && changes.data && changes.data.currentValue) {

      this.data = changes && changes.data && changes.data.currentValue;
      this.layout = changes && changes.layout && changes.layout.currentValue;
     
        this.plot_graph()

    }

  }

  plot_graph(){

    let GRAPH = document.getElementById(this.id);

    GRAPH? Plotly.newPlot(GRAPH , this.data, this.layout, { responsive: true, displaylogo: false,displayModeBar: false}):null;

  }

}
