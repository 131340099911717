

 <p-tabView>
    <p-tabPanel>

        <ng-template pTemplate = "header">
            <i class="fa fa-info"></i>
            <span style="margin-left: 0.5rem;">Attributes</span>
        </ng-template>

        <p-scrollPanel [style]="{width: '100%', 'height': '450px'}">

            <div *ngFor="let col of gridColumns" class="rows">

                <div class="cell cell-heading">

                    {{col}}

                </div>

                <div class="cell">

                    {{gridData[col]}}
                </div>

                
            </div>
            

        </p-scrollPanel>
       
    </p-tabPanel>
    <p-tabPanel  *ngIf="showAnalysis">

        <ng-template pTemplate = "header">
            <i class="fa fa-chart-bar"></i>
            <span style="margin-left: 0.5rem;">Analytics</span>
        </ng-template>

        <p-scrollPanel [style]="{width: '100%', 'height': '450px'}">

            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.</p>


        </p-scrollPanel>
        
    </p-tabPanel>

    <p-tabPanel  *ngIf="showQualityReport">

        <ng-template pTemplate = "header">
            <i class="fa fa-chart-bar"></i>
            <span style="margin-left: 0.5rem;">Quality report</span>
        </ng-template>

        <p-scrollPanel [style]="{width: '100%', 'height': '450px'}">

            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.</p>


        </p-scrollPanel>
        
    </p-tabPanel>
  
</p-tabView>
