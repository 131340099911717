<div class="f-panel">

    <div *ngFor="let fil of dropdowns;let i=index">

        <p-dropdown class="p-fluid" [(ngModel)]="selectedPartition" 
        (ngModelChange)="onSelect($event)" 
        [filter]="true" 
        [options]="dropdown_values[i]" 
        optionLabel="name" 
        placeholder="Select {{fil}}"
        optionValue="code" 
        ></p-dropdown>

    </div>

</div>
