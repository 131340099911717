import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DataModelService } from 'src/app/browse-data/data-model.service';
import { ViewModelService } from 'src/app/browse-data/view-model.service';
import { GlobalCompService } from '../global-comps-services/global.service';

@Component({
  selector: 'app-filter',
  templateUrl: './app-filter.component.html',
  styleUrls: ['./app-filter.component.css']
})
export class AppFilterComponent implements OnInit {

  filters_list;
  datasource;
  value;
  selected ={};
  filter_index;
  temp_list = [];
  loading=false;


  @Output() onFilter: EventEmitter<any> = new EventEmitter();
  @Output() onClose = new EventEmitter();

  constructor(private vService:ViewModelService,
    public gService:GlobalCompService,
    private dMService: DataModelService) { }

  
  ngOnInit(): void {

   

    this.datasource = this.vService.get_active_datasource()

    this.filter_index = this.gService.get_datasource_index_mapping(this.datasource)

    this.filters_list = this.get_filter_attributes()

    this.fetch_filter_data()

  }

  get_filter_attributes(){

    let list = this.dMService.get_datasource_filters(this.datasource)

    list = list.map(x=>{
      
      return {'attribute':x.attribute,'values':[]}})

    return list
      
  }

  close(){

    this.onClose.emit()
   }
  
  set_filters(attribute,i){

    let activeSubContext = this.vService.active_subcontext;

    let index = this.gService.all_filters[activeSubContext][this.filter_index]["filter_values"]
    .indexOf(this.gService.all_filters[activeSubContext][this.filter_index]["filter_values"].
    find(att=>att.attribute==attribute)
    )

    if(index == -1){

      this.gService.all_filters[activeSubContext][this.filter_index]["filter_values"].push({"operator":"=","attribute":attribute,"selected":this.selected[i]})
    }
    else{

      this.gService.all_filters[activeSubContext][this.filter_index]["filter_values"][index]["selected"]=this.selected[i];
      this.gService.all_filters[activeSubContext][this.filter_index]["filter_values"][index]["operator"]= "=";
    }

  }

  fetch_filter_data(){

      this.selected={};

      this.get_filter_values_from_api()

  }

  get_filter_values_from_api(){

    this.loading=true;
    this.gService.get_filter_data(this.datasource).subscribe((res:any)=>{

      res.forEach(element => {

        let temp =element["values"].map(res=>this.get_label_code_map(res))
  
        this.temp_list.push({"attribute":element["attribute"],"values":temp})

      });

      this.loading=false;

      this.filters_list=this.temp_list;
    })

  }

  apply_filter(){


    this.onFilter.emit(true)

  }


  get_label_code_map(filter_values){

    return {name:filter_values,code:filter_values}
   
  }


}
