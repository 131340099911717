<div class="menu-bar p-d-flex p-jc-between p-ai-center" *ngIf="!loading">
  <i style="margin: 1rem; cursor: pointer; font-size: 1.2rem; color: white" pTooltip="Navigation Menu"
    class="pi pi-bars" (mouseover)="visibleSidebar = true" (mouseleave)="visibleSidebar = true" #btn></i>
  <p-sidebar styleClass="MenuSidebar MenuSidebarleft" [(visible)]="visibleSidebar" [baseZIndex]="10000"
    [showCloseIcon]="false" (onHide)="sidebarHide()">
    <h3 class="panelTitle">
      <img src="/assets/images/{{settingService.company_name}}.PNG" width="80px" />
      <span class="panelSubTitle"> Data Portal </span>
    </h3>
    <p-panelMenu [model]="items" #menu [multiple]="false"></p-panelMenu>
  </p-sidebar>
  <div style="width: 20%">
    <img src="/assets/images/{{settingService.company_name}}.PNG" width="100px" />
    <span class="menu-bar-title"> Data Portal </span>
  </div>
  <div class="menuSearchPanel">
    <span class="p-input-icon-left">
      <i class="pi pi-filter-slash" (click)="onResetEvent($event)"></i>

      <input type="text" class="p-inputtext-lg" placeholder="Filter items" pInputText type="text" style="color: white"
        (change)="onChangeEvent($event)" />
    </span>
  </div>
  
  <p-avatar icon="pi   pi-shopping-cart  " pBadge styleClass="mr-4    p-text-secondary" [value]="service.all_cart_items.length" size="small"
    shape="circle" [style]="{
    'background-color': '#62bb46',
    color: '#ffffff'
  }" (click)="show_shopping_cart()"></p-avatar>


  <div class="p-d-flex p-ai-center">
    <!-- <p-avatar icon="pi pi-info    " styleClass="mr-2" size="small" shape="circle"  (click)="showtutorial()" [style]="{
        'background-color': ' rgb(252 237 0)',
        color: 'black'
      }"></p-avatar> -->

    <p-avatar [label]="user.username.charAt(0).toUpperCase()" styleClass="mr-2" size="small"
      [style]="{ 'background-color': '#04aff0', color: '#ffffff' }" shape="circle"
      (mouseover)="visibleRigthSidebar = true" (mouseleave)="visibleRigthSidebar = true"></p-avatar>

    <p-sidebar #rightSidebar styleClass="MenuSidebar" position="right" [showCloseIcon]="false"
      [(visible)]="visibleRigthSidebar" [baseZIndex]="10000" [showCloseIcon]="false">
      <h3 class="panelTitle">
        <p-avatar [label]="user.username.charAt(0).toUpperCase()" styleClass="mr-2" size="large"
          [style]="{ 'background-color': '#04aff0', color: '#ffffff' }" shape="circle"
          (mouseover)="visibleRigthSidebar = true" (mouseleave)="visibleRigthSidebar = true"></p-avatar>{{ user.username
        }}
      </h3>
      <p-panelMenu [model]="userItems" #menuUser [multiple]="false"></p-panelMenu>
    </p-sidebar>
  </div>
  <div class="copyright layout-footer">
    <section class="wrapper copyRightText">
      Driven by SLB. Copyright © 2024. All rights reserved
    </section>
  </div>
</div>

<div *ngIf="loading" class="spinner">
  <p-progressSpinner></p-progressSpinner>
</div>