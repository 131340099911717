
<!-- <p-blockUI target="map" [blocked]="!show_map">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
</p-blockUI> -->

<!-- <div #targ  *ngIf="!show_map">

</div> -->
<div id="layer-details-panel" *ngIf="show_details_panel && panel_data" class="layer-details-panel">

    <app-map-panel [data]="panel_data" (onClose)="close_panel()" (onPublish)="set_publish($event)"></app-map-panel>
    

</div>


<div #map id="{{map_id}}" class="map" aria-haspopup="true">

    <div class="spinner-container" *ngIf="loading">

        <i  class="pi pi-spin pi-spinner" style="font-size: 2rem; position: absolute;z-index: 20000; color: white;"></i>
    
    </div>

    
    <!-- <div *ngIf="showGlobalFilters && globalFilters.values.length" class="global_filter_panel">

        <app-map-filters  [filters]="globalFilters.values" (selection)="on_partition_selection($event)"></app-map-filters>

    </div> -->

</div>





<p-contextMenu [target]="map" [model]="items"
 *ngIf="custom_div" appendTo="body" ></p-contextMenu>


<!-- <div id="card" class="p-d-flex">

        <div class="p-d-flex p-flex-column p-jc-center p-ai-center map-card p-shadow-1">
            <p style="font-size: 1rem; font-weight: 600;">Boreholes</p>
            <p style="font-size: 1.4rem;">{{boreholes}}</p>
        </div>

        <div class="p-d-flex p-flex-column p-jc-center p-ai-center map-card p-shadow-1">

            <p style="font-size: 1rem; font-weight: 600;">Datasets</p>
            <p style="font-size: 1.4rem;">{{datasets}}</p>

        </div>


</div> -->



<p-overlayPanel #op showCloseIcon="true">
    <ng-template pTemplate>
        <div  class="layer_selector">

            <div *ngFor="let layer of layers" class="field-checkbox">
                <p-checkbox name="group2" value="layer" [value]="layer.key"  [(ngModel)]="selectedLayers" (ngModelChange)="toggle_layers(layer.key)" [inputId]="layer.key"></p-checkbox>
                <label style="margin-left: 0.25rem;" [for]="layer.key">{{layer.name}}</label>
            </div>
        
        </div>
    </ng-template>
</p-overlayPanel>
 
<div  id="layer_selector" style="margin-top:15rem;" >

    <!-- <div class="layer-selector-icon p-shadow-1" style="margin-bottom:1rem;" >
        
        <i class="fa fa-arrow-alt-circle-up" *ngIf="clikedLayers.length" pBadge value={{clikedLayers.length}} style="font-size:large" pTooltip="Publish Selected layers"></i>

        <i class="fa fa-arrow-alt-circle-up disabled" *ngIf="!clikedLayers.length" style="font-size:large" ></i>
        
    </div> -->

    <div class="layer-selector-icon p-shadow-1" (click)="op.toggle($event)" pTooltip="Toggle Layers">

        <i class="fa fa-layer-group" style="font-size:large" ></i>

    </div>
    

</div>















    




   
   

    

 
    

    
    
