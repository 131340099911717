import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import { DataTablesService } from 'src/app/data-tables/data-tables.service';

import { MapComponent } from '../map/map.component';
@Component({
  selector: 'app-surface-viewer',
  templateUrl: './surface-viewer.component.html',
  styleUrls: ['./surface-viewer.component.css']
})
export class SurfaceViewerComponent implements AfterViewInit {

  data;
  layout;
  deviation_data;
  show_map = false;
  @Input() selectedRows;
  loading = false;
  error = false;
  error_message;
  map_data;
  rows;
  res

  @ViewChild('map') mapView: MapComponent;

  constructor(private service: DataTablesService) { }

  ngAfterViewInit(): void {

    this.loading = true;

    this.service.get_surface(this.selectedRows).subscribe(res => {

      if (res["error"].length > 2) {

        this.error = true;
        this.error_message = res["error"]

      }

      this.res = res

      var trace = {
        "x": res["plot"]['x'],
        "y": res["plot"]['y'],
        "z": res["plot"]['z'],
        "type": 'surface'

      }

      this.data = [trace]

      this.loading = false;

      this.layout = {

        'autosize': false,
        'scene': {
          "camera": { "eye": { "x": 1.87, "y": 0.88, "z": -0.64 } },
          'zaxis': {
            'backgroundcolor': "#d8edf3",
            'showbackground': true,
            'gridcolor': "white",
            'title': 'Z-axis'
          },
          'xaxis': {

            'backgroundcolor': "#d8edf3",
            'showbackground': true,
            'gridcolor': "white",
            'title': 'X-axis'
          },
          'yaxis': {

            'backgroundcolor': "#d8edf3",
            'showbackground': true,
            'gridcolor': "white",
            'title': 'Y-axis'
          }

        },
        "height": 700,
        'width': 1200
      }

    })
  }


  renderContourPlot(): void {
    // Define contour plot data
    const contourData = [{
      z: [
        [10, 15, 20],
        [15, 20, 25],
        [20, 25, 30]
      ],
      type: 'contour'
    }];

    // Define contour plot layout
    const contourLayout = {
      title: 'Contour Plot',
      xaxis: { title: 'X Axis' },
      yaxis: { title: 'Y Axis' }
    };


  }


  downloadFile() {

    if (this.selectedRows.length == 1) {
      let fileName = this.selectedRows[0]["Borehole Name"] + ".csv"
      this.downloadDevationData(fileName);
    } else {
      this.downloadDevationData("Deviation.zip");
    }

  }

  downloadDevationData(fileName) {
    this.service.download_deviations_data(this.rows).subscribe((res: any) => {
      if (res.body) {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(res.body)
        a.href = objectUrl
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(objectUrl);
      }
    });
  }

}
