<div #map id="{{map_id}}" class="map" aria-haspopup="true">


    <div class="spinner-container" *ngIf="map_loading">

        <i  class="pi pi-spin pi-spinner" style="font-size: 2rem; position: absolute;z-index: 20000; color: white;"></i>
    
    </div>


    <!-- <div class="area-container" *ngIf="search_mode && !area_selected && !drawing">

        <div  (click)="draw_area()" class="p-shadow-3 area-selector">
              Click to draw area of interest
        </div>
    
    </div> -->

    <div class="clear-container" *ngIf="search_mode && area_selected && !drawing">
  
        <button (click)="clear_area()" pButton pRipple type="button" label="Clear Area of Interest" icon="pi pi-times" class="p-button-rounded p-button-secondary"></button>
     
    </div>

    <div class="dialog-container" *ngIf="display_max_selection">
                   
        <p-card header="Max selection limit reached" [style]="{'z-index':'100000'}">
    
            Database String limitation reached. {{selection_list.length}} points selected. Please select less than 1000 points on the map.
            
            <ng-template pTemplate="footer">
                <button pButton type="button" label="OK" (click)="display_max_selection=!display_max_selection" ></button>
            </ng-template>
        </p-card>    
    
    </div>

</div>




