import {AuthModule, AutoLoginAllRoutesGuard, OidcSecurityService} from 'angular-auth-oidc-client';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppMenuComponent } from './app-menu/app-menu.component';
import { AuthConfigModule } from './services/auth-config.module';
import { AuthGuard } from './login/login.guard';
import { AuthInterceptor } from './services/auth-interceptor';
import { AuthService } from './services/auth-service.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DataService } from 'angular-auth-oidc-client/lib/api/data.service';
import { GlobalCompsModule } from './global-comps/global-comps.module';
import { NgModule } from '@angular/core';
import { PrimeNGModuleModule } from './prime-ng-module/prime-ng-module.module';
import { RouterModule } from '@angular/router';
import { TutorialIndexComponent } from './tutorial/tutorial-index/tutorial-index.component';
import { environment } from '../environments/environment';

const isIframe = window !== window.parent && !window.opener;
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri: '/portal/auth'

    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },

  });
}


@NgModule({
  declarations: [AppComponent, AppMenuComponent, TutorialIndexComponent,DashboardComponent],
  imports: [
    AuthConfigModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    PrimeNGModuleModule,
    GlobalCompsModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      // {
      //   path: 'signin',
      //   loadChildren: () =>
      //     import('./login/login.module').then((m) => m.LoginModule),
      // },
      {
        path: 'home',
        canActivate: [AutoLoginAllRoutesGuard],
        loadChildren: () =>
          import('./home/home.module').then(
            (m) => m.HomeModule
          ),
      },
      {
        path:'security/sauth/callback',
        redirectTo: 'home'
      },
      {
        path: 'unauthorized',
        loadChildren: () =>
          import('./error/error.module').then(
            (m) => m.ErrorModule
          ),
      },
      {
        path: 'loader-portal',
        canActivate: [AutoLoginAllRoutesGuard],
        loadChildren: () =>
          import('./loader-portal/loader-portal.module').then(
            (m) => m.LoaderPortalModule
          ),
      },
      {
        path: 'browse-data',
        canActivate: [AutoLoginAllRoutesGuard],
        loadChildren: () =>
          import('./browse-data/browse-data.module').then(
            (m) => m.BrowseDataModule
          ),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./search-portal/search-portal.module').then(
            (m) => m.SearchPortalModule
          ),
      },
      {
        path: 'tutorial',
        canActivate: [AutoLoginAllRoutesGuard],
        component: TutorialIndexComponent,
      },
      {
        path: 'data-tables',
        canActivate: [AutoLoginAllRoutesGuard],
        loadChildren: () =>
          import('./data-tables/data-tables.module').then(
            (m) => m.DataTablesModule
          ),
      },
      {
        path: 'dashboard',
        canActivate: [AutoLoginAllRoutesGuard],
        component: DashboardComponent,
      },
      {
        path: 'config-dashboard',
        canActivate: [AutoLoginAllRoutesGuard],
        loadChildren: () =>
          import('./configs/configs.module').then(
            (m) => m.ConfigsModule
          ),
      },
      {
        path: 'integration-dashboard',
        canActivate: [AutoLoginAllRoutesGuard],
        loadChildren: () =>
          import('./integration-dashboard/integration-dashboard.module').then(
            (m) => m.IntegrationDashboardModule
          ),
      },
      {
        path: 'apps/forecast',
        canActivate: [AutoLoginAllRoutesGuard],
        loadChildren: () =>
          import('./tailored-solutions/forecasting/forecasting.module').then(
            (m) => m.ForecastingModule
          ),
      }
    ],),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    OidcSecurityService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
