<div class="panel-container">

    <p-tabView (onChange)="on_tab_change($event)">
        <p-tabPanel header="Log Curves">
            <div class="panel-files p-fluid">

                <p-listbox [options]="traces_per_file" [(ngModel)]="selectedCurve" (ngModelChange)="onCurveSelection($event)" *ngIf="traces_per_file.length>0" [group]="true" [listStyle]="{'height':'65.5vh'}" [metaKeySelection]="false" [checkbox]="true" [filter]="true" [multiple]="true">
                    <ng-template pTemplate="header">
                        Log Curves
                    </ng-template>
        
                    <ng-template let-group pTemplate="group">
                        <div class="flex align-items-center">
                            <span>{{group.label}}</span>
                        </div>
                    </ng-template>
                </p-listbox>
        
            </div>
        </p-tabPanel>
        <p-tabPanel header="Markers" *ngIf="markers_list.length>0">
            <p-listbox [options]="markers_list" [(ngModel)]="selectedMarker" (ngModelChange)="onMarkerSelection($event)"  [group]="false" [listStyle]="{'height':'65.5vh'}" [metaKeySelection]="false" [checkbox]="true" [filter]="true" [multiple]="true">
                <ng-template pTemplate="header">
                    Markers
                </ng-template>
            </p-listbox>

        </p-tabPanel>

        <!-- <p-tabPanel header="Core Images">
            <p-listbox [options]="fills_list" [(ngModel)]="selectedFill" (ngModelChange)="onFillSelection($event)" *ngIf="fills_list.length>0" [group]="false" [listStyle]="{'height':'65.5vh'}" [metaKeySelection]="false" [checkbox]="true" [filter]="true" [multiple]="true">
                <ng-template pTemplate="header">
                    Curve Fills
                </ng-template>
            </p-listbox>

        </p-tabPanel> -->

    </p-tabView>

</div>
