import { Component, OnInit } from '@angular/core';

import { LoginService } from 'src/app/login/login.service';
import { MenuOptionsService } from './menu-options.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-options',
  templateUrl: './menu-options.component.html',
  styleUrls: ['./menu-options.component.css']
})
export class MenuOptionsComponent implements OnInit {


  active_link=""

  constructor(public oidcSecurityService:OidcSecurityService,private router:Router,public menuService:MenuOptionsService) { }

  ngOnInit(): void {

  }

  signout(){
    this.oidcSecurityService.logoff();
  }

  navigate_to(url){

    this.menuService.active_link=url;

    this.router.navigate([url])
  }

}
