import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-grid-report',
  templateUrl: './grid-report.component.html',
  styleUrls: ['./grid-report.component.css']
})
export class GridReportComponent implements OnInit {

  @Input() gridColumns;
  @Input() gridData;
  @Input() showAnalysis;
  @Input() showQualityReport;

  constructor() { }

  ngOnInit(): void {
  }

}
