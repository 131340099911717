<div style="height: 100%;width: 100%;">

    <div class="side-nav-heading">

       <p>Navigator</p>

       <div>
        <i *ngIf="!loading" class="pi pi-times cross" (click)="close()"></i>
        <i *ngIf="loading" class="pi pi-spin pi-spinner spinner"></i>
       </div>
       

    </div>

    <p-tree [style]="{border:'0px'}"
 selectionMode="single" 
 [value]="tree"
 [(selection)]="selectedNode" 
 (onNodeSelect)="toggle_node($event)"
 (onNodeExpand)="toggle_node($event)"
 [style]="{'padding':'0.5rem','border':'none','padding-top':'1rem','height':'80%'}" 

 >
<ng-template let-node pTemplate="default">
   <p *ngIf="!node.root || node.root===false">{{ node.label }}</p>

   <div *ngIf="node.root && node.root===true" style="display: flex; align-items: center">
      <p style="line-height: 0.2rem; cursor: pointer">
         {{ node.label }}
      </p>
      <div style="padding: 0 0.5rem; cursor: pointer">
         <i style="margin-left:0.5rem" class="fas fa-cart-plus  cart-icon" pTooltip="Add to Cart" (click)="addToCart(node)"></i>


      </div>
   </div>
</ng-template>
</p-tree>

    

</div>


