import { Component, OnInit } from '@angular/core';

import { DataTablesService } from './data-tables/data-tables.service';
import { LoaderPortalServicesService } from './services/loader-portal-services.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { SettingService } from './services/setting.service';
import { Subject } from 'rxjs';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'AngApp';

  showRouter = false;

  constructor(
    private settingService: SettingService,
    private router: Router,
    private service: LoaderPortalServicesService,
    public dTService: DataTablesService,
    public oidcSecurityService: OidcSecurityService
  ) {
    this.settingService.getSetting();
  }
  private unsubscribe = new Subject<void>();

  ngOnInit() {
    setTimeout(() => {
      this.service.user=this.oidcSecurityService.getUserData()['email'];
    },2500)
  }
  close() {
    this.router.navigate(['signin']);
  }
}
