import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ViewModelService {
  all_view_models = [];

  active_view_model = null;
  active_datasource = null;
  active_subcontext = null;
  active_partition = null;
  active_tree_node = null;
  redirected_partition = null;

  constructor() {}

  reset() {
    this.active_view_model = null;
    this.active_datasource = null;
    this.active_subcontext = null;
    this.active_partition = null;
    this.active_tree_node = null;
  }

  get_all_subcontexts() {
    let view = this.all_view_models.find(
      (models) => models.context == this.active_view_model
    );

    let all_sub_contexts = view['tree'].map((x) => x['subContext']);

    return all_sub_contexts;
  }

  get_tree_definition() {
    let view = this.all_view_models.find(
      (models) => models.context == this.active_view_model
    );

    let tree = view['tree'][this.active_subcontext];

    return [tree];
  }

  get_tree_definition2() {
    let view = this.all_view_models.find(
      (models) => models.context == this.active_view_model
    );

    let tree = view['tree'];

    return tree;
  }

  set_active_datasource_from_context() {
    let view = this.all_view_models.find(
      (models) => models.context == this.active_view_model
    );

    view = view['tree'][this.active_subcontext];

    this.active_datasource = view['data'];
  }

  get_viewers() {
    let view = this.all_view_models.find(
      (models) => models.context == this.active_view_model
    );

    return view.viewers;
  }

  set_active_datasource() {
    let view = this.all_view_models.find(
      (models) => models.context == this.active_view_model
    );

    this.active_datasource = view.default_datasource;
  }

  get_label_for_context() {
    let view = this.all_view_models.find(
      (models) => models.context == this.active_view_model
    );

    return view['label'];
  }

  get_active_datasource() {
    if (this.active_datasource) {
      return this.active_datasource;
    } else {
      let view = this.all_view_models.find(
        (models) => models.context == this.active_view_model
      );

      this.active_datasource = view.default_datasource;

      return view.default_datasource;
    }
  }

  get_tree_link() {
    let view = this.all_view_models.find(
      (models) => models.context == this.active_view_model
    );

    return view.tree[this.active_subcontext].link;
  }

  get_tree_link_for_provided_context(index) {
    let view = this.all_view_models.find(
      (models) => models.context == this.active_view_model
    );

    return view.tree[index].link;
  }

  is_active_datasource_parent() {
    let view = this.all_view_models.find(
      (models) => models.context == this.active_view_model
    );

    if (
      view.tree[this.active_subcontext].data == this.active_datasource &&
      view.tree[this.active_subcontext].children?.length
    ) {
      return true;
    } else {
      return false;
    }
  }

  get_datasource_primary_key() {
    let view = this.all_view_models.find(
      (models) => models.context == this.active_view_model
    );

    let node = null;

    node = view['tree'][this.active_subcontext]['children'].find(
      (x) => x.data == this.active_datasource
    );

    if (node) {
      return node['key'];
    }

    return null;
  }

  get_grid_options() {
    let view = this.all_view_models.find(
      (models) => models.context == this.active_view_model
    );

    if (view['tree'][this.active_subcontext].data == this.active_datasource) {
      return view['tree'][this.active_subcontext]['grid_options'];
    }

    let node = view['tree'][this.active_subcontext]['children'].find(
      (x) => x.data == this.active_datasource
    );

    return node['grid_options'];
  }

  get_label_for_datasource() {
    let view = this.all_view_models.find(
      (models) => models.context == this.active_view_model
    );

    if (view['tree'][this.active_subcontext].data == this.active_datasource) {
      return view['tree'][this.active_subcontext]['label'];
    }

    let node = view['tree'][this.active_subcontext]['children'].find(
      (x) => x.data == this.active_datasource
    );

    return node['label'];
  }

  get_parent_datasource_from_sub_context_index(index) {
    let view = this.all_view_models.find(
      (models) => models.context == this.active_view_model
    );

    return view['tree'][index].data;
  }

  has_global_filters() {
    let view = this.all_view_models.find(
      (models) => models.context == this.active_view_model
    );

    if (view.global_filters) {
      return true;
    } else {
      return false;
    }
  }

  get_global_filters() {
    let view = this.all_view_models.find(
      (models) => models.context == this.active_view_model
    );

    return view.global_filters;
  }

  get_view() {
    return this.all_view_models.find(
      (models) => models.context == this.active_view_model
    );
  }

  get_all_partitions() {
    let view = this.all_view_models.find(
      (models) => models.context == this.active_view_model
    );

    return view.partitions;
  }

  get_views_for_selected_partition() {
    let view = this.all_view_models.find(
      (models) => models.context == this.active_view_model
    );

    let applicable_views = [];

    view.tree.forEach((view, i) => {
      if (view.partition == this.active_partition) {
        applicable_views.push({ code: i, name: view.label });
      }
    });

    return applicable_views;
  }

  // get_cart_item_object() {
  //   return {
  //     asset: '',
  //     dataType: {
  //       name: '',
  //       values: [],
  //     },
  //     entityType: {
  //       name: '',
  //       values: [],
  //     },
  //     dataKeys: {
  //       name: '',
  //       values: [],
  //     },
  //     dataFrom: '',
  //     dataTill: '',
  //   };
  // }

  // update_cart_object(
  //   cart_obj,
  //   node,
  //   rows = undefined,
  //   summary_attributes = undefined
  // ) {
  //   if (node.root && node.root === true && !rows) {
  //     node.children.forEach((entityType) => {
  //       if (cart_obj.entityType.values.indexOf(entityType.label) < 0) {
  //         cart_obj.entityType.name =
  //           cart_obj.entityType.name + entityType.label + ',';
  //         cart_obj.entityType.values.push(entityType.label);
  //       }
  //       entityType.children.forEach((dataType) => {
  //         if (cart_obj.dataType.values.indexOf(dataType.label) < 0) {
  //           cart_obj.dataType.name =
  //             cart_obj.dataType.name + dataType.label + ',';
  //           cart_obj.dataType.values.push(dataType.label);
  //         }
  //       });
  //     });
  //   } else if (
  //     (!node.root || node.root === false) &&
  //     node.children &&
  //     node.children.length > 0 &&
  //     rows
  //   ) {
  //     cart_obj.entityType.name = cart_obj.entityType.name + node.label;
  //     cart_obj.entityType.values.push(node.label);

  //     node.children.forEach((dataType) => {
  //       if (cart_obj.dataType.values.indexOf(dataType.label) < 0) {
  //         cart_obj.dataType.name =
  //           cart_obj.dataType.name + dataType.label + ',';
  //         cart_obj.dataType.values.push(dataType.label);
  //       }
  //     });
  //     cart_obj.dataKeys.name = rows.length + ' items selected';
  //     rows.forEach((row) =>
  //       cart_obj.dataKeys.values.push(row[summary_attributes[0]])
  //     );
  //   } else if (
  //     (!node.root || node.root === false) &&
  //     (!node.children || node.children.length < 1) &&
  //     rows
  //   ) {
  //     cart_obj.entityType.name = cart_obj.entityType.name + node.parent.label;
  //     cart_obj.entityType.values.push(node.parent.label);

  //     cart_obj.dataType.name = cart_obj.dataType.name + node.label + ',';
  //     cart_obj.dataType.values.push(node.label);

  //     cart_obj.dataKeys.name = rows.length + ' items selected';

  //     rows.forEach((row) =>
  //       cart_obj.dataKeys.values.push(row[summary_attributes[0]])
  //     );
  //   }
  //   cart_obj.dataType.name = cart_obj.dataType.name.slice(0, -1);
  //   cart_obj.entityType.name = cart_obj.entityType.name.slice(0, -1);
  //   return cart_obj;
  // }
}


