import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clientId = environment.clientId;
    const clientSecret = environment.client_secret;
    const authHeader = 'Basic ' + btoa(clientId + ':' + clientSecret);
    const authToken = this.oidcSecurityService.getAccessToken();

    let authReq = req;
    if (authToken) {
      authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + authToken),
      });
    } 
    else {
        authReq = req.clone({
            headers: req.headers.set('Authorization', authHeader)
        });
    }

    return next.handle(authReq).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // Handle HTTP response if needed
          }
        },
        (err: any) => { // Error handling within tap
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(['home']);
            }
          }
        }
      )
    );
  }
}