import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-map-panel',
  templateUrl: './map-panel.component.html',
  styleUrls: ['./map-panel.component.scss']
})
export class MapPanelComponent implements OnInit, OnChanges {


  @Input() data;
  @Output() onClose = new EventEmitter();
  @Output() onPublish = new EventEmitter();

  plot_data={values:[],labels:[]};
  prod_available;
  st_values = [' ',' ',' ',' ',' ',' ',' ',' ',' ',' ']

  summary_attributes=["Well","Borehole","Country","Basin","Last Updated"]


  constructor() { }

  ngOnInit(): void {

    this.plot_data ={

      values: [this.data['well_data']['Deviation'], this.data['well_data']['Checkshot'], this.data['well_data']['Log'],this.data['well_data']['Stratigraphy']
      ,this.data['well_data']['Biostratigraphy'],this.data['well_data']['Core'],this.data['well_data']['Core GR'],this.data['well_data']['Core Probe Perm']
      ,this.data['well_data']['Formation Pressure'],this.data['well_data']['DST']],
      labels: ['Deviation', 'Checkshot','Log', 'Stratigraphy','Biostratigraphy','Core','Core GR','Core Probe Perm','Formation Pressure','DST'],
    };

    this.st_values[2] = this.data['studio_data']

    // this.prod_available = this.plot_data.values[3]>0? true:false;

    // if(this.prod_available){

    //   this.data.prod_data[0]['layout']['legend'] = {orientation: "h"}
    //   this.data.prod_data[0]['layout']['margin'] = {l:40,t:15}
    //   this.data.prod_data[0]['layout']['height'] = 340;
    // }



  }

  ngOnChanges(changes: SimpleChanges) {
        
    if(changes.data.currentValue){

      this.ngOnInit()
    }
    
    
}

close_panel(){

  this.onClose.emit()
}

publish(){

  this.onPublish.emit({attribute:"Borehole", value:this.data['well_data']['Borehole']})
}

}
