<div class="side-nav-container" >

    <div *ngIf="!disable" class="align-items">

        <i id="tree" class="fas fa-sitemap side-nav-icon" pTooltip="Navigator" (click)="toggle('tree')"></i>
    
        <i id="grid" class="fas fa-table side-nav-icon2" pTooltip="Data Grid" (click)="toggle('grid')"></i>
    
        <i id="map" class="fas fa-map side-nav-icon2" pTooltip="Map" (click)="toggle('map')"></i>
    
        <i id="log" class="fab fa-gitter side-nav-icon2" pTooltip="Log Viewer" *ngIf="log_view" (click)="toggle('log')"></i>

    </div>
   
    
</div>
