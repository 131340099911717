import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ViewModelService } from 'src/app/browse-data/view-model.service';
import { GlobalCompService } from '../global-comps-services/global.service';

@Component({
  selector: 'app-tree-nav',
  templateUrl: './tree-nav.component.html',
  styleUrls: ['./tree-nav.component.css'],
  providers: [MessageService],
})
export class TreeNavComponent implements OnInit {
  @Output() selectedDtype = new EventEmitter();

  @Output() onClose = new EventEmitter();

  constructor(
    private vService: ViewModelService,
    private gService: GlobalCompService,
    private message_service: MessageService
  ) {}

  tree_structure;
  selectedNode;
  loading = false;
  tree = [];
  last_selected;
  ngOnInit(): void {
    this.activate();
  }

  activate() {
    this.tree_structure = this.vService.get_tree_definition2();

    let temp = null;

    let all_partitions = this.vService.get_all_partitions();

    if (all_partitions.length > 0) {
      this.tree_structure.forEach((node) => {
        if (node['partition'] == temp) {
          this.tree[this.tree.length - 1].children.push(node);
        } else {
          this.tree.push({
            label: node['partition'],
            root: true,
            partition: node['partition'],
            expandedIcon: 'pi pi-folder-open',
            collapsedIcon: 'pi pi-folder',
            children: [node],
          });

          temp = node['partition'];
        }
      });

      this.initialize();
    } else {
      this.tree = this.tree_structure;
    }

    if (this.vService.active_tree_node) {
      this.expand_node();

      this.selectedNode = this.vService.active_tree_node;
    }

    // this.set_active_node()

    // this.get_tree_data()
  }

  initialize() {
    if (this.vService.redirected_partition) {
      let node = this.tree.find((elem) => {
        if (elem['partition'] == this.vService.redirected_partition) {
          elem.expanded = true;
          this.vService.active_partition = this.vService.redirected_partition;
          this.vService.active_subcontext = elem.children[0].id;
          this.selectedNode = elem.children[0];
          this.vService.active_tree_node = this.selectedNode;
          this.last_selected = elem.children[0].data;
          this.selectedDtype.emit(elem.children[0].data);
          this.vService.redirected_partition = null;
          return true;
        }
      });
    }
  }

  expand_node() {
    if (this.vService.active_tree_node.parent.parent) {
      this.tree.forEach((elem) => {
        if (
          this.vService.active_tree_node.parent.parent.label == elem['label']
        ) {
          elem.expanded = true;

          elem.children.forEach((el) => {
            el.label == elem.label;
            el.expanded = true;
          });
        }
      });
    } else if (this.vService.active_tree_node.parent) {

      this.tree.forEach((elem) => {
        if (this.vService.active_tree_node.parent.label == elem['label']) {
          elem.expanded = true;
        }
      });
    }
  }

  set_active_node() {
    let datasource = this.vService.get_active_datasource();

    if (this.tree[0].data == datasource) {
      this.selectedNode = this.tree[0];
    } else {
      this.tree[0].children.forEach((elem) => {
        if (elem.data == datasource) {
          this.selectedNode = elem;
        }
      });
    }
  }

  get_tree_data() {
    this.loading = true;

    this.gService.get_tree_data(this.tree).subscribe(
      (res) => {
        this.loading = false;

        this.tree = this.gService.update_tree_labels(res['data']);

        this.tree[0]['expanded'] = true;

        this.set_active_node();
      },
      () => {
        this.loading = false;
      }
    );
  }

  toggle_node(event) {
    if (event.node.data) {
      if (event.node.data == this.last_selected) {
        return;
      }
    }

    if (event.node.children == null) {
      this.selectedNode = event.node;
      this.vService.active_tree_node = this.selectedNode;
      this.last_selected = event.node.data;
      this.selectedDtype.emit(event.node.data);
      return;
    }

    if (event.node.root) {
      this.vService.active_partition = event.node.label;
      this.selectedNode = event.node;
      this.last_selected = null;
      this.vService.active_tree_node = this.selectedNode;
    } else {
      this.vService.active_subcontext = event.node.id;
      this.selectedNode = event.node;
      this.vService.active_tree_node = this.selectedNode;
      this.last_selected = event.node.data;
      this.selectedDtype.emit(event.node.data);
    }
  }

  close() {
    this.onClose.emit();
  }

  addToCart(asset_node) {
    // let obj = this.vService.get_cart_item_object();
    // obj.asset = asset_node.label;
    // obj = this.vService.update_cart_object(obj, asset_node);
    // this.vService.all_cart_items.push(obj);
    // this.message_service.add({ severity: 'info', summary: `Added to Cart` });
  }
}
