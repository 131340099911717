import { AppFilterComponent } from './app-filter/app-filter.component';
import { CommonModule } from '@angular/common';
import { DeviationViewerComponent } from './deviation-viewer/deviation-viewer.component';
import { DocViewerComponent } from './doc-viewer/doc-viewer.component';
import { FChipsComponent } from './f-chips/f-chips.component';
import { GridComponent } from './grid/grid.component';
import { GridReportComponent } from './grid/grid-report/grid-report.component';
import { LeafletMapComponent } from '../leaflet-map/leaflet-map.component';
import { LogFilePanelComponent } from './log-viewer/log-file-panel/log-file-panel.component';
import { LogViewerComponent } from './log-viewer/log-viewer.component';
import { MapComponent } from './map/map.component';
import { MapFiltersComponent } from '../leaflet-map/map-filters/map-filters.component';
import { MapPanelComponent } from '../leaflet-map/map-panel/map-panel.component';
import { MenuOptionsComponent } from './menu-options/menu-options.component';
import { NgModule } from '@angular/core';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { PlotlyComponent } from './plotly/plotly.component';
import { PrimeNGModuleModule } from '../prime-ng-module/prime-ng-module.module';
import { SideNavComponent } from './side-nav/side-nav.component';
import { TraceViewerComponent } from './trace-viewer/trace-viewer.component';
import { TreeNavComponent } from './tree-nav/tree-nav.component';
import { WellboreSchematicComponent } from './wellbore-schematic/wellbore-schematic.component';
import { SurfaceViewerComponent } from './surface-viewer/surface-viewer.component';
import { Horizon3dComponent } from './horizon-3d-viewer/horizon-3d-viewer.component';
import { InsightsDashboardComponent } from './insights-dashboard/insights-dashboard.component';

@NgModule({
  declarations: [
    AppFilterComponent,
    SideNavComponent,
    LeafletMapComponent,
    TreeNavComponent,
    GridComponent,
    FChipsComponent,
    LogViewerComponent,
    DocViewerComponent,
    PlotlyComponent,
    TraceViewerComponent,
    GridReportComponent,
    MapPanelComponent,
    MapFiltersComponent,
    MapComponent,
    DeviationViewerComponent,
    LogFilePanelComponent,
    MenuOptionsComponent,
    WellboreSchematicComponent,
    Horizon3dComponent,
    SurfaceViewerComponent, 
    InsightsDashboardComponent


  ],
  imports: [
    CommonModule,
    PrimeNGModuleModule,
    PdfJsViewerModule
  ],
  exports:[
    AppFilterComponent,
    SideNavComponent,
    LeafletMapComponent,
    TreeNavComponent,
    GridComponent,
    FChipsComponent,
    LogViewerComponent,
    DocViewerComponent, 
    PlotlyComponent,
    TraceViewerComponent,
    MapComponent,
    DeviationViewerComponent,
    MenuOptionsComponent,
    WellboreSchematicComponent,
    SurfaceViewerComponent,
    Horizon3dComponent,
    InsightsDashboardComponent
  ]
})
export class GlobalCompsModule { }
