// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  clientId: "fe95f4ec797c370546866a3461af1192",
  client_secret:"bc7d5f06b3c840c99f6a428b8e538bec524940ba107bf31",
  authority: "https://csi.slb.com/v2",
  redirect_url: "http://origin-alb-temp-1258255994.ap-southeast-2.elb.amazonaws.com/security/sauth/callback",
  browse_data_api_endpoint: "http://origin-alb-temp-1258255994.ap-southeast-2.elb.amazonaws.com/dmaas2",
  project_data_api_endpoint: "http://origin-alb-temp-1258255994.ap-southeast-2.elb.amazonaws.com/dmaas2",
  insight_api_endpoint: "http://origin-alb-temp-1258255994.ap-southeast-2.elb.amazonaws.com/dmaas2",
  company_name: "origin",
  company_profile: "oil_gas",
  auth_required: false,

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
