import { EventEmitter, Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { LoaderPortalServicesService } from './loader-portal-services.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  constructor(private http: HttpClient, private router: Router, private service: LoaderPortalServicesService) { }
  userchange: EventEmitter<any> = new EventEmitter();

  env;
  company_name;
  userPoolId;
  clientId;
  currentUser;
  menu_config;
  system_permissions;
  company_profile;
  auth_required;
  profiles;
  insights_server_endpoint;

  emitNavChangeEvent(currentUser) {
    this.userchange.emit(currentUser);
  }
  getNavChangeEmitter() {
    return this.userchange;
  }

  getSetting() {
    this.service.company_name = environment.company_name;
    this.service.clientId = environment.clientId;
    this.company_profile = environment.company_profile;
    this.auth_required = environment.auth_required;
    this.service.project_data_api_endpoint = environment.project_data_api_endpoint
    this.service.browse_data_api_endpoint = environment.browse_data_api_endpoint
    this.service.insight_api_endpoint = environment.insight_api_endpoint
    this.get_settings().subscribe((res: any) => {
      this.system_permissions = res.system_permissions;
      this.profiles = res.profiles;
      this.insights_server_endpoint = res.insights_server_endpoint;
    });
  }
  private get_settings() {
    return this.http.get('/assets/app-settings.json');
  }

  get_current_user_groups(username) {
    return this.http.get(
      `${this.service.browse_data_api_endpoint}/meta_data_management/group?username=${username}`
    );
  }

  backHome() {
    if (this.currentUser && this.currentUser.permissions)
      return this.router.createUrlTree(['home']);
    else this.backLogin();
  }
  backLogin() {
    return this.router.createUrlTree(['login']);
  }

  visible(menuLabel) {
    let menu_item_config = this.menu_config.find((p) => p.label === menuLabel);
    if (menu_item_config) {
      if (!menu_item_config.visible) return false;
      if (
        menu_item_config.visible &&
        (!menu_item_config.permissions ||
          menu_item_config.permissions.length === 0)
      )
        return true;
      if (
        this.currentUser.all_permission &&
        this.currentUser.all_permission.length > 0 &&
        menu_item_config.permissions &&
        menu_item_config.visible
      ) {
        for (let per of menu_item_config.permissions) {
          let permres = this.currentUser.all_permission.filter(
            (p) => p === per
          );

          if (permres && permres.length > 0) {
            return true;
          }
        }
        return false;
      } else return false;
    } else return true;
  }
}
