import { AfterViewInit, Component, ComponentFactoryResolver, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DataModelService } from 'src/app/browse-data/data-model.service';
import { ViewModelService } from 'src/app/browse-data/view-model.service';
import { GlobalCompService } from '../global-comps-services/global.service';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class GridComponent implements OnInit, AfterViewInit  {

  rows = [];
  cols= [];
  first=1;
  last=50;
  datasource;
  total_records;
  show_report=false;
  loading=true;
  selectedRows;
  grid_key;
  summary_attributes;
  summary_grid_checked=[]
  details_array;
  grid_label;
  page=1;
  expand_row=false;
  otherCols;

  summary_view=false;

  @Output() rowSelected = new EventEmitter();

  @Output() grid_type_change = new EventEmitter();

  constructor( private message_service:MessageService ,private dMService:DataModelService, private vService: ViewModelService,public gService: GlobalCompService) { }

  ngOnInit(): void {

    this.fetch_grid_data()

  }

  ngAfterViewInit(): void {
      
 
  }

 
  
  fetch_grid_data(){

    this.expand_row=false;

    let datasource = this.vService.get_active_datasource()

    this.grid_key = this.dMService.get_primary_key(datasource)

    this.loading=true; 

    

    this.gService.get_grid_data(datasource,this.page).subscribe(res=>{

      this.grid_label = this.vService.get_label_for_datasource()

      
    if(this.gService.summary_grid){

      this.summary_attributes = this.dMService.get_summary_attributes(datasource)

      this.details_array = this.summary_attributes.slice(1)

    } 

      this.cols =  this.dMService.get_datasource_attributes(datasource)
      this.rows = res["data"]
      this.total_records= res["total_record_count"]
      this.loading=false;
      this.otherCols = this.cols.filter(elem=>!this.summary_attributes.includes(elem))

  })
}

onRowSelect(event){

  this.summary_grid_checked = this.selectedRows.map(e=>String(e[this.grid_key]))
  
  this.rowSelected.emit(event)

}

change_page(event){

this.page=event.page+1;
this.fetch_grid_data()
}



add_selected_rows(){

  this.selectedRows = this.rows.filter(row=>{

      if(this.summary_grid_checked.includes(String(row[this.grid_key]))){

        return true

      }else{

        return false
      }

  })


  this.rowSelected.emit(true)

}


clear_selected_rows(){

  this.selectedRows = [];
  this.summary_grid_checked=[];
}


activate_summary_view(){

  this.gService.summary_grid=true;

  this.grid_type_change.emit('summary_view')

}

get_marker_details(selectedRows){

  
  let marker_rows = this.rows.filter(row=>selectedRows.includes(row["Id"]))

  return marker_rows


}

deactivate_summary_view(){

  let rows = this.rows
  let cols = this.cols;

  // this.rows=[]
  // this.cols=[]

  // this.cols=cols
  // this.rows=rows

  this.loading=true;
  setTimeout(() => {

    this.loading=false;
    
  }, 1000);

  this.gService.summary_grid=false;

  this.grid_type_change.emit('grid_view')

}


addAllDatasetsToCart(selected_asset) {
 
}}