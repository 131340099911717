import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { DataTablesService } from 'src/app/data-tables/data-tables.service';
import { newArray } from '@angular/compiler/src/util';

@Component({
  selector: 'app-log-file-panel',
  templateUrl: './log-file-panel.component.html',
  styleUrls: ['./log-file-panel.component.css']
})
export class LogFilePanelComponent implements OnInit {

  @Input() traces;
  @Input() markers;
  @Input() view;
  @Input() mode='file';
  selectedCurve;
  @Output() selectedCurves = new EventEmitter();
  selectedMarker;
  selectedFill;
  markers_list = [];
  fills_list = [];
  traces_per_file=[];
  displayPanel = true;
  @Output() selectedMarkers = new EventEmitter();
  @Output() selectedFills = new EventEmitter();
  @Output() displayLogCurves = new EventEmitter();
  @Output() displayTypesofCurves = new EventEmitter();
  @Output() hideLogPanel = new EventEmitter();



  constructor(
    private dTService:DataTablesService
  ) { }

  ngOnInit(): void {
    if(this.selectedCurve == undefined && this.dTService.getLogTracesSelection() != null){
        this.selectedCurve = this.dTService.getLogTracesSelection();
    }
    if(this.selectedMarker == undefined && this.dTService.getMarkersSelection() != null){
      this.selectedMarker = this.dTService.getMarkersSelection();
    }
    if(this.mode == "file"){
      this.group_results(this.traces);
    }
    if(this.mode == "db"){
      this.group_results_db(this.traces);
    }
    this.fills_list = [
      'Core Image',
    ]
    
    this.markers_list = Array.from(this.markers)
  }

  group_results(traces){
    traces.forEach(trace => {
      let node = {label:trace["file_name"],value:trace["file_name"]}
      node["items"] = trace["trace_names"].map(item =>this.get_label_value(item,trace["file_path"],trace["boreholeName"]))
      this.traces_per_file.push(node);
    });

  }

  group_results_db(traces){
    traces.forEach(trace => {
      let node = {label:trace["file_name"],value:trace["file_name"]}
      let itemsArr = new Array<any>();
      trace["trace_names"].forEach(trace_type => {
        itemsArr.push(this.get_label_value(trace_type['trace_name'],trace_type['bulk_id'],trace["boreholeName"]));
      });
      node["items"] = itemsArr;
      this.traces_per_file.push(node);
    });

  }

  get_label_value(name,file,wellName){
    return {label:name,value:`${name}$${file}$${wellName}`}
  }

  onCurveSelection(event){
    this.dTService.saveLogTracesSelection(event);
    this.selectedCurves.emit(event);
  }

  onMarkerSelection(event){
    this.selectedMarkers.emit(event);
    this.dTService.saveMarkersSelection(event);
  }

  onFillSelection(event){
    this.selectedFills.emit(event);
  }

  on_tab_change(event){
    if(event.index == 0 || event.index == 1){
      this.displayLogCurves.emit(event);
    }
    if(event.index == 2){
      this.displayTypesofCurves.emit(event);
    }

  }

}
