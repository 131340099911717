<!-- <div class="doc-header">


    <div class="panel-title">
            <p class="title">{{name}}</p>
    </div>

    <div class="panel-icons">

        <i class="fas fa-times icons" pTooltip="Close" (click)="close()" ></i>

    </div>

</div> -->

<div style="height:100%;width: 100%;position: relative; ">

    <div style="position: absolute;width: 50%;top:45%;left:25%" *ngIf="downloading"><p-progressBar [value]="value" [showValue]="false"></p-progressBar></div>

    <ng2-pdfjs-viewer #viewer></ng2-pdfjs-viewer>



</div>