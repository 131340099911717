<p-panel header="Video Tutorials" [toggleable]="true" [style]="{height: 'calc(100vh - 8.2rem)',overflow: 'scroll'}" >
    <!-- <p-carousel [value]="vedios" [numVisible]="5" [numScroll]="5" [circular]="true">
        <ng-template let-vedio pTemplate="item">

            <div class=" p-text-lg">
                <p-card [header]="vedio.name" [subheader]="vedio.description"
                    [style]="{ width: '360px', margin: '5px' }" styleClass="p-card-shadow">
                    <ng-template pTemplate="header">

                    </ng-template>

                </p-card>

            </div>
        </ng-template>
    </p-carousel> -->
    <!-- <p-galleria [(value)]="vedios" [numVisible]="5" [containerStyle]="{'min-width': '100%' }">
        <ng-template pTemplate="item" let-item>
            <video controls #videoPlayer style="min-width: 60% ">
                <source src="{{item.url}}" type="video/mp4" />
                Browser not supported
            </video>
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
            <div class="grid grid-nogutter justify-content-center">
                <p-card header="{{item.name}}" [style]="{'width': '25rem', 'margin-bottom': '2em'}">

                    <video controls #videoPlayer width="100%">
                        <source src="{{item.url}}" type="video/mp4" />
                        Browser not supported
                    </video>
                </p-card>


            </div>
        </ng-template>
    </p-galleria> -->
    <p-dataView #dv [value]="vedios" [paginator]="true" [rows]="8" filterBy="name"
    sortField="name" layout="grid">
    <ng-template pTemplate="header">
        <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
            <div></div>
            <span class="p-input-icon-left p-mb-2 p-mb-md-0">
                <i class="pi pi-search"></i>
                <input type="search" pInputText placeholder="Search by Name" (input)="dv.filter($event.target.value)" style="min-width:500px ">
            </span>
           <div></div>
        </div>
    </ng-template>
    <!-- <ng-template let-product pTemplate="listItem">
        <div class="p-col-12">
            <div class="product-list-item">
                <video controls #videoPlayer  style="min-width: 10% " >
                    <source src="{{product.url}}" type="video/mp4"   />
                    Browser not supported
                </video>
         
                <div class="product-list-detail">
                    <div class="product-name">{{product.name}}</div>
             
                 </div>
 
            </div>
        </div>
    </ng-template> -->
    <ng-template let-product pTemplate="gridItem">
        <div class="p-col-12 p-md-3">
            <div class="product-grid-item card">
                <div class="product-grid-item-content">
                    <video controls #videoPlayer   style="max-width: 100% ">
                        <source src="{{product.url}}" type="video/mp4" />
                        Browser not supported
                    </video>
                   
                    <div class="product-name"> <br>{{product.name}}</div>                 
                   
           
                </div>
 
            </div>
        </div>
    </ng-template>
</p-dataView>
</p-panel>