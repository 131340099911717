import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {FillType, LogFill} from '@int/geotoolkit/welllog/LogFill';
import { Node, StateChanges } from '@int/geotoolkit/scene/Node';
import { TemplateData, TrackData } from './models/log-viewer-template.model';

import {AbstractWellTrack} from '@int/geotoolkit/welllog/multiwell/AbstractWellTrack'
import {AnchorType} from '@int/geotoolkit/util/AnchorType';
import {CorrelationMarker} from '@int/geotoolkit/welllog/multiwell/correlation/CorrelationMarker';
import {CorrelationTrack} from '@int/geotoolkit/welllog/multiwell/CorrelationTrack'
import { DataTablesService } from 'src/app/data-tables/data-tables.service';
import { GlobalCompService } from '../global-comps-services/global.service';
import { LineStyle } from '@int/geotoolkit/attributes/LineStyle';
import { LogAxis } from '@int/geotoolkit/welllog/LogAxis';
import {LogCurve} from '@int/geotoolkit/welllog/LogCurve';
import {LogData} from '@int/geotoolkit/welllog/data/LogData';
import {LogMarker} from '@int/geotoolkit/welllog/LogMarker';
import { MenuItem } from 'primeng/api';
import {TrackType as MultiWellTrackType} from '@int/geotoolkit/welllog/multiwell/TrackType';
import { MultiWellWidget } from '@int/geotoolkit/welllog/multiwell/MultiWellWidget';
import {Events as NavigationEvents} from '@int/geotoolkit/welllog/widgets/tools/Navigation';
import {Plot} from '@int/geotoolkit/plot/Plot';
import {Range} from '@int/geotoolkit/util/Range';
import {TextStyle} from '@int/geotoolkit/attributes/TextStyle';
import {TrackType} from '@int/geotoolkit/welllog/TrackType';
import {UnitFactory} from '@int/geotoolkit/util/UnitFactory'
import {WellNavigation} from '@int/geotoolkit/welllog/multiwell/tools/WellNavigation';

@Component({
  selector: 'app-log-viewer',
  templateUrl: './log-viewer.component.html',
  styleUrls: ['./log-viewer.component.css']
})
export class LogViewerComponent implements OnInit, AfterViewInit{
  
  
  @ViewChild("plot") canvas;
  @Input() mode='file';
  @Output() onClose = new EventEmitter();
  @Input() selectedData;
  @Input() view;
  @Input() maximized: EventEmitter<boolean>;

  data=[];

  widget:MultiWellWidget;
  plot:Plot;
  loading=false;
  
  traces=[];
  markers= new Set();
  markersCompleteArr = new Array<String>();
  metadata_loading=true;
  file_path_selector=null;
  markers_details_view;
  curvesMap = new Map();
  indexTrackTypeMap = new Map();
  currentEventSet = new Set();
  removeTracksSet = new Set();
  selectedFilesCounter = 0;
  filewellMap = new Map();
  filePathSet = new Set();
  isAlreadyWellInitiated = new Map();
  wellIndexTrackMap = new Map();
  maximizedOnInit = false;
  startRange = 0;
  stopRange = 700;
  templateData:TemplateData;
  tracks: TrackData[];
  trackData: TrackData;
  wellIndexLogCurveMap = new Map();
  curveTypeTrackPropertiesMap = new Map();
  groupedTrackMap = new Map();
  groupTraceTypes = new Map();
  boreholeNamesArr = new Set();
  isboreHoleNameInitialised = new Map();
  boreholeMarkerDepthMap = new Map();
  markersListForCorrelations;
  indexCorrelationTrackMap = new Map();
  boreholeIndexMap = new Map();
  indexBoreholeMap = new Map();
  index = 0;
  currentMarkersSet = new Set();
  currentCorrelationsMarkersSet = new Set();
  wellMarkersMap = new Map();
  wellCorrelationMarkersMap = new Map();
  removeMarkersSet = new Set();
  currentMarkersBoreholesSet = new Set();
  maximizedFlag = false;
  wellNameWellStartStopMap = new Map();
  wellDepthScaleMap = new Map();
  depthScale;
  selectedDepthScale;
  initialModelLimits;
  hideLogPanelFlag = true;
  items: MenuItem[] | undefined;
  hideItems: MenuItem[] | undefined;
  showItems: MenuItem[] | undefined;
  heightOfPanel;
  widthOfPanel;
  trackWidth = 200;
  boreholeColumnNameInCurrentView;
  markersFilterKeyAttribute;
  boreholeColumnNameInMarkersView;
  surfaceColumnNameInMarkersView;
  depthColumnNameInMarkersView;

  constructor(private gService:GlobalCompService,private dtService:DataTablesService) { }
  
  ngAfterViewInit(): void {
    this.items = [
      {
          label: 'Options',
          items: [
              {
                  label: 'Hide Log Panel',
                  icon: 'fa fa-regular fa-eye-slash icons',
                  visible: true,
                  command: () => {
                      this.hideLogPanelFlag = false;
                      this.refresh_canvas_post_hiding_log_panel(50,75);
                      this.showHideMenuItems();
                  }
              },
              {
                  label: 'Show Log Panel',
                  icon: 'fa fa-solid fa-eye icons',
                  visible: false,
                  command: () => {
                    this.hideLogPanelFlag = true;
                    if(!this.maximizedFlag){
                      this.styleChangeOnMinimize();
                      this.canvasStyleOnInit();
                    }
                    if(this.maximizedFlag){
                      setTimeout(() => {
                      this.refresh_canvas_show_hidden_log_panel();
                    }, 300);
                  }
                  this.showHideMenuItems();
                  }
              }
          ]
      }
  ];
  }

  getMenuItem(array: any, label: string): any {
    return array.find(item => item.label === label);
  }

  showHideMenuItems(): void {
    if (this.hideLogPanelFlag) {
      this.getMenuItem(this.items[0].items, 'Hide Log Panel').visible = true;
      this.getMenuItem(this.items[0].items, 'Show Log Panel').visible = false;
    }
    if(!this.hideLogPanelFlag){
      this.getMenuItem(this.items[0].items, 'Show Log Panel').visible = true;
      this.getMenuItem(this.items[0].items, 'Hide Log Panel').visible = false;
    }
}

  colors =  ["#636efa", "#ef553b",
  "#00cc96", "#ab63fa",
  "#ffa15a", "#19d3f3",
  "#ff6692", "#b6e880",
  "#ff97ff", "#fecb52"]

  ngOnInit(): void {

    if (this.mode == 'file') {
      this.dtService.get_plugin_selector(this.view,'log_viewer_file','trace_names').subscribe(res=>{

        this.file_path_selector = res["selector"]["file_path"];
        this.markers_details_view = res["selector"]["markers_view"];
        this.boreholeColumnNameInCurrentView = res["selector"]["borehole_name_column_in_view"];
        this.markersFilterKeyAttribute = res["selector"]["markers_filter_attribute_name"];
        this.boreholeColumnNameInMarkersView = res["selector"]["borehole_name_column_in_markers_view"];
        this.surfaceColumnNameInMarkersView = res["selector"]["surface_name_column_in_markers_view"];
        this.depthColumnNameInMarkersView = res["selector"]["depth_column_in_markers_view"];
        this.selectedData.forEach(data => {
          let boreholeName = data[this.boreholeColumnNameInCurrentView];
          this.boreholeNamesArr.add(boreholeName);
        });
        let req = {
          "filters" : [
            {
                "attribute": "borehole_name",
                "operator": "in",
                "values": Array.from(this.boreholeNamesArr)
            }
        ],
        "map_filters": {},
        "sorting": {}
        }
        this.dtService.get_data_for_grid(null,this.markers_details_view,1,req.filters,100).subscribe(
         (resp) => {
           this.groupMarkers(resp['data']);
           if (this.mode == 'file') {
   
             this.dtService.get_plugin_metadata(this.view,'log_viewer_file','trace_names',this.selectedData).subscribe(res=>{
       
               this.metadata_loading=false;
       
               this.traces = res["traces"];
         
             });
           } 
         }
       );
        
       });
   
       this.dtService.get_log_viewer_template().subscribe(resp => {
         this.templateData  = resp['template'];
       });

    }

    
    if (this.mode == 'db') {
        let bulk_ids_arr = new Array<String>();
        let input_arr = new Array<any>();
        let event = new Array<any>();
        this.dtService.get_plugin_details(this.view,'log_viewer_db').subscribe(res=>{
          this.markers_details_view = res["plugin_details"]["selector"]["markers_view"];
          this.boreholeColumnNameInCurrentView = res["plugin_details"]["selector"]["borehole_name_column_in_view"];
          this.markersFilterKeyAttribute = res["plugin_details"]["selector"]["markers_filter_attribute_name"];
          this.boreholeColumnNameInMarkersView = res["plugin_details"]["selector"]["borehole_name_column_in_markers_view"];
          this.surfaceColumnNameInMarkersView = res["plugin_details"]["selector"]["surface_name_column_in_markers_view"];
          this.depthColumnNameInMarkersView = res["plugin_details"]["selector"]["depth_column_in_markers_view"];
          
          let traceNameColumn = res["plugin_details"]["selector"]["trace_name_column_in_view"];
          let bulkIdColumn = res["plugin_details"]["selector"]["bulk_id_column_in_view"];
          this.selectedData.forEach(data => {
            let boreholeName = data[this.boreholeColumnNameInCurrentView];
            let traceName = data[traceNameColumn]
            let bulk_id = data[bulkIdColumn]
            if(traceName == "DEPT"){
              return;
            }
            bulk_ids_arr.push(bulk_id);
            this.boreholeNamesArr.add(boreholeName);
            let input = {
              "borehole_name":boreholeName,
              "bulk_ids":bulk_ids_arr
            }
            if (!input_arr.find(object => object.borehole_name === input.borehole_name)) {
              input_arr.push(input);
            }
            event.push(traceName + '$' + bulk_id + '$'+boreholeName);
          });
          let req = {
            "filters" : [
              {
                  "attribute": String(this.markersFilterKeyAttribute),
                  "operator": "in",
                  "values": Array.from(this.boreholeNamesArr)
              }
          ],
          "map_filters": {},
          "sorting": {}
          }
          this.dtService.get_data_for_grid(null,this.markers_details_view,1,req.filters,100).subscribe( re => {
            this.groupMarkers(re['data']);
            this.dtService.get_traces_from_db(this.view,input_arr).subscribe( resp => {
              this.metadata_loading=false;
               this.traces = resp["traces"];
               this.onCurveSelectionFile(event);
               this.dtService.saveLogTracesSelection(event);
            });
          });
          
        });
    }
    this.widget = new MultiWellWidget({
      'horizontalscrollable': true,
      'verticalscrollable': true,
      'trackcontainer': {
        'border': { 'visible': false }
      },
      'border': { 'visible': false }});
      
      this.widget.setCss({
        'css': [
            '.geotoolkit.welllog.header.AdaptiveLogCurveVisualHeader {',
            '   textstyle-font : 15px Roboto;',
            '}',
            '.geotoolkit.welllog.header.LogTrackVisualHeader {',
             '   textstyle-font : 18px Roboto;',
            '}'
        ].join('\n')
      });
      this.maximized.subscribe( flag => {
        if(flag){
          let height;
          let width;
          [].forEach.call(document.querySelectorAll('.p-dialog-content'), function (el) {
            height = el.clientHeight;
            width = el.clientWidth;
          });
          this.canvas.nativeElement.style.width = width.toString()+'px';
          this.canvas.nativeElement.style.height = height.toString()+'px';
          if(!this.hideLogPanelFlag){
            setTimeout(() => {
              this.refresh_canvas(592,85);
            }, 100);
          }
          this.styleChangeOnMaxmize();
          this.maximizedFlag = true;
        }
        if(!flag){
          let height;
          let width;
          [].forEach.call(document.querySelectorAll('.data-panel'), function (el) {
            height = el.clientHeight;
            width = el.clientWidth;
          });
          this.canvas.nativeElement.style.width = width+'px';
          this.canvas.nativeElement.style.height = height+'px';
          if(!this.hideLogPanelFlag){
            this.refresh_canvas_post_hiding_log_panel(385,181);
          }
          this.styleChangeOnMinimize();
          this.maximizedFlag = false;
        }
      });

  }

  groupMarkers(data){
    let dynamicSurfaceNamesList = new Array<Array<String>>();
    let surfaceNameList;
    this.boreholeNamesArr.forEach(boreholeName =>{
      surfaceNameList = new Array<String>();
      data.forEach(dataNode => {
        if(dataNode[this.boreholeColumnNameInMarkersView] == boreholeName){
          this.markersCompleteArr.push(dataNode[this.surfaceColumnNameInMarkersView]);
          surfaceNameList.push(dataNode[this.surfaceColumnNameInMarkersView]);
          this.boreholeMarkerDepthMap.set(boreholeName+'$'+dataNode[this.surfaceColumnNameInMarkersView],dataNode[this.depthColumnNameInMarkersView])
        }
      });
      if(surfaceNameList.length > 0){
        dynamicSurfaceNamesList.push(surfaceNameList);
      }
    });
      for(let i = 0; i < dynamicSurfaceNamesList.length; i++){
        if(i < dynamicSurfaceNamesList.length-1){
          this.markersListForCorrelations = this.intersectTwoArrays(dynamicSurfaceNamesList[i],dynamicSurfaceNamesList[i+1]);
        }
      }

       let uniqueElements = [...new Set(this.markersCompleteArr)];
       const elementCounts = uniqueElements.map(value => [value, this.markersCompleteArr.filter(str => str === value).length]);
       elementCounts.forEach(element => {
        this.markers.add(element[0]+'('+element[1]+')');
       })

  }

  intersectTwoArrays(arr1, arr2){
    let filteredArray = new Array<String>();
    arr1.filter(value => {
      if(arr2.includes(value)){
        filteredArray.push(value);
      };
    });
    return filteredArray;
  }

  onCurveSelectionFile(event){
    if(event.length == 0){
      this.removeTracksSet = this.currentEventSet;
    }
    
    if(event.length < this.currentEventSet.size){
      this.currentEventSet.forEach( item => {
        if(event.length == 0 && this.currentEventSet.size == 1){
          this.removeTracksSet.add(item);
        }
        for(var j = 0; j < event.length; j++){
          if(item == event[j]){
            this.removeTracksSet.delete(item);
            break;
          } else {
            this.removeTracksSet.add(item);
          }
        }
      }
      )
    }
        
    this.removeTracksSet.forEach( event => {
      this.delete_single_curve_from_widget(event);
      this.currentEventSet.delete(event);
    }
    )

    event.forEach(event => {
      let filePath = event.split("$")[1]
      this.filePathSet.add(filePath);
    });
    
    this.selectedFilesCounter = this.filePathSet.size;

    event.forEach(event => {
      let traceType = event.split("$")[0]
      let filePath = event.split("$")[1]
      let boreholeName = event.split("$")[2]
      let arrayofpathelements = filePath.split("/");
      let fileName = arrayofpathelements[arrayofpathelements.length-1]
      this.currentEventSet.add(event);
      if(this.curvesMap.get(traceType+"$"+fileName) == undefined){

        if (this.mode == 'file') {
          this.dtService.get_curves(filePath,boreholeName,traceType).subscribe(res=>{ 
            this.curvesMap.set(traceType+"$"+fileName, res[0]);
            if(this.filewellMap.get(boreholeName) == undefined){
              this.plot_curves_v2(this.curvesMap.get(traceType+"$"+fileName),traceType);
            }
            else
            {
              if(this.filewellMap.get(boreholeName) != undefined){
                  this.add_single_curve_to_widget(this.curvesMap.get(traceType+"$"+fileName), traceType);
              }
            }
          });
        }

        if (this.mode == 'db') {
          let input_arr = new Array<any>();
          let bulk_array = new Array<any>();
          let traceType = event.split("$")[0]
          let bulk_array_id = event.split("$")[1]
          bulk_array.push(Number(bulk_array_id))
          let wellName = event.split("$")[2]
          let input = {
            "borehole_name":wellName,
            "bulk_ids":bulk_array
          }
          input_arr.push(input);
          this.dtService.get_logs_from_db(this.view,input_arr).subscribe(res=>{ 
            this.curvesMap.set(traceType+"$"+fileName, res[0]);
            if(this.filewellMap.get(wellName) == undefined){
              this.plot_curves_v2(this.curvesMap.get(traceType+"$"+fileName),traceType);
            }
            else
            {
              if(this.filewellMap.get(wellName) != undefined){
                  this.add_single_curve_to_widget(this.curvesMap.get(traceType+"$"+fileName), traceType);
              }
            }
          });
        }
       
      } 
      else {
        if(!this.isAlreadyWellInitiated.get(boreholeName)){  
          this.plot_curves_v2(this.curvesMap.get(traceType+"$"+fileName),traceType);
        
        } else if(this.isAlreadyWellInitiated.get(boreholeName) && !this.wellIndexTrackMap.get(fileName+'$'+traceType)){
          this.add_single_curve_to_widget(this.curvesMap.get(traceType+"$"+fileName), traceType);
        }
      }
    });

    this.maximized.subscribe( flag => {
      if(flag){
        this.styleChangeOnMaxmize();
        this.maximizedFlag = true;
      }
      if(!flag){
        this.styleChangeOnMinimize();
        this.maximizedFlag = false;
      }
    });
   
  }

  plot_curves_v2(res,traceType){
    this.add_multiple_curves_to_widget(res, traceType);
    
    this.plot = new Plot({
      'canvasElement': this.canvas.nativeElement,
      'root': this.widget,
      'autoSize': false,
      'actualSize': true
    });
    this.canvasStyleOnInit();

  }

  add_multiple_curves_to_widget(res, traceType){
    let fileName = res['fileName']
    let boreholeName = res['boreholeName']
    for(let i=0; i < this.selectedFilesCounter; i++){
      if(!this.isAlreadyWellInitiated.get(boreholeName)){
        let well = this.widget.addTrack(MultiWellTrackType.WellTrack, {
          'range': new Range(this.startRange,this.stopRange),
          'name': boreholeName,
          'welllog': {
              'range': new Range(res['start'],res['stop'])
          }
        });
        this.wellNameWellStartStopMap.set(boreholeName, res['start']+'$'+res['stop']);
        this.filewellMap.set(boreholeName,well);
        this.isAlreadyWellInitiated.set(boreholeName,true);
        this.isboreHoleNameInitialised.set(boreholeName+'$'+fileName,true);
        let wellcreated = this.filewellMap.get(boreholeName);
        const indexTrack = wellcreated.insertTrack(wellcreated.getTracksCount(),{
          'type':TrackType.IndexTrack
        });
        this.wellIndexTrackMap.set(boreholeName+'$'+'index', indexTrack);
        this.add_single_curve_to_widget(this.curvesMap.get(traceType+"$"+fileName), traceType);
        const correlationTrack = this.widget.addTrack(MultiWellTrackType.CorrelationTrack, {
          'width': 50
        });
        this.wellIndexTrackMap.set(boreholeName+'$'+'correlationTrack', correlationTrack);
        this.indexCorrelationTrackMap.set(this.index,correlationTrack);
        this.boreholeIndexMap.set(boreholeName,this.index);
        this.indexBoreholeMap.set(this.index, boreholeName);
        // this.setCorrelationTracksWells();
        const wellNavigation = new WellNavigation(wellcreated);
        wellcreated.getTool().add(wellNavigation);
        this.updateNavigation(wellNavigation,boreholeName);
        this.index++;
      }
    }
    
  }

  updateNavigation (wellNavigation, wellName) {
    let wellcreated = this.filewellMap.get(wellName);
    wellNavigation.addListener(NavigationEvents.DepthRangeChanged,
        (sender, eventArgs) => {
            const visibleDepthLimits = wellcreated.getVisibleDepthLimits();
            const newVisibleDepthLimits = eventArgs['limits'];
            const heightDiff = newVisibleDepthLimits.getSize() - visibleDepthLimits.getSize();
            if (wellcreated.getDepthLimits().intersects(newVisibleDepthLimits) === false) {
                return;
            }
            eventArgs.stopPropagation(true, true);
            if (heightDiff === 0) {
                this.widget.scrollTrackByDepth(wellcreated, visibleDepthLimits.getLow() - newVisibleDepthLimits.getLow());
            } else {
              wellcreated.setVisibleDepthLimits(newVisibleDepthLimits);
            }
        }
    );
}

  add_single_curve_to_widget(res, traceType){
    let fileName = res['fileName'];
    let boreholeName = res['boreholeName']
    if(this.wellIndexTrackMap.get(fileName+'$'+traceType) == undefined){
      let values = res['tracesData'][0][traceType]['data']
      let depths = values.map((v, i) => (res['start'] + i * res['step']));
      let data = new LogData(depths, values);
      const random = Math.floor(Math.random() * this.colors.length);

      let log_curve = new LogCurve(data)     
      .setName(traceType)
      .setNormalizationLimits(res['tracesData'][0][traceType]['traceMin'],res['tracesData'][0][traceType]['traceMax'])
      .setLineStyle(this.colors[random]);

      if(this.filewellMap.get(boreholeName) != undefined){
        let well = this.filewellMap.get(boreholeName);
        let startStopRange = this.wellNameWellStartStopMap.get(boreholeName);
        let start = Number(startStopRange.split("$")[0]);
        let stop = Number(startStopRange.split("$")[1]);
        if(res['start'] < start){
          start = res['start']
          well.setDepthLimits(start, stop);
          this.wellNameWellStartStopMap.set(boreholeName, start+'$'+stop);
        }
        if(res['stop'] > stop){
          stop= res['stop']
          well.setDepthLimits(start, stop);
          this.wellNameWellStartStopMap.set(boreholeName, start+'$'+stop);
        }
        const track = well.insertTrack(well.getTracksCount(),{
              'type':TrackType.LinearTrack,
              'width':this.trackWidth,
              'lineStyle': {
                'color':'grey',
                'width': 0.5
              },
              }).addChild([log_curve]);
        
        // this.setCorrelationTracksWells();
        this.wellDepthScaleMap.set(boreholeName, well.getDepthScale());
        this.wellIndexTrackMap.set(fileName+'$'+traceType, track);
        this.wellIndexLogCurveMap.set(fileName+'$'+traceType,log_curve);

      }
      this.styleAsperTemplate(fileName,boreholeName,traceType);
    }
  }

  styleAsperTemplate(fileName,boreholeName, traceType){
    if(this.templateData != undefined){
      this.tracks = this.templateData['tracks'];
      this.trackData = this.tracks != undefined ? this.tracks.find(x => x.curve_type == traceType): undefined;
      if(this.trackData != undefined){
        this.curveTypeTrackPropertiesMap.set(fileName+'$'+traceType,this.trackData);
        this.createLogFillCurve(fileName,boreholeName,traceType,this.trackData);
      }
    }
  }

  createLogFillCurve(fileName,boreholeName,traceType,template){
    let well = this.filewellMap.get(boreholeName);
    this.widget.setHeaderHeight(145);
    if(template.group_with != undefined){
      const track = this.wellIndexTrackMap.get(fileName+"$"+traceType);
      const previous_track = this.wellIndexTrackMap.get(fileName+'$'+template.group_with);
      if(this.groupedTrackMap.get(fileName+'$'+traceType+'$'+template.group_with) == undefined && well.getTrackIndex(previous_track) != -1){
      let log_curve_to_be_grouped_with = this.wellIndexLogCurveMap.get(fileName+'$'+template.group_with);
      let template_of_to_be_grouped_with = this.curveTypeTrackPropertiesMap.get(fileName+'$'+template.group_with);
      if(log_curve_to_be_grouped_with != undefined){
        let log_curve = this.wellIndexLogCurveMap.get(fileName+'$'+traceType);
        log_curve_to_be_grouped_with.setLineStyle(template_of_to_be_grouped_with.line_color);
        log_curve.setLineStyle(template.line_color);
        let rightcolor = template.fill_color;
        let leftColor = template_of_to_be_grouped_with.fill_color;
        let curve1 = log_curve;
        let curve2 = log_curve_to_be_grouped_with;
        this.groupTraceTypes.set(fileName+'$'+traceType,fileName+'$'+template.group_with);
        this.groupTraceTypes.set(fileName+'$'+template.group_with,fileName+'$'+traceType);
      const new_track =  well.insertTrack(well.getTrackIndex(previous_track),{
        'type':TrackType.LinearTrack,
        'width':this.trackWidth,
        'lineStyle': {
          'color':'grey',
          'width': 0.5},}).addChild([log_curve,log_curve_to_be_grouped_with,new LogFill({
          'curve1': curve1,
          'curve2': curve2,
          'leftfillstyle': leftColor,
          'rightfillstyle': rightcolor
      })].reverse());
      this.wellIndexTrackMap.set(fileName+'$'+traceType,new_track);
      const updated_well = well.removeTrack(previous_track);
      this.filewellMap.set(boreholeName, updated_well);
      this.groupedTrackMap.set(fileName+'$'+traceType+'$'+template.group_with,previous_track);
      this.groupedTrackMap.set(fileName+'$'+template.group_with+'$'+template.group_with,previous_track);
      well.removeTrack(track);
      this.cleanupTheCanvas(fileName,boreholeName);
      }
    } else {
      let log_curve = this.wellIndexLogCurveMap.get(fileName+'$'+traceType);
      log_curve.setLineStyle(template.line_color);
      const track = this.wellIndexTrackMap.get(fileName+"$"+traceType);
      let fillType;
      if(template.fill_type == 'right'){
        fillType = FillType.Right;
      }
      if(template.fill_type == 'left'){
        fillType = FillType.Left;
      }
      const new_track =  well.insertTrack(well.getTrackIndex(track),{
        'type':TrackType.LinearTrack,
        'width':this.trackWidth,
        'lineStyle': {
          'color':'grey',
          'width': 0.5},}).addChild([log_curve,new LogFill({
            'curve1': log_curve,
            'fillstyle': template.fill_color,
            'filltype': fillType,
      })].reverse());
      this.wellIndexTrackMap.set(fileName+'$'+traceType,new_track);
      const updated_well = well.removeTrack(track);
      this.filewellMap.set(boreholeName, updated_well);
    }}
    else {
      let log_curve = this.wellIndexLogCurveMap.get(fileName+'$'+traceType);
      log_curve.setLineStyle(template.line_color);
      const track = this.wellIndexTrackMap.get(fileName+"$"+traceType);
    const new_track =  well.insertTrack(well.getTrackIndex(track),{
      'type':TrackType.LinearTrack,
      'width':this.trackWidth,
      'lineStyle': {
        'color':'grey',
        'width': 0.5},}).addChild([log_curve,new LogFill({
          'curve1': log_curve,
          'fillstyle': template.fill_color,
    })].reverse());
    this.wellIndexTrackMap.set(fileName+'$'+traceType,new_track);
    const updated_well = well.removeTrack(track);
    this.filewellMap.set(boreholeName, updated_well);
    }
}

  cleanupTheCanvas(fileName,wellName){
    let well = this.filewellMap.get(wellName);
    if(well.getTracksCount() == 1){
      const track = this.wellIndexTrackMap.get(wellName+"$"+'index');
      well.removeTrack(track);
      this.filewellMap.delete(wellName);
      this.isAlreadyWellInitiated.set(wellName,false);
      this.wellIndexTrackMap.delete(wellName+'$'+'index');
      const correlationTrack = this.wellIndexTrackMap.get(wellName+"$"+'correlationTrack');
      this.widget.removeTrack(correlationTrack);
    }
  }


  delete_single_curve_from_widget(event){
    const traceType = event.split("$")[0]
    const filePath = event.split("$")[1]
    const wellName = event.split("$")[2]
    let arrayofpathelements = filePath.split("/");
    let fileName = arrayofpathelements[arrayofpathelements.length-1];
    this.delete_from_grouped_track(fileName,wellName, traceType);
    const track = this.wellIndexTrackMap.get(fileName+"$"+traceType);
    let well = this.filewellMap.get(wellName);
    well.removeTrack(track);
    this.wellIndexTrackMap.delete(fileName+'$'+traceType);
    this.removeTracksSet.delete(event);
    this.cleanupTheCanvas(fileName,wellName);
  }

  delete_from_grouped_track(fileName,wellName, traceType){
    let anotherTraceType = this.groupTraceTypes.get(fileName+"$"+traceType);
    if(anotherTraceType != undefined){
      const fileName = anotherTraceType.split("$")[0]
      const traceTypeOfotherCurve = anotherTraceType.split("$")[1];
      let track = this.groupedTrackMap.get(fileName+"$"+traceType+'$'+traceTypeOfotherCurve);
      let track1 = this.wellIndexTrackMap.get(fileName+"$"+traceType);
      if(track == undefined){
        track1 = this.wellIndexTrackMap.get(fileName+"$"+traceTypeOfotherCurve);
      }
      let log_curve = this.wellIndexLogCurveMap.get(fileName+'$'+traceTypeOfotherCurve);
      let well = this.filewellMap.get(wellName);
      let trackTemplateProperties = this.curveTypeTrackPropertiesMap.get(fileName+'$'+traceTypeOfotherCurve);
      const new_track = well.insertTrack(well.getTrackIndex(track1),{
        'type':TrackType.LinearTrack,
        'width':this.trackWidth,
        'lineStyle': {
          'color':'grey',
          'width': 0.5},}).addChild([log_curve,new LogFill({
            'curve1': log_curve,
            'fillstyle': trackTemplateProperties.fill_color,
            'filltype': trackTemplateProperties.fill_type,
      })].reverse());
      this.wellIndexTrackMap.set(fileName+'$'+traceTypeOfotherCurve,new_track);
      this.wellIndexTrackMap.delete(fileName+'$'+traceType);
      const updated_well = well.removeTrack(track1);
      this.filewellMap.set(wellName, updated_well);
      this.groupedTrackMap.delete(fileName+"$"+traceTypeOfotherCurve+'$'+traceType);
      this.groupedTrackMap.delete(fileName+"$"+traceType+'$'+traceTypeOfotherCurve);
      this.groupTraceTypes.delete(fileName+"$"+traceType);
      this.groupTraceTypes.delete(fileName+"$"+traceTypeOfotherCurve);
    }
  }

  refresh_canvas(x,y){
    let height;
    let width;
    [].forEach.call(document.querySelectorAll('.data-panel'), function (el) {
      height = el.clientHeight;
      width = el.clientWidth;
    });
    if(this.plot != undefined) {
      this.plot.setSize(width + x,height + y);
    }
  }

  refresh_canvas_post_hiding_log_panel(x,y){
    let height;
    let width;
    [].forEach.call(document.querySelectorAll('.p-dialog-content'), function (el) {
      height = el.clientHeight;
      width = el.clientWidth;
    });
    if(this.plot != undefined) {
      this.plot.setSize(width - x,height - y);
    }
  }

  refresh_canvas_show_hidden_log_panel(){
    let height;
    let width;
    [].forEach.call(document.querySelectorAll('.p-dialog-content'), function (el) {
      height = el.clientHeight;
      width = el.clientWidth;
    });
    if(this.plot != undefined) {
      this.plot.setSize(width - 330,height - 85);
    }

    this.styleChangeOnMaxmize();
  }

  zoom_out(){
    this.widget.zoomOut()
  }

  zoom_in(){
    
    this.widget.zoomIn()

  }

  isDisposed () {
    return this.widget.isDisposed();
  }

  close(){

    this.onClose.emit()
  }

  onMarkerSelection(event){
    if(event.length == 0){
      this.removeMarkersSet = this.currentMarkersSet;
    }
    
    if(event.length < this.currentMarkersSet.size){
      this.currentMarkersSet.forEach( item => {
        if(event.length == 0 && this.currentMarkersSet.size == 1){
          this.removeMarkersSet.add(item);
        }
        for(var j = 0; j < event.length; j++){
          if(item == event[j]){
            this.removeMarkersSet.delete(item);
            break;
          } else {
            this.removeMarkersSet.add(item);
          }
        }
      }
      )
    }
        
    this.removeMarkersSet.forEach( event => {
      this.delete_single_marker(event);
      this.delete_correlations(event);
      this.currentMarkersSet.delete(event);
    }
    );

    event.forEach(marker => {
      this.currentMarkersSet.add(marker);
        for (let entry of this.isboreHoleNameInitialised.entries()) {
          let boreholeName = entry[0].split('$')[0];
          let fileName = entry[0].split('$')[1];
          let markerName = marker.split("(")[0];
          let markerDepth = this.boreholeMarkerDepthMap.get(boreholeName+'$'+markerName);
          if(!this.currentMarkersBoreholesSet.has(markerName+'$'+boreholeName)){
            this.addTops(markerName, markerDepth, "#8B0000", boreholeName, fileName);
            this.currentMarkersBoreholesSet.add(markerName+'$'+boreholeName);
          }
        }
      }
      );

    event.forEach(marker => {
    for (let entry of this.isboreHoleNameInitialised.entries()) {
    let boreholeName = entry[0].split('$')[0];
    let markerName = marker.split("(")[0];
    if(this.isAlreadyWellInitiated.size > 1 && this.markersListForCorrelations.includes(markerName)){
      let leftDepth = this.boreholeMarkerDepthMap.get(boreholeName+'$'+markerName);
      const indexOfBorehole = this.boreholeIndexMap.get(boreholeName);
      const indexOfNextBorehole = indexOfBorehole + 1;
      const nextBoreholeName = this.indexBoreholeMap.get(indexOfNextBorehole);
      const rightDepth = this.boreholeMarkerDepthMap.get(nextBoreholeName+'$'+markerName);
      if(nextBoreholeName != undefined){
        if(!this.currentCorrelationsMarkersSet.has(markerName+'$'+boreholeName)){
          this.addTopsCorrelation(boreholeName,markerName,leftDepth, rightDepth, "#8B0000");
          this.currentCorrelationsMarkersSet.add(markerName+'$'+boreholeName);
        }
      }
    }}});
  }

  addTops (name, depth, color, boreholeName,fileName) {
    const top = new LogMarker(depth,name);
    top.setLineStyle(LineStyle.fromObject({'color': color}));
    top.setTextStyle(TextStyle.fromObject({
        'color': color,
        'alignment': 'left',
        'font': '12px sans-serif'
    }));
    top.setNameLabel(name);
    top.setNameLabelPosition(AnchorType.TopCenter);
    top.setDepthLabel(depth);
    top.setDepthLabelPosition(AnchorType.BottomCenter);
    if(this.isAlreadyWellInitiated.get(boreholeName)){
      let well = this.filewellMap.get(boreholeName);
      well.getMarkerLayer().addChild(top);
      this.wellMarkersMap.set(name+'$'+boreholeName,top);
    }
  }

addTopsCorrelation (boreholeName,markerName,leftDepth, rightDepth, color) {
  const indexOfBorehole = this.boreholeIndexMap.get(boreholeName);
  const track = this.indexCorrelationTrackMap.get(indexOfBorehole);
  const index = this.widget.indexOfTrack(track);
  const leftWell = this.widget.getTrackAt(index - 1);
  const rightWell = this.widget.getTrackAt(index + 1);
  track.setWells(leftWell, rightWell);
  const correlationMarker = new CorrelationMarker(leftDepth, rightDepth, {
    'linestyle': {
        'color': color,
        'width': 2,
        'pixelsnapmode': {'x': false, 'y': false}
    }
});
  track.addChild(correlationMarker);
  this.wellCorrelationMarkersMap.set(markerName+'$'+boreholeName,correlationMarker);

}

  delete_single_marker(marker){
    for (let entry of this.isboreHoleNameInitialised.entries()) {
      let boreholeName = entry[0].split('$')[0];
      let fileName = entry[0].split('$')[1];
      let markerName = marker.split("(")[0];
      let well = this.filewellMap.get(boreholeName);
      const markerTop = this.wellMarkersMap.get(markerName+'$'+boreholeName);
      well.getMarkerLayer().removeChild(markerTop);
      this.removeMarkersSet.delete(marker);
      this.currentMarkersBoreholesSet.delete(markerName+'$'+boreholeName);
    }
  }

  delete_correlations(marker){
    for (let entry of this.isboreHoleNameInitialised.entries()) {
        let boreholeName = entry[0].split('$')[0];
        let markerName = marker.split("(")[0];
        const indexOfBorehole = this.boreholeIndexMap.get(boreholeName);
        const track = this.indexCorrelationTrackMap.get(indexOfBorehole);
        const correlationMarker = this.wellCorrelationMarkersMap.get(markerName+'$'+boreholeName);
        track.removeChild(correlationMarker);
        this.wellCorrelationMarkersMap.delete(markerName+'$'+boreholeName);
        this.currentCorrelationsMarkersSet.delete(markerName+'$'+boreholeName);
    }
  }


  onFillSelection(event){

  }

  onTabChangeOfLogCurves(event){
  
  }

  onTabChangeOfFillType(event){
  
  }

  styleChangeOnMaxmize(){

    let height;
    let width;
    [].forEach.call(document.querySelectorAll('.data-panel'), function (el) {
      height = el.clientHeight;
      width = el.clientWidth;
    });
    width = width + 300;

    [].forEach.call(document.querySelectorAll('.header-panel'), function (el) {
      el.style.width = width.toString()+'px';
    });

    let heightForPanel = height + 123;
    
    [].forEach.call(document.querySelectorAll('.panel-container'), function (el) {
      el.style.height = heightForPanel.toString() + 'px';});

    [].forEach.call(document.querySelectorAll('.p-listbox-list-wrapper'), function (el) {
      el.style.height = height.toString()+'px';});
  }
  
  styleChangeOnMinimize(){
    
    let height;
    let width;
    [].forEach.call(document.querySelectorAll('.data-panel'), function (el) {
      height = el.clientHeight;
      width = el.clientWidth;
    });
    
    [].forEach.call(document.querySelectorAll('.header-panel'), function (el) {
      el.style.width = width.toString()+'px';
    });

    [].forEach.call(document.querySelectorAll('.panel-container'), function (el) {
      el.style.height = '78.076vh';});
        
    [].forEach.call(document.querySelectorAll('.p-listbox-list-wrapper'), function (el) {
      el.style.height = '63.5vh';});
  }

  canvasStyleOnInit(){
    if(!this.maximizedFlag){
      this.refresh_canvas(592,85);
      this.styleChangeOnMinimize();
      let height;
      let width;
      [].forEach.call(document.querySelectorAll('.data-panel'), function (el) {
        height = el.clientHeight;
        width = el.clientWidth;
      });
      this.canvas.nativeElement.style.width = width.toString()+'px';
      this.canvas.nativeElement.style.height = height.toString()+'px';
    }
  }

  reset_zoom(){
    Node.enableSceneGraphNotification(false);
    for(let i=0; i < this.widget.getTracksCount(); i++) {
      const well = this.widget.getTrackAt(i);
      if(well.getName() != null && well instanceof AbstractWellTrack) {
        well.setDepthScale(Number(this.wellDepthScaleMap.get(well.getName())));
      }
   }
  this.widget.alignTracks(0, 'top');
  Node.enableSceneGraphNotification(true);
  this.widget.updateState(undefined, StateChanges.Rebuild);
  this.selectedDepthScale = {};
  this.depthScale = {};
  }

  zoomToFit(){
    this.widget.setCenterVisibleModelLimits(this.widget.getCenterModelLimits().clone());
  }

  changeInput(){

    for(let i=0; i < this.widget.getTracksCount(); i++) {
      const well = this.widget.getTrackAt(i);
      if(well instanceof AbstractWellTrack) {
        var unitFactory = UnitFactory.getInstance();
        well.setDepthScale(Number(this.depthScale));
      }
   }
   this.widget.alignTracks(0, 'top');

  }

  // resetZoomToFit(){
  //   this.widget.setCenterModelLimits(this.widget.getCenterModelLimits().clone());
  // }

  setCorrelationTracksWells() {
    for (var i = 0; i < this.widget.getTracksCount(); ++i) {
        var track = this.widget.getTrackAt(i);
        if (track instanceof CorrelationTrack) {
            var leftWell, rightWell;
            if (i >= 1) {
                leftWell = this.widget.getTrackAt(i - 1);
            }
            if (i < this.widget.getTracksCount() - 1) {
                rightWell = this.widget.getTrackAt(i + 1);
            }
            if (rightWell && leftWell) {
                track.setWells(leftWell, rightWell);
            }
        }
    }
}

addHideMenu(){
  this.hideItems = [
    {
        label: 'Options',
        items: [
            {
                label: 'Hide Log Panel',
                icon: 'fa fa-regular fa-eye-slash icons',
                command: () => {
                    this.hideLogPanelFlag = false;
                    this.refresh_canvas_post_hiding_log_panel(50,75);
                }
            }
        ]
    }
];
}

addShowMenu(){
  this.showItems = [
    {
        label: 'Options',
        items: [
            {
                label: 'Show Log Panel',
                icon: 'fa fa-solid fa-eye icons',
                command: () => {
                  this.hideLogPanelFlag = true;
                  if(!this.maximizedFlag){
                    this.styleChangeOnMinimize();
                    this.canvasStyleOnInit();
                  }
                  if(this.maximizedFlag){
                    setTimeout(() => {
                    this.refresh_canvas_show_hidden_log_panel();
                  }, 300);
                }
                }
            }
        ]
    }
  ];
}
   

}
