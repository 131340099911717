import { Observable, throwError } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderPortalServicesService } from '../services/loader-portal-services.service';
import { embedDashboard } from '@superset-ui/embedded-sdk';

@Injectable({
  providedIn: 'root'
})
export class InsightsDashboardService {

  insights_server_url;
  constructor(private http:HttpClient,private loaderPService:LoaderPortalServicesService) { 
    this.loaderPService.get_settings().subscribe((res: any) => {
      this.insights_server_url = res.insights_server_endpoint
    });
  }

  
  getGuestToken(id): Observable<any> {
    
    return this.http.get(`${this.loaderPService.browse_data_api_endpoint}/insights/get_guest_token?id=${id}`)
  }

  embedDashboard(id): Observable<void> {
    return new Observable((observer) => {
      this.getGuestToken(id).subscribe(
        (token) => {
          embedDashboard({
            id: id,
            supersetDomain: this.insights_server_url,
            mountPoint: document.getElementById('dashboard'),
            fetchGuestToken: () => token["token"],
            dashboardUiConfig: {
              hideTitle: true,
              hideChartControls: true,
              hideTab: true
            },
          });
          observer.next();
          observer.complete();
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }
}
