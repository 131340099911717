<div class="logo">
    <div class="logo__inner">
        <div style="display: flex;">
            <!-- <span class="menu-bar-title" > Driven</span> -->
            <span class="menu-bar-title" (click)="navigate_to('/home')" > Driven</span>
            <div style="margin-left: 1rem;">
                <!-- <i pTooltip="Search" (click)="navigate_to('/search')"  class="fas fa-search navigation-option" [ngClass]="{'active':menuService.active_link=='/search'}"></i> -->
                <!-- <i pTooltip="Data Catalogue" (click)="navigate_to('/data-tables')"
                    class="fas fa-sitemap navigation-option"
                    [ngClass]="{'active':menuService.active_link=='/data-tables'}"></i>
                <i pTooltip="File Manager" (click)="navigate_to('/loader-portal/data-loader')"
                    class="fas fa-archive navigation-option"
                    [ngClass]="{'active':menuService.active_link=='/data-tables'}"></i>
                    <i pTooltip="Dashboards" (click)="navigate_to('/config-dashboard')" 
                    class="fas fa-solid fa-chart-column navigation-option" 
                    [ngClass]="{'active':menuService.active_link=='/dashboards'}"></i>
                <i pTooltip="View Jobs" (click)="navigate_to('/loader-portal/manage-requests')"
                    class="fas fa-table navigation-option"
                    [ngClass]="{'active':menuService.active_link=='/data-tables'}"></i> -->
            </div>
        </div>

        <div class="logout">
            <button pButton pRipple type="button" style="color: #fff;" (click)=signout() icon="pi pi-sign-out"
                class="p-button-rounded p-button-secondary p-button-outlined"></button>
        </div>

    </div>