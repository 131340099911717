import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  id=null;
  title=null;
  show_dashboard=false;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {

    

    this.route.queryParamMap.subscribe((query:any) => {

      
      this.id=query.params.id;
      this.title=query.params.title;
      setTimeout(() => {
        this.show_dashboard=true;
      }, 10);
      
     
    });
  }

  

}
