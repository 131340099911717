import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoaderPortalServicesService {
  constructor(private http: HttpClient, private route: Router) { }
  all_cart_items = [];
  all_projects = []
  projects = []
  all_assets = []
  assets = []
  companies = []
  epi_proj_ids = []
  queuedExportFiles = [];
  userPoolId;
  clientId;
  disableingest = true
  user;
  project;
  job_ID;
  uploadedFiles = [];
  uploaded_size = 0;
  progress_mapper = [];
  queuedFiles = [];
  file_association_map = [];
  association_columns = ['borehole', 'datatype', 'confidentiality', 'urgency'];
  bucket;
  exportBucket;
  project_data_api_endpoint;
  browse_data_api_endpoint;
  insight_api_endpoint;
  company_name;
  folder_tree = [];
  system_tags = [];
  repo_type = "corporate";
  auth_token = '';

  proj_id = '';

  epi_proj_id = {
    type: 'existing',
    code: null,
    name: '',
  };

  proj = {
    type: 'existing',
    name: '',
    code: null,
  };

  company = {
    type: 'existing',
    name: '',
    code: null,
  };

  asset = {
    type: 'existing',
    name: '',
    code: null,
  };

  crs = {
    code: null,
    name: null,
  };

  load_type = false
  selectedFolder

  reset_properties() {
    this.job_ID = Date.now();
    this.uploadedFiles = [];
    this.uploaded_size = 0;
    this.progress_mapper = [];
    this.queuedFiles = [];
    this.file_association_map = [];
    this.folder_tree = [];
    this.load_type;
    this.selectedFolder
  }


  get_association_object_fm(obj) {
    return {
      dataset_id: obj['id'],
      filename: obj["file_name"],
      file_url: obj['path'],
      project: '',
      valid: false,
      dataType: '',
      view: '',
      executionPlatform: '',
      params: {},
      jobType: "",
      workflow: ""
    };
  }


  route_error() {
    this.route.navigate(['unauthorized'])
  }
  get_file_association_map() {

    if (this.file_association_map.length === 0) {
      this.uploadedFiles.forEach((file) =>
        this.file_association_map.push(this.get_association_object_fm(file))
      );
    } else if (this.file_association_map.length < this.uploadedFiles.length) {
      this.uploadedFiles.forEach((file) => {
        if (
          !this.file_association_map.find(
            (file_map) => file_map.filename == file["name"]
          )
        ) {
          this.file_association_map.push(this.get_association_object_fm(file));
        }
      });
    }
    return this.file_association_map;
  }

  get_boreholes_list() {
    let body = {
      type: 'filter',
      attributes: [
        {
          id: 21,
          attribute: 'Borehole Name',
          topic: 'PSL_Borehole_Summary',
          attributeName: 'Borehole',
          attributeType: 'STRING',
          list: [],
        },
      ],
      index: [],
      aggFunc: [],
      filters: [],
      templateFilters: [],
      project: 'PROJ_DMAAS',
      dataSource: 'Borehole Summary',
      user: 'usinha',
      associationKeys: [],
      order_by_position_topics: ['PSL_Borehole_Summary'],
    };

    return this.http.post(`${this.browse_data_api_endpoint}/widget`, body);
  }

  get_datatypes_list() {
    return [
      { name: 'Well Logs', code: 'Well Logs' },
      { name: 'Well Documents', code: 'Well Documents' },
      { name: 'Core data', code: 'Core data' },
      { name: 'Geochemistry', code: 'Geochemistry' },
    ];
  }

  get_document_types_list() {
    return this.http.get(`${this.browse_data_api_endpoint}/getAllDocDtypes`);
  }

  get_datatypes_list_dynamic() {
    return this.http.get(`${this.browse_data_api_endpoint}/filemanagement/getAllDtypes`);

  }


  get_workflows(job_type) {
    return this.http.get(`${this.browse_data_api_endpoint}/jobmanagement/getworkflows?job_type=${job_type}`);

  }

  get_templates_list(datatype) {
    return this.http.get(
      `${this.browse_data_api_endpoint}/getTemplatesList/${datatype}`
    );
  }

  get_template(template) {
    return this.http.get(`${this.browse_data_api_endpoint}/template/${template}`);
  }

  get_confidentiality_list() {
    return [
      { name: 'Confidential', code: 'Confidential' },
      { name: 'Secret', code: 'secret' },
      { name: 'Private', code: 'private' },
      { name: 'Public', code: 'public' },
    ];
  }

  get_urgency_list() {
    return [
      { name: 'high', code: 'high' },
      { name: 'urgent', code: 'urgent' },
      { name: 'low', code: 'low' },
    ];
  }

  // Dmaas 2
  //  get_portal_jobs(body) {
  //   return this.http.post(`${this.api_gateway_endpoint}/getdata`, body);
  // }

  get_base_url() {
    return this.repo_type === 'project' ? this.project_data_api_endpoint : this.browse_data_api_endpoint

  }

  get_portal_jobs(body = null) {
    return this.http.get(`${this.browse_data_api_endpoint}/getAllJobs`);
  }

  update_job(body) {
    return this.http.post(`${this.browse_data_api_endpoint}/updatedata`, body);
  }

  get_job_details(body) {
    return this.http.post(`${this.browse_data_api_endpoint}/getdata`, body);
  }

  get_portal_export_jobs() {
    return this.http.get(`${this.browse_data_api_endpoint}/getAllExportJobs`);
  }

  get_export_job_details(id) {
    return this.http.get(`${this.browse_data_api_endpoint}/jobInfo/${id}`);
  }

  get_public_data_grid_meta(body) {
    return this.http.post(
      `${this.browse_data_api_endpoint}/datasource/getGridMeta`,
      body
    );
  }

  get_public_data_map_shape(body) {
    return this.http.post(`${this.browse_data_api_endpoint}/datasource/getShape`, body);
  }

  get_public_data_filters(body) {
    return this.http.post(
      `${this.browse_data_api_endpoint}/datasource/getFilters`,
      body
    );
  }

  get_dataset_details(body) {
    return this.http.post(
      `${this.browse_data_api_endpoint}/datasource/getDatasets`,
      body
    );
  }

  get_stats(body) {
    return this.http.post(`${this.browse_data_api_endpoint}/datasource/getStats`, body);
  }

  get_settings() {
    return this.http.get('/assets/app-settings.json');
  }

  get_model(model, type) {
    return this.http.get(`/assets/${type}/${model}.json`);
  }

  get_serviceData(year_month) {
    return this.http.get(
      `${this.browse_data_api_endpoint}/getServicePoints?year_month=${year_month}`
    );
  }

  getJobsStats(days) {
    return this.http.get(
      `${this.browse_data_api_endpoint}/getJobStats?days=${days}`
    );
  }

  getLog(id) {
    return this.http.get(`${this.browse_data_api_endpoint}/getLog/${id}`);
  }

  getReports() {
    return this.http.get(`${this.browse_data_api_endpoint}/getReports`);
  }
  get_project_input() {
    return this.http.get(`${this.get_base_url()}/dataTables/getProjects`)
  }

  get_job_list(page,nrows) {
    return this.http.post(`${this.browse_data_api_endpoint}/jobmanagement/getJobs`,{},{
      params:{
        page:page,
        nrows:nrows
      }});
  }

  get_job_summary_view_list(page,nrows) {
    return this.http.post(`${this.browse_data_api_endpoint}/jobmanagement/getJobsSummaryView`,{},{
      params:{
        page:page,
        nrows:nrows
      }});
  }

  get_job_logs(body) {
    return this.http.post(`${this.browse_data_api_endpoint}/jobmanagement/getLogs`, body);
  }

  dash_stats(body) {
    return this.http.post(`${this.browse_data_api_endpoint}/dashStats`, body);
  }

  rule_details(body) {
    return this.http.post(`${this.browse_data_api_endpoint}/ruleDetails`, body);
  }

  getzip(jobid) {
    return this.http.get(`${this.browse_data_api_endpoint}/get-zip/${jobid}`, {
      responseType: 'blob',
      reportProgress: true,
      observe: 'events',
    });
  }

  getFileUrl(jobid) {
    return this.http.get(`${this.browse_data_api_endpoint}/get-file-url/${jobid}`);
  }

  getZipFromS3(targetUrl) {
    return this.http.get(targetUrl, {
      responseType: 'blob',
      reportProgress: true,
      observe: 'events',
    });
  }

  get_job_status(jobid) {
    return this.http.get(`${this.browse_data_api_endpoint}/jobStatus/${jobid}`);
  }

  fetch_csv_meta(body) {
    return this.http.post(`${this.browse_data_api_endpoint}/filemanagement/CSVMeta`, body);
  }

  get_datasource_from_dtype(dtype) {
    return this.http.get(
      `${this.browse_data_api_endpoint}/getDatasourceFromDtype/${dtype}`
    );
  }

  get_schemainfo(ds) {
    return this.http.get(`${this.browse_data_api_endpoint}/schemaInfo/${ds}`);
  }

  create_template(obj) {
    return this.http.post(`${this.browse_data_api_endpoint}/createTemplate`, obj);
  }

  createJob(body) {
    return this.http.post(`${this.browse_data_api_endpoint}/createJob`, body);
  }

  getCRSList() {
    return this.http.get(`${this.browse_data_api_endpoint}/getCRSList`);
  }

  registerCompany(body) {
    return this.http.post(`${this.browse_data_api_endpoint}/registerCompany`, body);
  }

  registerAsset(body) {
    return this.http.post(`${this.browse_data_api_endpoint}/registerAsset`, body);
  }

  registerProject(body) {
    return this.http.post(`${this.browse_data_api_endpoint}/registerProject`, body);
  }


  listFolders(body) {
    return this.http.post(`${this.browse_data_api_endpoint}/filemanagement/listFolders`, body);
  }

  listFolders_with_clear_cache() {
    return this.http.post(`${this.browse_data_api_endpoint}/filemanagement/listFolders`, { "clear_ache": true });
  }

  listFiles(folderPath) {

    return this.http.post(`${this.browse_data_api_endpoint}/filemanagement/listFiles`, { "folder_path": folderPath });
  }

  create_dataset(obj) {
    return this.http.post(`${this.browse_data_api_endpoint}/registerDataset`, obj)

  }

  get_download_url(path) {
    return this.http.post(`${this.browse_data_api_endpoint}/filemanagement/getFileDownloadUrl`, { "path": path });
  }

  catalogue_document(obj) {
    return this.http.post(`${this.browse_data_api_endpoint}/registerCatalogueJob`, obj);
  }

  fetchSummaryProject() {
    return this.http.get(
      `${this.browse_data_api_endpoint}/meta_data_management/project?summary=true`
    );
  }

  get_cart_item_object() {
    return {
      asset: '',
      dataType: {
        name: '',
        values: [],
      },
      entityType: {
        name: '',
        values: [],
      },
      dataKeys: {
        name: '',
        values: [],
      },
      dataFrom: '',
      dataTill: '',
    };
  }

  get_input_properties() {
    return {
      project: this.proj,
      company: this.company,
      asset: this.asset,
      crs: this.crs,
      load_type: this.load_type,
    };
  }

  get_csv_headers(file) {
    return [
      'sampletype',
      'holeid',
      'utm_easting',
      'utm_northing',
      'zone',
      'sampleid',
      'azimuth',
    ];
  }

  create_export_job(body) {
    return this.http.post(`${this.browse_data_api_endpoint}/createExportjob`, body);
  }

  get_dataset_records(body) {
    return this.http.post(`${this.browse_data_api_endpoint}/dataset_details`, body);
  }

  get_all_dataset() {
    return this.http.get(`${this.browse_data_api_endpoint}/getAllDatasets`);
  }

  get_duplicates(file_url, pri_key) {

    let body = {
      file_url: file_url,
      pri_key: pri_key
    }
    return this.http.post(`${this.browse_data_api_endpoint}/getDuplicates`, body);

  }



  getTraceInfo(filePath) {
    const baseUrl = `${this.browse_data_api_endpoint}`;
    return this.http.get(`${this.browse_data_api_endpoint}/trace-viewer/file-info?path=` + filePath, {
      'responseType': 'json',
      'headers': {
        'Authorization': 'Bearer ac5365ed-8f61-4ee7-a674-527d956224c1',
        'x-accesstoken': "df352fc1-e652-4df1-81a9-6783f20ab72f",
        'x-checksum': 'ME2n8XIVlDyQ7vKppHr1cJX8FQGFLcLkb2SNu+wsbHA=',
        'x-encryptedqueryparams': 'file',
        'x-machine': 'seismic_catalog',
        'x-project': 'PROJ_DMAAS',
        'x-user': 'usinha'
      }
    });
  }

  getBrowseEndpoint() {
    return this.get_base_url();
  }

  getHeaderDetailsOfTraceFile(filePath, headerType, fromPoint, toPoint) {
    let body = {
      path: filePath,
      type: headerType,
      from: fromPoint,
      to: toPoint
    }
    return this.http.post(`${this.browse_data_api_endpoint}/trace-viewer/header-details`, body);
  }

  get_system_tags() {
    return this.http.get(`${this.browse_data_api_endpoint}/tag`);
  }

  delete_folder(path) {
    let body = {
      path: path,
    };
    return this.http.post(`${this.browse_data_api_endpoint}/filemanagement/deleteFolder`, body);
  }

  create_folder(path, name) {
    let body = {
      path: path,
      name: name,
    };
    return this.http.post(`${this.browse_data_api_endpoint}/filemanagement/createFolder`, body);
  }

  tagResources(data) {
    return this.http.put(`${this.browse_data_api_endpoint}/tagResource`, data);
  }

  deleteTagResource(res_id, tag) {
    return this.http.delete(`${this.browse_data_api_endpoint}/tagResource?res_id=${res_id}&tag=${tag}`);
  }

  getIngestionForm(ingestionType, view) {
    this.user = "dmaas";
    this.project = "AUS";
    return this.http.get(`${this.browse_data_api_endpoint}/jobmanagement/getForm?user=${this.user}&view=${view}&ingestionType=${ingestionType}&project=${this.project}`);
  }

  getCSVIngestionReferences(ingestionType, view) {
    this.user = "dmaas";
    this.project = "AUS";
    return this.http.get(`${this.browse_data_api_endpoint}/jobmanagement/getReferences?user=${this.user}&ingestionType=${ingestionType}&view=${view}&project=${this.project}`);
  }

  createJobforJobManagement(body) {
    return this.http.post(`${this.browse_data_api_endpoint}/jobmanagement/create`, body);
  }

  createTag(props) {
    return this.http.post(
      `${this.browse_data_api_endpoint}/tag`,
      props
    );
  }

  delete_files(files) {
    return this.http.post(`${this.browse_data_api_endpoint}/filemanagement/deleteFolder`, files);
  }

  createResource(props) {
    return this.http.post(
      `${this.browse_data_api_endpoint}/resource`,
      props
    );
  }


  get_file_content(file_path) {
    return this.http.get(`${this.browse_data_api_endpoint}/jobmanagement/getFileContent?file=${file_path}`);
  }

  check_user_access() {
    return this.http.get(`${this.browse_data_api_endpoint}/accessmanagment/checkAccess`);
  }




}
