import { HttpClient, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { LoaderPortalServicesService } from '../services/loader-portal-services.service';

@Injectable({
  providedIn: 'root'
})
export class DataTablesService {

  project="AUS";

  tree;
  filters={};
  map_filters={};
  active_project;
  active_project_bounds=[];
  search_text;
  search_results=[];
  search_aoi=null;
  search_layers=[]
  user;
  sorting={};
  traces= [];
  selectedMarker;
  parentFilters;


  constructor(
    private http:HttpClient,
    public loaderPService:LoaderPortalServicesService
  ) { }

  get_tree_definition(user){

    return this.http.get(`${this.loaderPService.get_base_url()}/dataTables/getTree?user=${this.user}`)
  }

  get_plugins(scope,view){

    return this.http.get(`${this.loaderPService.get_base_url()}/dataTables/getPlugins?view=${view}&scope=${scope}`)
  }

  get_plugin_details(view,plugin_name){

    return this.http.get(`${this.loaderPService.get_base_url()}/dataTables/getPluginDetails?view=${view}&plugin_name=${plugin_name}`)
  }

  get_capablities(user,view){

    return this.http.get(`${this.loaderPService.get_base_url()}/dataTables/getCapabilities?view=${view}&user=${this.user}&project=${this.active_project}`)
  }

  get_filters(view){


    if (this.filters[view]) {

      return this.filters[view]
      
    }else{

      return []
    }
  
  }

  search(search_string,index){

    return this.http.get(`${this.loaderPService.get_base_url()}/dataTables/search?search_string=${search_string}&indices=${index}&project=${this.active_project}&bounds=${this.search_aoi}`)
  }

  search_filters(){

    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/search_filters`,{})
  }

  get_map_filters(view){

    if (this.map_filters[view]) {

      return this.map_filters[view]
      
    }else{

      return []
    }

  }

  get_sorting(view){


    if (this.sorting[view]) {

      return this.sorting[view]
      
    }else{

      return {}
    }
  
  }

  add_filters(filter,view){

    this.filters[view] = filter["filter"]

  }

  add_sorting(sorting,view){

    this.sorting[view]=sorting;

  }


  add_map_filter(filter,view){

    this.map_filters[view] = filter;
  }


  get_data(user,view,page){

    let filters = [...this.get_filters(view)];

    let sorting = {...this.get_sorting(view)};

    let map_filters = {...this.get_map_filters(view)};

    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/getData`,{filters:filters,map_filters:map_filters,sorting:sorting},{
      params:{
        user:this.user,
        view:view,
        project:this.active_project,
        page:page,
        nrows:100
      }})
  }


  downloadFile(user,view,page){

    let filters = [...this.get_filters(view)];

    let sorting = {...this.get_sorting(view)};

    let map_filters = {...this.get_map_filters(view)};

    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/getCSV`,{filters:filters,map_filters:map_filters,sorting:sorting},{
      params:{
        user:this.user,
        view:view,
        project:this.active_project,
        page:page,
        nrows:100
      },
    responseType:"blob"},
      )
  }

  downloadGSQReport(user,view,page){

    let filters = [...this.get_filters(view)];

    let sorting = {...this.get_sorting(view)};

    let map_filters = {...this.get_map_filters(view)};

    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/getGSQReportCSV`,{filters:filters,map_filters:map_filters,sorting:sorting},{
      params:{
        user:this.user,
        view:view,
        project:this.active_project,
        page:page,
        nrows:100
      },
    responseType:"blob"},
      )
  }

  downloadChilddData(user,view,page,filters){


    let sorting = {};

    let map_filters = {};

    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/getCSV`,{filters:filters,map_filters:map_filters,sorting:sorting},{
      params:{
        user:this.user,
        view:view,
        project:this.active_project,
        page:page
      },
    responseType:"blob"},
      )
  }

  get_data_for_grid(user,view,page,filters,rows){
    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/getData`,{filters:filters,map_filters:{}},{
      params:{
        user:this.user,
        view:view,
        project:this.active_project,
        page:page,
        nrows:rows
      }})

  }

  get_ids(view,filters,parent_attribute){
    let body= {
      user:this.user,
      view:view,
      project:this.active_project,
      filters:filters,
      map_filters:{},
      sorting:{},
      parent_attribute:parent_attribute
  }
  return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/getIds`,body)

  }

  get_plugin_metadata(view,plugin,attribute,input){

    let body={

      input:input
    }

    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/get_plugin_metadata`,body,{params:{

      view:view,
      plugin:plugin,
      attribute:attribute

    }})
  }

  get_traces_from_db(view,input){
    let body={
      input:input,
      view:view,
      project:this.active_project
    }
    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/getTracesNames`,body)
  }

  get_logs_from_db(view,input){

    let body={
      input:input,
      view:view,
      project:this.active_project
    }
    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/getLogsData`,body)
  }


  get_plugin_selector(view,plugin,attribute){


    return this.http.get(`${this.loaderPService.get_base_url()}/dataTables/get_plugin_selector`,{params:{

      view:view,
      plugin:plugin,
      attribute:attribute

    }})
  }

  get_image(imageName){

    let promise = new Promise((resolve, reject) => {
      let apiURL = `${this.loaderPService.get_base_url()}/dataTables/get_image`;
      this.http.get(apiURL,{params:{image_name:imageName}}).toPromise();
    });
    return promise;
  }


  get_map_data(user,view){

    let filters = [...this.get_filters(view)];

    let map_filters = {...this.get_map_filters(view)};
    
    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/getMapData`,{filters:filters,map_filters:map_filters},{
      params:{
        user:this.user,
        view:view,
        project:this.active_project,
      }})


  }

  get_projects(){

    return this.http.get(`${this.loaderPService.get_base_url()}/dataTables/getProjects`)

  }

  get_log_viewer_template(){
    return this.http.get(`${this.loaderPService.get_base_url()}/dataTables/getLogViewerTemplate`)
  }

  get_deviations(rows){

    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/getDeviations`,{data:rows})

  }

  get_surface(row) {

    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/getSurface`, { project: this.active_project, data: row[0]['Id'] })

  }


  get_horizon3d(row) {

    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/getHorizon3d`, { project: this.active_project, data: row[0]['Id'] })

  }

  download_deviations_data(rows){

    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/deviationDataDownload`,{data:rows},{
      responseType: 'blob', reportProgress: true,
      observe: 'events' })

  }

  get_curves(file_path,well_name,traceType){

    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/getCurves`,{path:file_path,borehole_name:well_name,curveIds:[traceType]})

  }

  get_default_view(){

    return this.http.get(`${this.loaderPService.get_base_url()}/dataTables/getDefaultViews`)

  }


  get_record(user,view,page,filter){

    let filters = [...this.get_filters(view)];

    filters.push(filter);

    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/getData`,{filters:filters},{
      params:{
        user:this.user,
        view:view,
        project:this.active_project,
        page:page,
        nrows:500
      }})


  }

  get_form_definition(user,view){

    return this.http.get(`${this.loaderPService.get_base_url()}/dataTables/getForm`,{
      params:{
        user:this.user,
        view:view,
        project:this.active_project,
      }})

  }

  get_tree_children(view,node_type){

    let filters = [...this.get_filters(view)];

    let map_filters = {...this.get_map_filters(view)};

    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/getTreeChildren`,{
      filters:filters,
      map_filters:map_filters,
      view:view,
      project:this.active_project,
      type:node_type,
  
    })

  }

  get_reference_values(view){

    return this.http.get(`${this.loaderPService.get_base_url()}/dataTables/getReferences`,{

      params:{

        view:view,

        project:this.active_project,

      }})

  }

  get_particular_reference_values(view, key){

    return this.http.get(`${this.loaderPService.get_base_url()}/dataTables/getReferences`,{

      params:{

        view:view,

        project:this.active_project,

        key:key

      }})

  }

  insert_data(view,values){

    let body = {

      view:view,
      values:values,
      project:this.active_project,
      dBuser:this.user

    }

    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/insert`,body)
  }

  load_Data(body){

    return this.http.post(`${this.loaderPService.get_base_url()}/dataLoaders/seismic`,body)

  }

  delete_data(view,values){

    let body = {

      view:view,
      values:values,
      project:this.active_project,
      dBuser:this.user

    }

    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/delete`,body)
  }

  get_linked_views(view){

    return this.http.get(`${this.loaderPService.get_base_url()}/dataTables/getLinkedViews`,{

      params:{

        view:view,

      }})
  }

  get_linked_count(views){

    let body = {

      views:views,
      project:this.active_project

    }

    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/getLinkedCount`,body)

     
  }


 update_data(view,values){

    let body = {

      view:view,
      values:values,
      project:this.active_project,
      dBuser:this.user
    }

    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/update`,body)
  }

  saveLogTracesSelection(traces){
    this.traces = traces;
  }

  getLogTracesSelection(){
    return this.traces;
  }

  saveMarkersSelection(markers){
    this.selectedMarker = markers;
  }

  getMarkersSelection(){
    return this.selectedMarker;
  }

  validate_csv_data(view,fileName,validateFlag,insertFlag,editFlag,dateFormat){
    let body = {
      filePath:"Temp/"+fileName,
      view:view,
      dBuser:this.user,
      project:this.active_project,
      validateFlag:validateFlag,
      insertFlag: insertFlag,
      editFlag: editFlag,
      dateFormat:dateFormat
    }

    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/ingestCsv`,body)
  }

  getParentFilters(){
    return this.parentFilters;
  }

  setParentFilters(parentFilters){
    this.parentFilters = parentFilters;
  }

  get_scan_and_associate_logs(survey_name,dataType){

    let body = {
      survey_name:survey_name,
      project:this.active_project,
      dataType:dataType
    }
    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/getLogs`,body)
     
  }

  create_document_type_folders(view,survey_names,dataType){

    let body = {
      view:view,
      survey_names:survey_names,
      project:this.active_project,
      dataType:dataType
    }
    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/createDocumentTypeFolders`,body)
     
  }

  scan_and_associate_documents(associated_documents_view,values,dBuser,dataType,currentView){
    let body = {
      associated_documents_view:associated_documents_view,
      values:values,
      project:this.active_project,
      dBuser:dBuser,
      dataType:dataType,
      currentView:currentView
    }
    return this.http.post(`${this.loaderPService.get_base_url()}/dataTables/associateDocuments`,body)
  }

}
