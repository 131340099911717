    <div id="grid2" *ngIf="!gService.summary_grid" class="inner-container grid p-shadow-3">

        <p-table 
        [columns]="cols" 
        [value]="rows" 
        [(selection)]="selectedRows"
        (onRowSelect)="onRowSelect($event)"
        [scrollable]="true" 
        scrollHeight="flex"
        scrollDirection="both"
        [rows]="500" 
        styleClass="p-datatable-sm"
        [loading]="loading"
        [dataKey]="grid_key"
         [tableStyle]="{ 'table-layout': 'auto' }"
        >

        <ng-template pTemplate="caption">
            <div class="caption">
                <div> {{grid_label}} ({{total_records}})</div>
                <div> <i *ngIf="!loading" class="pi pi-angle-double-left expand-button"  (click)="activate_summary_view()"></i></div>
            </div>
        </ng-template>
    
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th><p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
                <th style="width: 200px;"   *ngFor="let col of columns;let i=index">
                    {{col}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td> <p-tableCheckbox [value]="rowData"></p-tableCheckbox></td>
                <td style="width: 200px;"  *ngFor="let col of columns;let i=index">
                    {{rowData[col]}}
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="summary">
            <div style="margin:-0.5rem">
                <p-paginator (onPageChange)="change_page($event)" [showPageLinks]="true"  [pageLinkSize]="3" [rows]="500" [totalRecords]="total_records" ></p-paginator>
            </div>
           
        </ng-template>

    </p-table>

    </div>


    <div  class="cards-grid p-shadow-2" id="grid2" *ngIf="gService.summary_grid">

        <div class="header">

            <div *ngIf="total_records" class="header-title">
                {{grid_label}} ({{total_records}})
            </div>
            
            <div style="display: flex;align-items: center;justify-content: center;">
                <i *ngIf="!loading && rows.length" class="pi pi-angle-double-right expand-button" pTooltip="Expand View" (click)="deactivate_summary_view()"></i>
            

            </div>
           

        </div>

        <div #cards id="cards_container" class="cards-container">

            <div *ngFor="let row of rows;let i=index">

                <div class="card">

                    <div class="check-box">
                        <p-checkbox [(ngModel)]="summary_grid_checked" value={{row[grid_key]}} (ngModelChange)="add_selected_rows()" inputId="binary"></p-checkbox>
                    </div>

                    <div class="text-area">

                        <div class="title">

                            {{row[summary_attributes[0]]}}

                        </div>

                        <div class="details">

                            <div class="element" *ngFor="let elem of details_array;let i=index">

                               <span style="font-weight: 500;font-size: 0.9rem;">{{elem}}:</span><span style="margin-left:0.5rem;font-size:0.9rem">{{ row[elem]}}</span> 

                            </div>
                          

                        </div>

                        <!-- <div class="expand-arrow" (click)="expand_row=!expand_row;expanded_row = i">

                            <i *ngIf="!expand_row || expanded_row != i " style="color:rgb(205, 204, 204)" class="pi pi-angle-down"></i>
                            <i *ngIf="expand_row && expanded_row == i" class="pi pi-angle-up"></i>

                        </div> -->

                    </div>

                </div>
                
                <div class="expanded-card" *ngIf="expanded_row == i && expand_row">


                    <app-grid-report 
                    [showAnalysis]="false" 
                    [gridColumns]="otherCols" 
                    [gridData]="row" 
                    [showQualityReport]="false"
                     ></app-grid-report>


                </div>

            </div>

      

            <div *ngIf="!rows.length && !loading" class="no-record">

                <p>No records found</p>

            </div>


        </div>

        
        

        <div class="footer">

            <p-paginator (onPageChange)="change_page($event)" [showPageLinks]="true"  [pageLinkSize]="3" [rows]="500" [totalRecords]="total_records" ></p-paginator>


        </div>

       

    </div>
 

    <i  class="pi pi-spin pi-spinner loading" *ngIf="loading && gService.summary_grid" ></i>



