import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutorial-index',
  templateUrl: './tutorial-index.component.html',
  styleUrls: ['./tutorial-index.component.scss']
})
export class TutorialIndexComponent implements OnInit {
  vedios= [
      {

        "name": "Get Started - Login",
        "url": "https://dmaas2-images.s3.ap-southeast-2.amazonaws.com/Tutorial/1.mp4",
      },
      {

        "name": "Get Started - Home Page",
        "url": "https://dmaas2-images.s3.ap-southeast-2.amazonaws.com/Tutorial/2.mp4",
      },
      {

        "name": "File Manager - Browse",
        "url": "https://dmaas2-images.s3.ap-southeast-2.amazonaws.com/Tutorial/3.mp4",
      },
      {

        "name": "File Manager - Upload",
        "url": "https://dmaas2-images.s3.ap-southeast-2.amazonaws.com/Tutorial/4.mp4",
      },
      {

        "name": "File Manager - Petrel Project",
        "url": "https://dmaas2-images.s3.ap-southeast-2.amazonaws.com/Tutorial/Petrel Project.mp4",
      },
      {
        "name": "Browse Datasets",
        "url": "https://dmaas2-images.s3.ap-southeast-2.amazonaws.com/Tutorial/Browse Dataset.mp4",
      },

      {
        "name": "Data Ingestion Using Existing Templates",
        "url": "https://dmaas2-images.s3.ap-southeast-2.amazonaws.com/Tutorial/Ingest Collar.mp4",
      },
      {
        "name": "Data Ingestion Creating New Templates",
        "url": "https://dmaas2-images.s3.ap-southeast-2.amazonaws.com/Tutorial/Ingest Survey.mp4",
      },
      {
        "name": "Data Ingestion - Duplicate Data  ",
        "url": "https://dmaas2-images.s3.ap-southeast-2.amazonaws.com/Tutorial/Duplicate Data.mp4",
      },
      {
        "name": "Project Management",
        "url": "https://dmaas2-images.s3.ap-southeast-2.amazonaws.com/Tutorial/Project Management.mp4",
      },
      {
        "name": "Job Management | Export ",
        "url": "https://dmaas2-images.s3.ap-southeast-2.amazonaws.com/Tutorial/Export Data.mp4",
      },
      {
        "name": "Access Management",
        "url": "https://dmaas2-images.s3.ap-southeast-2.amazonaws.com/Tutorial/Access Management.mp4",
      }
]
  constructor() { }

  ngOnInit(): void {
  }

}
