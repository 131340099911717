import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';

import { DataTablesService } from 'src/app/data-tables/data-tables.service';
import { SearchPortalService } from 'src/app/search-portal/search-portal.service';

declare let L;
declare let turf;


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MapComponent implements AfterViewInit, OnChanges, OnDestroy, OnInit {

  @Input() data;
  @Input() loading;
  @Output() onSelection: EventEmitter<any> = new EventEmitter();
  @Input() search_mode=true;

  colorway = ["#636efa", "#ef553b",
    "#00cc96", "#ab63fa",
    "#ffa15a", "#19d3f3",
    "#ff6692", "#b6e880",
    "#ff97ff", "#fecb52"]

  map_data = {};
  active_layer;
  map_id = String(Math.random())
  selection_list: any[] = [];
  labels = false;
  all_selection_layers: any[] = [];
  map: any;
  layergroup: any;
  drawnitems: any;
  main_layers: any = {};
  index_plot;
  selectedLayers = [0, 1, 2];
  main_layers_cpy;
  custom_div = false;
  selected_extent = {}
  map_loading;
  drawControl;
  charts;
  show_details_panel;
  clikedLayers = []
  multi_select = false;
  bh_labels;
  seis_labels;
  panel_data;
  correlation_line;
  display_max_selection = false;
  area_selected=false;
  label_plot=false;

  search_layer;

  map_selections = {}

  search_select_layer;
  drawing=false;

  @ViewChild('map-container') map_elem: ElementRef;
  @Output() selectedRecords = new EventEmitter<any>();
  @Output() onAOISelect = new EventEmitter<any>();


  constructor(private dtservice: DataTablesService, private sPService: SearchPortalService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.map_loading = this.loading;

    if (!this.data) {return};

    if (Object.keys(this.data).length > 1) {
      if (Object.keys(this.map_data).includes(this.data.node.data)) {
        this.map.removeLayer(this.map_data[this.data.node.data])
      }
      this.clear_search_results();
      this.map_data[this.data.node.data] = this.data.data;
      this.plot_geojson(this.data.node.data);
      this.active_layer = this.data.node.data

    }



  }

  ngOnInit() {


  }

  ngAfterViewInit() {
    this.render_basemap()
    this.add_draw_controls();
    this.add_draw_callbacks_bh();

  }

  clear_search_results(){

    this.map.remove();
    this.render_basemap();
    this.add_draw_controls();
    this.add_draw_callbacks_bh();
    this.area_selected=false;
    if (!this.search_select_layer){return};
    this.map.removeLayer(this.search_select_layer);
    this.dtservice.search_aoi=null;
    this.onAOISelect.emit();

  }

  draw_area(){

    this.drawing=true;
    new L.Draw.Rectangle(this.map,this.drawControl).enable()

  }

  clear_area(){

    this.area_selected=false;
    if (!this.search_select_layer){return};
    this.map.removeLayer(this.search_select_layer);
    this.dtservice.search_aoi=null;
    this.onAOISelect.emit();
  }

  add_area_interest(layer){

    this.search_select_layer = L.geoJSON(layer.toGeoJSON(),{
      style:{

        fillOpacity:0,
        color:'white'
      }

    }).addTo(this.map);

    this.drawing=false;
    this.area_selected=true;

    let bounds= layer.getBounds()

    this.dtservice.search_aoi=[[bounds["_northEast"]["lat"],bounds["_northEast"]["lng"]],[bounds["_southWest"]["lat"],bounds["_southWest"]["lng"]]]
      
    this.map.fitBounds(this.search_select_layer.getBounds());
    
    this.onAOISelect.emit();
  }


  plot_search_results(){

    this.clear_search_results();
    this.map_data={"search":{
      "title":[],
      "GeoJSONextent":[],
      "key":"title",
      "color":[]
    }}
    
    Object.keys(this.sPService.search_results).forEach(search_layer=>{  

      let property = this.sPService.indices.find(l => l["index"] == search_layer)
      
      this.sPService.search_results[search_layer].forEach(lay => {

        this.map_data["search"]["GeoJSONextent"].push(lay._source.shape);
        this.map_data["search"]["title"].push(lay._source.title)
        this.map_data["search"]["color"].push(property["color"])
      });
    })

    this.plot_geojson("search");
  
  }



  plot_geojson(layer_name) {

    if (this.main_layers[layer_name]) {

      this.map.removeLayer(this.main_layers[layer_name])

      this.main_layers[layer_name] = null;
    }

    let index = 0

    let me = this;

    let key = this.map_data[layer_name]["key"]
    
    this.main_layers[layer_name] = L.geoJSON(null, {
    
      onEachFeature: function (feature, layer) {
          layer.unbindTooltip();
          layer.bindTooltip("<h4>" +me.map_data[layer_name][key][index] + "</h4>", {permanent: false, direction : 'auto', visibility: 'hidden'});
          layer.setStyle(
            {
              key: me.map_data[layer_name][key][index], 
              layer: layer_name}
          );
            index = index + 1;
       
      },
      pointToLayer: function (point, latlng) {

        let color = "#a1fcde"
        let radius = 4;
        let stroke = false;

        if (layer_name == "search") {
          radius= 6;
          color = me.map_data[layer_name]["color"][index]
          
        }

        return L.circleMarker(latlng, { radius: radius, stroke: stroke, color: color, fillColor: color, weight: 1, fillOpacity: 1})

      },
      style: function (feature, layer) {

        if (layer_name == "search") {
          
          return {color:me.map_data[layer_name]["color"][index]}
        }

      },
    }).addTo(this.map);

  setTimeout(() => {

    this.main_layers[layer_name].addData(this.map_data[layer_name]["GeoJSONextent"]);
    this.reset_map_bound(layer_name)

  }, 10);

    var self = me;
    self.map.on('zoomend', function(e){
      var zoom = self.map.getZoom();
      var index = 0
      var layer = self.main_layers[layer_name];
      var markersPointsLength = layer.toGeoJSON().features.length;
      if(layer != undefined && markersPointsLength < 150){
      if(zoom >= 13 && !me.label_plot){
        self.main_layers[layer_name] = L.geoJSON(layer.toGeoJSON(), {
          onEachFeature: function (feature, layer) {
              layer.unbindTooltip();
              layer.bindTooltip(self.map_data[layer_name][key][index], {permanent: true, direction : 'auto', visibility: 'visible',
              className: 'transparent-tooltip',
              offset: [0, -8]});
              layer.setStyle(
                {
                  key: me.map_data[layer_name][key][index], 
                  layer: layer_name}
              );
              index = index + 1;
        },
        pointToLayer: function (point, latlng) {
          let color = "#a1fcde"
          let radius = 6;
          let stroke = false;
          if (layer_name == "search") {
            color = me.map_data[layer_name]["color"][index]
            radius= 6;
          }  
          var myicon = L.icon({
            iconUrl: '/assets/icons/icon-well-yellow.svg',
            iconSize: [30,30],
          })
          return L.circleMarker(latlng, { radius: radius, stroke: stroke, color: color, fillColor: color, weight: 1, fillOpacity: 1})
  
        },
        style: function (feature, layer) {
          if (layer_name == "search") {
            return {color:me.map_data[layer_name]["color"][index]}
          }
        },
        }).addTo(self.map);

        me.label_plot=true;
         
    } 
      else if ( zoom < 13 && me.label_plot){
      self.main_layers[layer_name] = L.geoJSON(layer.toGeoJSON(), {
        onEachFeature: function (feature, layer) {
          layer.unbindTooltip();
          layer.bindTooltip("<h4>" +self.map_data[layer_name][key][index] + "</h4>", {permanent: false, direction : 'auto', visibility: 'hidden'});
          layer.setStyle(
            {
              key: me.map_data[layer_name][key][index], 
              layer: layer_name}
          );
          index = index + 1;          
      },
      pointToLayer: function (point, latlng) {
        let color = "#a1fcde"
        let radius = 6;
        let stroke = false;
        if (layer_name == "search") {
          color = me.map_data[layer_name]["color"][index];
          radius= 6;
        }
        var myicon = L.icon({
          iconUrl: '/assets/icons/icon-well-yellow.svg',
          iconSize: [30,30],
        })
        return L.circleMarker(latlng, { radius: radius, stroke: stroke, color: color, fillColor: color, weight: 1, fillOpacity: 1})
      },
      style: function (feature, layer) {
        if (layer_name == "search") {
          return {color:me.map_data[layer_name]["color"][index]}
        }
      },
      }).addTo(self.map);
      
      self.map.eachLayer(function(layer) {
        if(layer.options.pane === "tooltipPane") layer.removeFrom(self.map);
          });
    me.label_plot=false;
      }

     }
  });

  }

  reset_map_bound(layer_name) {

    this.map.fitBounds(this.main_layers[layer_name].getBounds());

  }


  add_draw_callbacks_bh() {

    let me = this;

    me.selection_list = [];
    me.all_selection_layers = [];

    this.map.on('draw:canceled', function (e) {
      this.drawing=false;
      
    })

    this.map.on('draw:created', function (e) {

      me.selection_list = [];

      var layer_geoJSON;

      if (e["layerType"] == "circle") {

        layer_geoJSON = turf.circle([e.layer._latlng["lng"], e.layer._latlng["lat"]],
          e.layer._mRadius / 1000, { steps: 100, units: 'kilometers' });

      }
      else {

        layer_geoJSON = e.layer.toGeoJSON();

      }  
      
      if (me.search_mode) {

        me.add_area_interest(e.layer)
        return;
      }
  
      me.selected_extent = layer_geoJSON;

      
      Object.keys(me.main_layers).forEach(map_layer => {

        me.map_selections[map_layer] = []

        me.main_layers[map_layer].eachLayer(function (layer) {

          if (me.map_data[map_layer]["layer_type"] == "point") {

            if (turf.booleanPointInPolygon(layer.feature, layer_geoJSON)) {

              me.map_selections[map_layer].push(layer.options.key)
            }
          }else{
            
            let pointInLayer = turf.pointOnFeature(layer.toGeoJSON())

            if (turf.booleanPointInPolygon(pointInLayer, layer_geoJSON)) {

               me.map_selections[map_layer].push(layer.options.key)
            }

          }

        });

      })

      let send_filters = false;

      Object.keys(me.map_selections).forEach(layer_name => {

        if (me.map_selections[layer_name].length > 1000) {

            me.display_max_selection = true;

        } else {
            send_filters = true;
        }

      })

      if(send_filters){

        Object.keys(me.map_selections).forEach(layer_name=> {

          if (me.map_selections[layer_name].length >0) {
  
            me.add_filters(me.map_selections[layer_name],layer_name)
  
          } 
        })
  
      }
      
    })


  }

  show_tooltip(title){

    if (this.map.getZoom()>=13) {
      return;
    }
    
    
    this.main_layers["search"].eachLayer(function (layer) {
      
      if (layer.options.key == title) {
        layer.toggleTooltip();
      } 
  })
    
  }


  render_basemap() {

    this.map = L.map(this.map_id, { maxZoom: 19, zoomControl: false }).setView([0, 0], 2);

    L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      { id: 'satellite' + "-v9", attribution: '', maxZoom: 20, accessToken: 'pk.eyJ1IjoidXNpbmhhMjkiLCJhIjoiY2xlZGZ2OWZyMDZvMzN2cDI4cWltNmZkbyJ9.pP_iFytJtpIKrIFZEypnaw' }).addTo(this.map)

  }


  add_filters(selected_list,layer_name) {

    let filter = {
      "attribute": this.map_data[layer_name]["key"],
      "operator": "in",
      "values": selected_list
    }


    this.dtservice.add_map_filter(filter, layer_name)

    this.onSelection.emit(layer_name)

  }

  add_draw_controls() {

    this.drawnitems = L.featureGroup();
    this.map.addLayer(this.drawnitems);

    this.drawControl = new L.Control.Draw({
      draw: {
        polygon: {
          shapeOptions: {
            color: 'blue',
            fillOpacity: 0.4,
            fillColor: 'white',
            dashArray: "6",
            weight: 2
          },

        },
        rectangle: {
          shapeOptions: {
            color: 'blue',
            fillOpacity: 0.4,
            fillColor: 'white',
            dashArray: "6",
            weight: 2
          },
        },
        circle: {
          shapeOptions: {
            color: 'blue',
            fillOpacity: 0.4,
            fillColor: 'white',
            dashArray: "6",
            weight: 2
          },
        },
        // polyline: {
        //   shapeOptions: {
        //     color: 'blue',
        //     fillOpacity: 0.4,
        //     fillColor: 'white',
        //     dashArray: "6",
        //     weight: 2
        //   }
        // },
        polyline:false,
        marker: false,
        circlemarker: false

      },
      position: 'topright'
    });

    this.map.addControl(this.drawControl);


  }

  // remove_draw_control(){

  //   if (this.drawControl == null) {return}

  //   this.map.removeControl(this.drawControl);

  //   this.drawControl=null;

  // }


  createIcons(id, position) {

    L.Control.mybar = L.Control.extend({

      options: {
        closeButton: true,
        position: 'top-left',
        autoPan: true,
      },

      initialize: function (placeholder, options) {
        L.setOptions(this, options);

        // Find content container
        var content = this._contentContainer = L.DomUtil.get(placeholder);

        // Remove the content container from its original parent
        content.parentNode.removeChild(content);

        var l;

        if (position == 'right') {

          l = 'leaflet-top leaflet-right';

        }
        if (position == "left-bottom") {

          l = 'leaflet-top leaflet-left';
        }



        // Create sidebar container
        var container = this._container =
          L.DomUtil.create('div', l);

        // Style and attach content container
        L.DomUtil.addClass(content, 'leaflet-control');
        container.appendChild(content);


      },

      addTo: function (map) {
        var container = this._container;
        var content = this._contentContainer;

        // Attach sidebar container to controls container
        var controlContainer = map._controlContainer;
        controlContainer.insertBefore(container, controlContainer.firstChild);

        this._map = map;
      },

    });

    L.control.mybar = function (placeholder, options) {
      return new L.Control.mybar(placeholder, options);
    };

    L.control.mybar(id, {}).addTo(this.map);
  }


  ngOnDestroy() {

    this.map.remove();
  
  }


}

