import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { activity } from '../activity-tracker/interfaces/activity-interface';
import { LoaderPortalServicesService } from './loader-portal-services.service';

@Injectable({
  providedIn: 'root'
})
export class BackendApiService {

  ps_api_end_point;

  constructor(private httpclient:HttpClient, private loaderservice: LoaderPortalServicesService) { 
    this.ps_api_end_point = loaderservice.browse_data_api_endpoint
    

  }

  get_unregistered_activity():activity[]{

    return [
    {activity_id:12320,activity_type:"Manual Loading",data_type:
    "Logs",description:"bulk data loading",user:"user1",
    end_datetime:new Date("25-01-2020"),start_datetime:new Date("25-01-2020"),
    dataset:2,minutes_consumed:25,record_count:123,status:"Unregistered"},
    {activity_id:12321,activity_type:"Manual Loading",data_type:
    "Logs",description:"bulk data loading",user:"user1",
    end_datetime:new Date("25-01-2020"),start_datetime:new Date("25-01-2020"),
    dataset:2,minutes_consumed:25,record_count:123,status:"Unregistered"}
  ]

  }


  get_registered_activity(){

    return this.httpclient.get('https://t20j69r1ai.execute-api.ap-south-1.amazonaws.com/dev/create-activity/query',
    {
      params:{
        key:"day_year",
        values:"2-2021"
      }
    })
  }

  get_activity_columns(){

    return [
      {label:'activity_id',value:'activity_id'},
      {label:"User",value:"user"},
      {label:"Activity Type",value:"activity_type"},
      {label:"Data Type",value:"data_type"},
      {label:"Description",value:"description"},
      {label:"Activity Start",value:"start_datetime"},
      {label:"Activity End",value:"end_datetime"},  
      {label:"Datasets",value:"dataset"},
      {label:"Minutes Consumed",value:"minutes_consumed"},
      {label:"Record Count",value:"record_count"}
    ]
  }

  get_activity_for_id(activity_id){

    return {activity_id:activity_id,activity_type:"Manual Loading",data_type:
    "Logs",description:"bulk data loading",user:"user2",
    end_datetime:new Date("25-01-2020"),start_datetime:new Date("25-01-2020"),
    dataset:2,minutes_consumed:25,record_count:123,status:"registered"}
  }

  get_list_of_activity_types(){

    return [
      {name:'Manual data loading',code:'Manual data loading'},
      {name:"Data prepartion",code:"Data prepartion"},
      {name:"Create API",code:"Create API"},
      {name:"Automated data loading",code:"Automated data loading"},
      {name:"Data Extraction",code:"Data Extraction"},
      {name:"Create DOV",code:"Create DOV"},
      {name:"Create Control File",code:"Create Control File"},
    ]
  }

  get_list_of_data_types(){

    return[
      
      {name:"Seismic",code:"Seismic"},
      {name:"Logs",code:"Logs"},
      {name:"Cores",code:"Cores"},
      {name:"Production",code:"Production"},
      {name:"Well",code:"Well"},
    ]
  
  }

  get_dashboard_charts(){

    return {
        data:[
          {
            x:["foo","bar","moo"],
            y:[45,55,33],
            type:'bar'
          }
        ],
        layout:{
          height:350
        }
      }
    
  }

  save_activity(body){

    return this.httpclient.post("https://t20j69r1ai.execute-api.ap-south-1.amazonaws.com/dev/create-activity",body)

  }

  get_rules(){
    return  this.httpclient.get(`${this.ps_api_end_point}/datasource/rules`)
  }

  get_assets(){

    return  this.httpclient.get(`${this.ps_api_end_point}/datasource/fields`)
  }

  get_quality_score(field, rules){
   var body={"field": field, "rules": rules}

    return  this.httpclient.post(`${this.ps_api_end_point}/datasource/quality_metrics`,body)
  }

  get_quality_score_summary(){
    return  this.httpclient.get(`${this.ps_api_end_point}/datasource/quality_metrics_summary`)
  }

  get_aspect(){

    return[
      
      {name:"Completeness",code:"Completeness"},
      {name:"Validity",code:"Validity"}
    ]
  }

  get_datatypes(){

    return[
      
      {name:"Borehole",code:"Borehole"},
      {name:"Log",code:"Log"},
      {name:"Deviation_Survey",code:"Deviation_Survey"},
      {name:"Checkshot",code:"Checkshot"},
      {name:"Marker",code:"Marker"},
    ]
  }

  save_rules(rules){
     return this.httpclient.post(`${this.ps_api_end_point}/datasource/rules`,rules)
  }

  
  get_field_summary(datatype, aspect, field, rule){
    var body = {
      "field" : field,
      "aspect" : aspect,
      "data_type" : datatype,
      "rule" : rule
    }
     return this.httpclient.post(`${this.ps_api_end_point}/datasource/quality_metrics_rule_summary`, body)
  }

  get_data_for_map(field){
   var body = {"field":field}

    return this.httpclient.post(`${this.ps_api_end_point}/datasource/map_data`, body)
  }

   get_file_url(file_path){
    return this.httpclient.post(`${this.ps_api_end_point}/datasource/get_file_url`,file_path);
  }

  get_file_content(url){
    return this.httpclient.post(`${this.ps_api_end_point}/datasource/get_file_data`,url, {responseType:'text'});
  }

  get_files(body){
    return this.httpclient.post(`${this.ps_api_end_point}/datasource/get_files`,body);
  }



  
  
}

