<div class="p-grid">
    <div class="p-md-2 p-sm-12">
        <label style="margin-right: 0.3rem;position: relative;top: 6px;font-weight: 600;">
            <p>Proposed or Actual</p>
        </label>
    </div>
    <div class="p-md-4 p-sm-12">
        <p-dropdown [style]="{'width':'10rem','position':'relative'}" [options]="proposed_or_actual_selection" (onChange)="on_proposed_or_actual_select()" [(ngModel)]="selected_proposed_or_actual" optionValue="code" appendTo="body" optionLabel="name"></p-dropdown>
    </div>
</div>
<div>
    <canvas #plot contextmenu="return false" ref="plot" style="width: 80vh; height: 100vh;">
    </canvas>
</div>

