import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuOptionsService {

  active_link="";
  
  constructor() { }
}
