import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { LoaderPortalServicesService } from 'src/app/services/loader-portal-services.service';
import { LoginService } from 'src/app/login/login.service';
import { MenuItem } from 'primeng/api';
import { NgModule } from '@angular/core';
import { PanelMenuModule } from 'primeng/panelmenu';
import { Router } from '@angular/router';
import { SettingService } from '../services/setting.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.css'],
})
export class AppMenuComponent implements OnInit {
  loading = true;
  subscription: any;
  visibleSidebar;
  visibleRigthSidebar;
  user;
  project;
  menu;
  items: MenuItem[];
  userItems: MenuItem[];
  constructor(
    public service: LoaderPortalServicesService,
    public settingService: SettingService,
    private router: Router
  ) {
    this.subscription = this.settingService
      .getNavChangeEmitter()
      .subscribe((item) => this.selectedNavItem(item));
  }

  ngOnInit(): void {

  }

  selectedNavItem(item) {
    this.getMenuItems();
    this.load_Projects();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  load_Projects() {
    this.service.fetchSummaryProject().subscribe((res: any) => {
      let projects = JSON.parse(res['data']);
      this.service.all_projects = projects;
      this.service.projects = projects;
      this.loading = false;
    });
  }

  getMenuItems() {
    this.userItems = [
      {
        label: 'User Profile',
        icon: 'pi pi-user-edit',
        command: (event) => {
          this.visibleRigthSidebar = false;
          this.handelshowUserProfile();
        },
      },
      // {
      //   label: 'Setting',
      //   icon: 'pi pi-fw pi-cog',
      // },
      {
        label: 'Signout',
        icon: 'pi pi-sign-out',
        command: (event) => {
          this.visibleRigthSidebar = false;
          this.signout();
        },
      },
    ];
    this.items = [
      {
        label: 'Home',
        icon: 'pi pi-pw pi-home',
        command: (event) => {
          this.visibleSidebar = false;
          this.router.navigate(['home']);
        },
      },
      {
        label: 'File Manager ',
        icon: 'pi pi-fw pi-table',
        visible: this.settingService.visible('data-loader'),
        command: (event) => {
          this.visibleSidebar = false;
          this.router.navigate(['loader-portal/data-loader']);
        },
      },
      // {
      //   label: 'Data Ingestion',
      //   icon: 'pi pi-fw pi-table',
      //   visible: this.settingService.visible('loading-input'),
      //   command: (event) => {
      //     this.visibleSidebar = false;
      //     this.router.navigate(['loader-portal/loading-input']);
      //   },
      // },
      {
        label: 'Browse Datasets',
        icon: 'pi pi-fw pi-table',
        command: (event) => {
          this.visibleSidebar = false;
          this.router.navigate(['data-tables']);
        },

        // items: [{
        //   label: 'wireline-data',
        //   icon: 'pi pi-fw pi-table',
        //   visible: this.settingService.visible('wireline-data'),
        //   command: (event) => {
        //     this.visibleSidebar = false;
        //     this.router.navigate(['loader-portal/wireline-data']);
        //   },
        // },
        // {
        //   label: 'Corporate Well Data',
        //   icon: 'pi pi-fw pi-map',
        //   command: (event) => {
        //     this.visibleSidebar = false;
        //     this.router.navigate(['browse-data'], {
        //       queryParams: { path: 'AllDatasetsMining' },
        //     });
        //   }
        // },
        // ]
      },
      {
        label: 'Job Management',
        items: [
          {
            label: 'Import Jobs',
            icon: 'pi pi-fw pi-upload',
            visible: this.settingService.visible('import-jobs'),
            command: (event) => {
              this.visibleSidebar = false;
              this.router.navigate(['loader-portal/manage-requests']);
            },
          },
          {
            label: 'Export Jobs',
            icon: 'pi pi-fw pi-download',
            visible: this.settingService.visible('export-jobs'),
            command: (event) => {
              this.visibleSidebar = false;
              this.router.navigate(['loader-portal/manage-export-requests']);
            },
          },
        ],
      },
      {
        label: 'Project Management',
        icon: 'fas fa-database	',
        visible: this.settingService.visible('metadata-management'),
        command: (event) => {
          this.visibleSidebar = false;
          this.router.navigate(['loader-portal/metadata-management']);
        },
      },
      // {
      //   label: ' Templete Management ',
      //   tooltip: ' Under development ',
      //   icon: 'fas fa-database	',
      //   visible: this.settingService.visible('metadata-management'),
      //   command: (event) => {
      //     this.visibleSidebar = false;
      //     this.router.navigate(['loader-portal/metadata-management']);
      //   },
      // },

      // {
      //   label: 'Data Quality',
      //   icon: 'fas fa-laptop-medical',
      //   visible: this.settingService.visible('data-quality'),
      //   command: (event) => {
      //     this.visibleSidebar = false;
      //     this.router.navigate(['loader-portal/quality-check']);
      //   },
      // },
      // {
      //   label: 'Dashboards',
      //   icon: 'fas fa-poll	',
      //   visible: this.settingService.visible('dashboard'),
      //   command: (event) => {
      //     this.visibleSidebar = false;
      //     this.router.navigate(['loader-portal/dashboard']);
      //   },
      // },
      // {
      //   label: 'Data Sync',
      //   icon: 'fas fa-sync',
      //   visible: this.settingService.visible('data-sync'),
      //   command: (event) => {
      //     this.visibleSidebar = false;
      //   },
      // },

      {
        label: 'User Management',
        icon: 'far fa-id-badge	',
        visible: this.settingService.visible('setting'),
        command: (event) => {
          this.visibleSidebar = false;
          this.router.navigate(['loader-portal/access-management']);
        },
      },
      {
        label: 'Tutorial',
        icon: 'pi pi-book	',
        command: (event) => {
          this.visibleSidebar = false;
          this.router.navigate(['tutorial']);
        },
      },
      // {
      //   label: ' Data Management ',
      //   tooltip: ' Data Management ',
      //   icon: 'fas fa-database	',
      //   visible: this.settingService.visible('data-management'),
      //   command: (event) => {
      //     this.visibleSidebar = false;
      //     this.router.navigate(['data-management']);
      //   },
      // },
    ];
  }
  handelshowUserProfile() {
    this.router.navigate([
      '/loader-portal/access-management',
      this.user.username,
    ]);
  }

  sidebarHide() {
    this.getMenuItems();
  }
  signout() {
    // this.authService.signout();
  }

  onChangeEvent(event: any) {
    let searchValue = event.target.value.toLowerCase();
    // this.service.assets = this.service.all_assets.filter((asset) => {
    //   if (asset) {
    //     let exist = false;
    //     for (var key in asset) {
    //       if (asset.hasOwnProperty(key) && asset[key] && asset[key] != '') {
    //         try {
    //           exist = exist || asset[key].toLowerCase().includes(searchValue);
    //         } catch (error) {}
    //       }
    //     }
    //     return exist;
    //   } else {
    //     return false;
    //   }
    // });
    this.service.projects = this.service.all_projects.filter((project) => {
      if (project) {
        let exist = false;
        for (var key in project) {
          if (
            project.hasOwnProperty(key) &&
            project[key] &&
            project[key] != ''
          ) {
            try {
              exist = exist || project[key].toLowerCase().includes(searchValue);
            } catch (error) {}
          }
        }
        return exist;
      } else {
        return false;
      }
    });
  }

  onResetEvent(event: any) {
    this.load_Projects();
  }

  show_shopping_cart() {
    this.router.navigate(['data-tables/cart']);
  }

  showtutorial()
  {
    this.router.navigate(['tutorial']);
  }
}
