<div class="panel-container p-shadow-2">

    <div id="layer-header" class="layer-header">
            <p style="font-size: 1.1rem;">{{data['well_data']['Borehole']}}</p>

            <div class="icons">
                <button pButton icon="fa fa-arrow-alt-circle-up" pRipple type="button" label="PUBLISH" class="p-button-text" (click)="publish()"></button>

                <i style="font-size: 1.1rem; cursor: pointer;" (click)="close_panel()" class="fa fa-times"></i>
            </div>
           
    </div>

              
    <div class="panel-content">

        <p-accordion [multiple]="true">

            <p-accordionTab header="Borehole Summary" [selected]="true" >

                <p-scrollPanel [style]="{width: '100%', 'max-height': '25rem'}">

                    <div class="row" *ngFor="let item of summary_attributes">

                        <p class="cell">
        
                            {{item}}
        
                        </p>
                        <p class="cell">
                                {{
                                    data['well_data'][item]
                                }}
                        </p>
                    </div>

                </p-scrollPanel>
               
     
            </p-accordionTab>

            <p-accordionTab header="Data Availability" [selected]="true" >

                <p-scrollPanel [style]="{width: '100%', 'max-height': '25rem'}">

                    <div>

                        <div class="row">

                            <h4 class="cell">
                                Data Type
                            </h4>
    
                            <h4 class="cell">
                                ProSource
                            </h4>

                            <h4 class="cell">
                                Studio
                            </h4>
    
                        </div>  
    
                        <div class="row" *ngFor="let item of plot_data.values;let i=index">
            
                            <p class="cell">
            
                                {{plot_data.labels[i]}}
            
                            </p>
                            <p class="cell" [ngClass]="{'cell_av': plot_data.values[i] == ' ', 'cell_nav': plot_data.values[i] == 'Y'}">
                                {{plot_data.values[i]}}
                            </p>

                            <p class="cell" [ngClass]="{'cell_av': st_values[i] == ' ', 'cell_nav': st_values[i] == 'Y'}">
                                {{st_values[i]}}
                            </p>
                            
                        </div>

                    </div>

                   

                    <div style="width:100%;text-align: center;" *ngIf="data.well_data.availability == 'N'" >
                        <p> No data available</p>
                    </div>
                </p-scrollPanel>


            </p-accordionTab>

            <p-accordionTab header="Public Datasets" [selected]="true" >

                <div *ngIf="data.public_data.length">

                    <div style="width:100%;display: flex;justify-content: center;padding-bottom: 0.5rem;">

                        <button pButton pRipple type="button" label="ADD TO CART" class="p-button-text"></button>

                    </div>

                    <p-scrollPanel [style]="{width: '100%', 'height': '25rem'}">
                        
                        
                            <div class="row">

                                <h4 class="cell">
                                    Dataset Name
                                </h4>
        
                                <h4 class="cell">
                                    Dataset Size
                                </h4>
        
                                <h4 class="cell">
                                    Format
                                </h4>
        
        
                            </div>      
        
                            <div class="row" *ngFor="let item of data.public_data">
        
                                <p class="cell">
                
                                    {{item['Dataset Description']}}
                
                                </p>
                                <p class="cell">
                                    {{item['Dataset Size']}}
                                </p>
                                <p class="cell">
                                    {{item['Format']}}
                                </p>
                            </div>
                            
                    
    
                    </p-scrollPanel>

                </div>

                <div style="width:100%;text-align: center;" *ngIf="!data.public_data.length" >
                    <p> No data available</p>
                </div>
                
            </p-accordionTab>
            
            <p-accordionTab header="Monthly Production" *ngIf="prod_available" [selected]="true">

                <p-scrollPanel [style]="{width: '98%', 'max-height': '25rem'}">

                    <app-plotly *ngIf="data" [data]="data.prod_data[0].data" [layout]="data.prod_data[0].layout"></app-plotly>
                
                </p-scrollPanel>

            </p-accordionTab>
           
         </p-accordion>
           
    </div>

   
  



</div>
