import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { CompositeTool } from '@int/geotoolkit/controls/tools/CompositeTool';
import { GlobalCompService } from 'src/app/global-comps/global-comps-services/global.service';
import {Plot} from '@int/geotoolkit/plot/Plot';
import {SchematicsWidget} from '@int/geotoolkit/schematics/widgets/SchematicsWidget';
import {ToolTipTool} from '@int/geotoolkit/controls/tools/ToolTipTool';
import { TooltipModule } from 'primeng/tooltip';
import {WellBoreData} from '@int/geotoolkit/schematics/data/WellBoreData';

@Component({
  selector: 'app-wellbore-schematic',
  templateUrl: './wellbore-schematic.component.html',
  styleUrls: ['./wellbore-schematic.component.css']
})
export class WellboreSchematicComponent implements OnInit {

  @ViewChild("plot") canvas;
  @Output() onClose = new EventEmitter();
  @Input() selectedData;
  @Input() view;
  @Input() maximized: EventEmitter<boolean>;

  widget_data;
  widget;
  plot:Plot;
  expand_viewer = false;
  selected_proposed_or_actual = 'actual'
  min_depth;
  max_depth;

  proposed_or_actual_selection=[
    {name:'Actual', label:'Actual', code:'actual'},
    {name:'Proposed', label:'Proposed', code:'proposed'}
  ]


  constructor(private gService:GlobalCompService) { }
  
  ngOnInit(): void {
    this.get_wellbore_schematic_data();
  }

  get_wellbore_schematic_data() {
    let request_body = {
      input:[{
        'ubhi':this.selectedData[0]['UBHI'],
        'proposedoractual':this.selected_proposed_or_actual
      }],
      view:this.view,
      project:'AUS'
    } 

    this.gService.get_well_schematic_data(request_body).subscribe(res => {
        this.widget_data = res['data'];
        this.min_depth = res['min_depth'];
        this.max_depth = res['max_depth'];
        let options = {
          'south': {'title': {'text': this.selectedData[0]["Borehole Name"] + 
          ' - Depth [' + this.min_depth.toFixed(3) + 
          ' : ' + this.max_depth.toFixed(3) + 
          '] m'}},
          'gap': 20,
          'wellborenode': {
            'units': {
                'depth': 'ft'
            }
          },
          'annotationssizes': {
            'south': 50,
            'north': 0
          },
          'tooltip': {
            'gettext': (componentNodes) => {
                if (!componentNodes || componentNodes.length < 1) {
                    return null;
                }
                else{
                  var tooltip_text = componentNodes[componentNodes.length - 1].ha.description + "<br>" + "depth:" + 
                  " [" + componentNodes[componentNodes.length - 1].ha.geometry.depth.from + " - " + 
                  componentNodes[componentNodes.length - 1].ha.geometry.depth.to + "]" +
                  "<br>" + "diameter: " + componentNodes[componentNodes.length - 1].ha.geometry.diameter.outer;
                  return tooltip_text;
                }
            }
        },
        };
        this.widget = new SchematicsWidget({'options': options, 'data': this.createWellBoreData()});
        this.widget.setViewMode(0);
        this.plot = new Plot({
          'canvasElement': this.canvas.nativeElement,
          'root': this.widget
        });
    });

    this.maximized.subscribe(res => {
      this.expand_viewer = true;
    });
  }

  on_proposed_or_actual_select(){
    this.widget.dispose();
    this.get_wellbore_schematic_data();
  }

  createWellBoreData () {
    const wellBoreData = new WellBoreData();
    this.widget_data.forEach((object) => {
        wellBoreData.addComponent(object['name'], {
            'description': object['description'],
            'geometry': {...object['geometry']}
        });
    });
    return wellBoreData;

}

}
