import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { ViewModelService } from 'src/app/browse-data/view-model.service';
import { GlobalCompService } from 'src/app/global-comps/global-comps-services/global.service';


@Component({
  selector: 'app-map-filters',
  templateUrl: './map-filters.component.html',
  styleUrls: ['./map-filters.component.css']
})
export class MapFiltersComponent implements OnInit {


  @Input() filters;

  @Output() selection = new EventEmitter<any>();

  dropdowns = [];

  dropdown_values=[];

  selectedPartition;

  constructor(private gService:GlobalCompService,
    private vService:ViewModelService) { }

  ngOnInit(): void {

    this.filters.forEach(elem => {

      if(elem.type == 'partition'){

        this.dropdowns.push(elem.label)

        this.dropdown_values.push(this.gService.get_partitions())

      }
    });
  }

  onSelect(event){

    this.vService.active_partition = this.selectedPartition;

    this.selection.emit();
  }

}
