
<div class="container" *ngIf="!loading">

    <div class="table-panel">

        <div style="height: 40%;width: 100%;">
            <p-table [value]="deviation_data" styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"
            [scrollable]="true" scrollHeight="flex">
            <ng-template pTemplate="caption">
                Deviation Survey
                <div style="float: right">
                    <i pTooltip="Download Deviation" class="fa fa-file-csv caption-icons" (click)="downloadFile()"></i>
                </div>
            </ng-template>
               <ng-template pTemplate="header">
                   <tr>
                       <th style="width: 150px;">Borehole</th>
                       <th style="width: 80px;">MD</th>
                       <th style="width: 80px;">INC</th>
                       <th style="width: 80px;">AZ</th>
                       <th style="width: 80px;">TVD</th>
                       <th style="width: 80px;">TVDSS</th>
                       <th style="width: 80px;">DLS</th>
   
                       
                   </tr>
               </ng-template>
               <ng-template pTemplate="body" let-row>
                   <tr>
                       <td style="width: 150px;">{{row.borehole}}</td>
                       <td style="width: 80px;">{{row.md}}</td>
                       <td style="width: 80px;">{{row.inclination}}</td>
                       <td style="width: 80px;">{{row.azimuth}}</td>
                       <td style="width: 80px;">{{row.tvd}}</td>
                       <td style="width: 80px;">{{row.tvdss}}</td>
                       <td style="width: 80px;">{{row.dls}}</td>
   
                   </tr>
               </ng-template>
           </p-table>
        </div>

        <div class="map" style="height: 60%;width: 100%; top: 1.5rem;">
            <div style="height: 100%;width: 100%;">
                <app-map #map *ngIf="show_map"></app-map>
            </div>
            
        </div>

    
    </div>

    <div class="viewers" *ngIf="!error">

        <app-plotly [data]="data" [layout]="layout"></app-plotly>

    </div>

    <div class="error" *ngIf="error">

        {{error_message}}

    </div>
    


</div>

<div class="spinner-container" *ngIf="loading">

    <i  class="pi pi-spin pi-spinner spinner2" style="font-size: 2rem;"></i>

</div>


