import { AuthModule, LogLevel } from 'angular-auth-oidc-client';

import { AutoLoginService } from 'angular-auth-oidc-client/lib/auto-login/auto-login.service';
import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
            authority: environment.authority,
            redirectUrl: environment.redirect_url,
            postLogoutRedirectUri: window.location.origin,
            clientId: environment.clientId,
            scope: 'openid',
            responseType: 'code',
            silentRenew: true,
            useRefreshToken: true
      },
    }
  ),
  ],
  exports: [AuthModule]
})
export class AuthConfigModule {}
