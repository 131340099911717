import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class DataModelService {

  all_models=[]

  constructor() {
  
  }

  get_datasource_attributes(name){

    let datasource = this.all_models.find(model=>{

      return model.datasource == name;
    })


    if(!datasource){
      return []
    }
    else{

      return datasource.attributes.map(attr=>attr.attribute)
    }
  }

  get_datasource_definition(name){

    let datasource = this.all_models.find(model=>{

      return model.datasource == name;
    })

    return datasource.attributes;

  }

  get_datasource_filters(name){

    let datasource = this.all_models.find(model=>{

      return model.datasource == name;
    })
    
    return datasource.attributes.filter(att=>{

      if(att.filterable){

        return att

      }
    })

  }

  get_primary_key(datasource){

    let dataS = this.all_models.find(model=>{

      return model.datasource == datasource;
    })
   
    return dataS.attributes.find(x=>{

      return x.primaryKey==true;
    })["attribute"]


  }

  get_attribute_type(attribute,datasource){

    let def = this.get_datasource_definition(datasource)

    return def.find(att=>att.attribute == attribute)["attributeType"]

  }

  get_summary_attributes(datasource){

    let def = this.get_datasource_definition(datasource)


    return def.filter(att=>att.summaryAttribute==true).map(x=>x.attribute)


  }
}
