import { AfterViewInit, Component, EventEmitter, OnInit,Input, Output, ViewChild } from '@angular/core';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { GlobalCompService } from '../global-comps-services/global.service';

@Component({
  selector: 'app-doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.css']
})
export class DocViewerComponent implements OnInit,AfterViewInit {

  fileSize=1;
  downloaded=0;
  downloading=false;
  value=0;
  name;

@Input() file_path;

 @ViewChild("viewer") viewer :PdfJsViewerComponent
 @Output() onClose = new EventEmitter();
  constructor(private gService:GlobalCompService) { }

  ngOnInit(): void {


    this.file_path=this.file_path.at(-1)["Original_Path"];

    this.get_document(this.file_path);
  
  }

  ngAfterViewInit(): void {
      
    document.getElementById('plot-container').clientHeight - 35
  }

  get_document(file){
    
    this.downloaded=0;
    this.fileSize=1;
    this.value=0;
    
    this.downloading=true;

    this.gService.get_file_details(this.file_path).subscribe(res=>{

      this.fileSize=res["fileSize"]
      
      this.gService.download_file(this.file_path).subscribe((res2:any)=>{

        res2.type == 3? this.downloaded = res2["loaded"]: null;
      
      this.value= Math.floor((this.downloaded/this.fileSize)*100);
  
        if(res2.body){
  
          this.viewer.pdfSrc = res2.body;
          this.downloading=false; 
          this.viewer.refresh(); 
        }
      })
    })
  

  }

  close(){

    this.onClose.emit()
  }

  

}
